import { useRunOnChange } from "@/hooks/runOnChange";
import { convertTimeToString, Time } from "@/utils/date";
import { useState } from "react";

const REGEX_TIME = /^(1[0-2]|[1-9])(:[0-5][0-9])?(am|pm)$/;

const validateInput = (string: string) => {
  return REGEX_TIME.test(string.toLocaleLowerCase());
};

const parseTimeFromString = (timeString: string) => {
  const match = timeString.toLocaleLowerCase().match(REGEX_TIME);

  if (!match) {
    throw new Error(`Invalid time format: "${timeString}"`);
  }

  const hours = parseInt(match[1]); // Capture group 1: Hours
  const minutes = match[2] ? parseInt(match[2].slice(1)) : 0; // Capture group 2: Minutes (default to 0)
  const amOrPm = match[3] as "am" | "pm"; // Capture group 3: "am" or "pm"

  return { hours, minutes, amOrPm };
};

const TimeInput = ({
  time,
  setTime,
  className = "text-sm w-full h-full outline-none",
  errorClass = "",
  disabled = false,
}: {
  time: Time;
  setTime: (time: Time) => void;
  className?: string;
  errorClass?: string;
  disabled?: boolean;
}) => {
  const [inputValue, setInputValue] = useState<string>(
    convertTimeToString(time)
  );
  const [error, setError] = useState<boolean>(false);

  useRunOnChange(time, () => {
    setInputValue(convertTimeToString(time));
  });

  return (
    <input
      type="text"
      className={`${className} ${error ? errorClass : ""}`}
      value={inputValue}
      disabled={disabled}
      onChange={(e) => {
        setInputValue(e.target.value);
      }}
      onFocus={() => {
        if (error) {
          setError(false);
        }
      }}
      onBlur={() => {
        if (!validateInput(inputValue)) {
          setError(true);
        } else {
          setTime(parseTimeFromString(inputValue));
        }
      }}
    />
  );
};

export default TimeInput;
