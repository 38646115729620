import { RuleTutorialState } from "@/interface/shiftSettings";

const STATE_DATA: Record<RuleTutorialState, { body: string; helper?: string }> =
  {
    clickAddRule: {
      body: "Start by clicking on the add a rule button.",
    },
    clickAddPattern: {
      body: "Click on the '+' button on any one of these days",
    },
    chooseDropdownOption: {
      body: "You can select a range directly, or hover over the arrow to view and select a shift you created earlier.",
    },
    addAnotherOption: {
      body: "Great! Add another shift for this day, or any other day of the week. You can hover over a day to reveal the '+' button.",
    },
    ruleCreated: {
      body: "Congrats, you’ve created your first rule! Continue adding shifts if you wish, or go back to the homepage to generate a schedule. This rule will be followed when generating them!",
      helper: "Click anywhere to continue",
    },
    ruleType: {
      body: "This shift pattern is ‘followed’ by default. If you don’t want this pattern to be followed, you can change this to ‘prevented’ and it will avoid such shift patterns when generating a roster.",
      helper: "Click anywhere to continue",
    },
    ruleStartsOn: {
      body: "You can also define when ‘Day 1’ should start using this dropdown.\nIf you would like to delete this rule, simply hover over this row to reveal the red delete button.",
      helper: "Click anywhere to finish tutorial.",
    },
    createRule: {
      body: "",
    },
    createNewPattern: {
      body: "",
    },
  };

const RulesTutorialDropdown = ({
  tutorialState,
}: {
  tutorialState: RuleTutorialState;
}) => {
  return (
    <div className="bg-white drop-shadow-xl px-4 py-2 rounded-md text-sm font-medium max-w-[450px]">
      <div>{STATE_DATA[tutorialState].body}</div>
      {STATE_DATA[tutorialState].helper && (
        <div className="text-xs text-[#737373]">
          {STATE_DATA[tutorialState].helper}
        </div>
      )}
    </div>
  );
};

export default RulesTutorialDropdown;
