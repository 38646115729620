import { motion, AnimatePresence } from "framer-motion";
import { useDroppable } from "@dnd-kit/core";
import { useEffect, useState } from "react";

import Grid from "../Icons/Grid";
import Monitor from "../Icons/Monitor";
import { Minus } from "../Icons/Minus";
import { Add } from "../Icons/Add";

import { formatTime } from "../../utils/formatDate";

import { useUpdateShiftMutation } from "../../api/shiftsApi";
import {
  useAssignRandomnMutation,
  useDeleteScheduleMutation,
} from "../../api/rosterApi";

import { useEstimationContext } from "../../hooks/context/useEstimationContext";
import useHandleSuccessErrors from "../../hooks/handleSuccessErrors";
import useHandleOnScheduleChange from "../../hooks/estimation/handleOnScheduleAdd";
import { getDateRange } from "../../utils/date";
import Slot from "./Slot";

import { InputSlotMode } from "./InputSlot";
import { ShiftType } from "../../interface/shift";
import handleResponse from "../../utils/handleResponse";
import { SHIFT_DOCTORS_REQUIRED_LIMIT } from "@/constants";
import { toast } from "react-toastify";

type ShiftCardProps = {
  time: { to: string; from: string };
  type: ShiftType;
  slots: any[];
  className?: string;
  windowType: "primary" | "secondary" | "responsive";
  shiftId: string;
  isFetching: boolean;
  date: Date;
  requestsStatus?: "no-requests" | "requests" | "contested";
  requestHandler?: () => void;
};

const getActiveColor = (type: "primary" | "secondary" | "responsive") => {
  if (type === "primary") {
    return "bg-secondary text-white";
  }
  if (type === "secondary") {
    return "bg-yellow1 text-black";
  }
  if (type === "responsive") {
    return "bg-blue3";
  }
};

const getClassName = (type: "primary" | "secondary" | "responsive") => {
  if (type === "primary") {
    return "stroke-white group-hover:stroke-secondary fill-white";
  }
  if (type === "secondary") {
    return "stroke-black1 group-hover:stroke-secondary fill-black1";
  }
  if (type === "responsive") {
    return "stroke-black1 group-hover:stroke-secondary fill-black1";
  }
};

const getIconColor = (
  type: "primary" | "secondary" | "responsive",
  isActive = false
) => {
  if (type === "primary") {
    return isActive ? "#67823A" : "white";
  }
  if (type === "secondary") {
    return isActive ? "#67823A" : "black";
  }
  if (type === "responsive") {
    return isActive ? "#67823A" : "black";
  }
};

const ShiftCard = ({
  time,
  type,
  className,
  windowType,
  slots,
  shiftId,
  isFetching,
  date,
  requestsStatus = "no-requests",
  requestHandler,
}: ShiftCardProps) => {
  const {
    setResponsiveWindow,
    responsiveWindow,
    monitorActiveId,
    setMonitorActiveId,
    editShiftId,
    setEditShiftId,
    setEditShiftDate,
    setActiveTab,
    activeTab,
    setDataDoctor,
    autoAssign,
    doctorFilterSeniority,
  } = useEstimationContext();

  const [updateShift, { isLoading: isUpdateLoading }] =
    useUpdateShiftMutation();

  const [slotModes, setSlotModes] = useState<InputSlotMode[]>(
    Array(slots.length).fill("close")
  );

  useEffect(() => {
    if (slots.length > slotModes.length) {
      setSlotModes([
        ...slotModes.concat(
          Array(slots.length - slotModes.length).fill("close")
        ),
      ]);
    } else if (slots.length < slotModes.length) {
      setSlotModes([...slotModes.slice(0, slots.length)]);
    }
  }, [slotModes, slots]);

  const onScheduleChange = useHandleOnScheduleChange({
    ...getDateRange(date),
  });

  const [deleteSchedule, { isLoading: isDeleteScheduleLoading }] =
    useDeleteScheduleMutation();

  const [
    assignRandomn,
    {
      isLoading: isAssignRandomLoading,
      isSuccess: isAssignSuccess,
      isError: isAssignError,
      error: assignError,
    },
  ] = useAssignRandomnMutation();

  useHandleSuccessErrors({
    isSuccess: isAssignSuccess,
    isError: isAssignError,
    error: assignError,
    successMessage: "Schedules auto assigned.",
    successFunction: async () => {
      await onScheduleChange();
    },
  });

  const { setNodeRef, isOver } = useDroppable({
    id: shiftId,
    disabled: slotModes.some((mode) => mode !== "close"),
    data: {
      type: "shiftCard",
    },
  });

  const handleAdd = async () => {
    if (slots.length < SHIFT_DOCTORS_REQUIRED_LIMIT) {
      const response = await updateShift({
        shiftId,
        totalDoctorsRequired: slots.length + 1,
      });
      handleResponse(response, "Shift update successfull", async () => {
        await onScheduleChange();
      });
    } else {
      toast.warning("Doctors Required limit reached.");
    }
  };

  const handleRemove = async () => {
    if (slots.length > slots.filter((slot) => slot.schedule).length) {
      const response = await updateShift({
        shiftId,
        totalDoctorsRequired: slots.length - 1,
      });
      handleResponse(response, "Shift update successfull", async () => {
        await onScheduleChange();
      });
    }
  };

  const isLoading =
    isUpdateLoading ||
    isDeleteScheduleLoading ||
    isAssignRandomLoading ||
    isFetching;

  const activateFirstUnfilledSlot = (index: number) => {
    let slotsToSearch;
    if (index === slots.length - 1) {
      slotsToSearch = slots.slice(0, slots.length - 1);
    } else {
      slotsToSearch = slots.slice(index + 1).concat(slots.slice(0, index));
    }

    const activeSlot = slotsToSearch.find((slot) => !slot.schedule);

    if (activeSlot) {
      const activeIndex = slots.findIndex(
        (slot) => slot._id === activeSlot._id
      );
      setSlotModes((slotModes) => {
        slotModes[activeIndex] = "doctor";
        return [...slotModes];
      });
    }
  };

  return (
    <div
      className={`col-span-1 relative transition-all ${className ?? ""} h-fit ${
        isOver ? "border-dashed border-secondary border-[3px] rounded-xl" : ""
      }`}
      ref={setNodeRef}
    >
      <div
        className={`${
          editShiftId === shiftId ? "rounded-t-xl" : " rounded-xl"
        } px-3 pt-3 bg-white ${isLoading ? "animate-pulseFast" : ""}`}
      >
        <div className="relative">
          {editShiftId === shiftId ? (
            <>
              <button
                disabled={isLoading}
                className={`absolute cursor-pointer top-0 bottom-0 my-auto h-fit left-2.5 rounded-sm p-0.5 ${
                  monitorActiveId === shiftId ? "bg-white" : ""
                }`}
                onClick={() => {
                  setDataDoctor(undefined);
                  setMonitorActiveId(monitorActiveId ? undefined : shiftId);
                  setResponsiveWindow(
                    responsiveWindow.type === "shiftView"
                      ? { type: "dateView", props: {} }
                      : { type: "shiftView", props: { shiftId } }
                  );
                }}
              >
                <Monitor
                  fill={getIconColor(windowType, monitorActiveId === shiftId)}
                />
              </button>
              <button
                disabled={isLoading}
                className={`absolute cursor-pointer top-0 bottom-0 my-auto h-fit right-2.5 p-0.5`}
                onClick={async () => {
                  await assignRandomn({
                    shiftId,
                    ...(!autoAssign &&
                    activeTab !== "suggested" &&
                    activeTab === "seniority"
                      ? { forceSeniority: doctorFilterSeniority }
                      : {}),
                  });
                }}
              >
                <Grid
                  className={isAssignRandomLoading ? "animate-spin" : ""}
                  fill={getIconColor(windowType)}
                />
              </button>
            </>
          ) : null}
          {editShiftId !== shiftId && requestsStatus !== "no-requests" ? (
            <>
              <button
                disabled={isLoading}
                className={`absolute cursor-pointer top-0 bottom-0 my-auto h-fit right-2.5 rounded-sm p-0.5 ${
                  monitorActiveId === shiftId ? "bg-white" : ""
                }`}
                onClick={() => {
                  requestHandler?.();
                }}
              >
                <div
                  className={`rounded-full h-3 w-3 ${
                    requestsStatus === "requests" ? "bg-teal3" : "bg-maroon2"
                  }`}
                />
              </button>
            </>
          ) : null}
          <button
            onClick={() => {
              if (editShiftId && editShiftId !== shiftId) {
                if (autoAssign) {
                  setActiveTab("suggested");
                }
                setEditShiftId(shiftId);
                setEditShiftDate(date);
                setMonitorActiveId(undefined);
                if (responsiveWindow.type === "shiftView") {
                  setResponsiveWindow({ type: "dateView", props: {} });
                }
              } else if (editShiftId && editShiftId === shiftId) {
                if (activeTab === "suggested") {
                  setActiveTab("seniority");
                }
                setEditShiftId(undefined);
                setMonitorActiveId(undefined);
                if (responsiveWindow.type === "shiftView") {
                  setResponsiveWindow({ type: "dateView", props: {} });
                }
              } else {
                if (autoAssign) {
                  setActiveTab("suggested");
                }
                setEditShiftId(shiftId);
                setEditShiftDate(date);
              }
            }}
            disabled={isAssignRandomLoading}
            className={`header w-full px-6 py-2 flex justify-center items-center text-xs font-medium rounded-lg transition-all ${
              editShiftId === shiftId
                ? getActiveColor(windowType)
                : " bg-[#f2f2f2] text-black1"
            }`}
          >
            {`${formatTime(time.from)} - ${formatTime(time.to)}`}
          </button>
        </div>
        <div className="max-w-md mt-1 mx-auto">
          <AnimatePresence>
            {slots.length === 0 ? (
              <motion.div
                key="noDataTransition"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.2 }}
                className={`pb-0.5 pt-1.5 border-x-0 border-t-0 border-b-[#BDBDBD] transition-opacity`}
              >
                <div className="font-medium text-sm p-1 text-black2 text-center">
                  No Data
                </div>
              </motion.div>
            ) : null}
            {slots.map((slot, index) => (
              <Slot
                key={slot._id}
                index={index}
                type={type}
                totalSlots={slots.length}
                shiftId={shiftId}
                slot={slot}
                isLoading={isLoading}
                onScheduleAdd={onScheduleChange}
                mode={slotModes[index]}
                setMode={(mode) => {
                  const getNewSlotModes = () => {
                    const newSlotModes = Array(slotModes.length).fill("close");
                    if (mode === "close") {
                      return newSlotModes;
                    } else {
                      newSlotModes[index] = mode;
                      return newSlotModes;
                    }
                  };
                  setSlotModes(getNewSlotModes());
                }}
                triggerOnEnter={(index: number) => {
                  activateFirstUnfilledSlot(index);
                }}
                deleteSchedule={async () => {
                  const response = await deleteSchedule({
                    scheduleId: slot.schedule._id,
                  });
                  handleResponse(
                    response,
                    "Schedule deleted successfully",
                    async () => {
                      await onScheduleChange();
                    }
                  );
                }}
              />
            ))}
          </AnimatePresence>
        </div>
      </div>
      {editShiftId === shiftId ? (
        <div
          className={`${getActiveColor(
            windowType
          )} py-1.5 px-5 rounded-b-xl transition-all`}
        >
          <div className="flex items-center text-xs justify-between">
            <button
              className="text-white p-0.5 cursor-pointer group hover:bg-white rounded-sm transition-colors duration-500"
              onClick={() => handleRemove()}
            >
              <Minus
                className="h-4 w-4"
                stroke={`${getClassName(
                  windowType
                )}stroke-white group-hover:stroke-secondary transition duration-500`}
              />
            </button>
            <div className=" my-auto  font-medium">
              {slots.reduce((count, slot) => {
                if (slot.schedule) {
                  return count + 1;
                }
                return count;
              }, 0)}
              /{slots.length}
            </div>
            <button
              className="text-white p-0.5 cursor-pointer group hover:bg-white rounded-sm transition-colors duration-500"
              onClick={() => handleAdd()}
            >
              <Add
                className="h-4 w-4  "
                stroke={`${getClassName(windowType)} transition duration-500`}
              />
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ShiftCard;
