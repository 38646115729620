import { TimeBlock } from "@/constants";
import { convertTimeToString, getDurationFromTime } from "@/utils/date";
import { CarotRight } from "../Icons/CarotRight";
import { PATTERN_LABEL_MAP } from "./PatternElement";
import { useEffect, useState } from "react";
import { ShiftTemplate } from "@/interface/shiftSettings";
import useSettingsContext from "./hooks/useSettingsContext";
import RulesTutorialDropdown from "./RulesTutorialDropdown";

const DEFAULT_STATE = Object.fromEntries(
  ["morning", "noon", "night"].map((timeBlock) => [timeBlock, false])
);

const ShiftTemplateDropDown = ({
  locationLabel,
  seniorityLabel,
  groupedShiftTemplates,
  settingsId,
  dayNumber,
  isLoading,
}: {
  locationLabel: string;
  seniorityLabel: string;
  groupedShiftTemplates: Record<TimeBlock, ShiftTemplate[]>;
  settingsId: string;
  dayNumber: number;
  isLoading: boolean;
}) => {
  const [isTimeBlockActive, setIsTimeBlockActive] = useState(DEFAULT_STATE);

  const { activateTutorial, setTutorialRuleState, createOrUpdateRuleFactory } =
    useSettingsContext();

  const clickHandler = createOrUpdateRuleFactory(settingsId, dayNumber);

  const [addTimeBlock, setAddTimeBlock] = useState<
    TimeBlock | "dayOff" | undefined
  >(undefined);

  if (addTimeBlock) {
    clickHandler({ timeBlock: addTimeBlock }).then(() => undefined);
    setAddTimeBlock(undefined);
  }

  return (
    <div
      className={`flex flex-col bg-white drop-shadow-lg rounded-md border border-[#E5E5E5] w-[220px] `}
    >
      <div
        className={`border-b-[#E5E5E5] border-b my-1 ${
          isLoading ? "animate-pulseFast" : ""
        }`}
      >
        <div className="text-sm font-semibold py-2 pl-7 pr-2">
          Shift templates for {seniorityLabel}, {locationLabel}
        </div>
      </div>
      <div className="px-0.5">
        {(["morning", "noon", "night"] as TimeBlock[]).map((timeBlock) => {
          return (
            <div className="flex relative text-sm font-medium items-center pt-1.5 pb-2 pl-7 pr-2 hover:bg-[#F5F8ED] rounded">
              <div
                className="cursor-pointer flex-grow"
                onClick={async () => {
                  if (!isLoading) {
                    if (activateTutorial(["chooseDropdownOption"])) {
                      setTutorialRuleState("createRule");
                      setAddTimeBlock(timeBlock);
                    } else {
                      await clickHandler({ timeBlock });
                    }
                  }
                }}
              >
                {PATTERN_LABEL_MAP[timeBlock]}
              </div>
              <div
                className="cursor-pointer hover:bg-[#D3E0B6] h-[16px] w-[16px] flex items-center justify-center rounded-sm"
                onClick={() => {
                  setIsTimeBlockActive({ ...DEFAULT_STATE, [timeBlock]: true });
                  if (activateTutorial(["chooseDropdownOption"])) {
                    setTutorialRuleState("createRule");
                  }
                }}
              >
                <CarotRight
                  pathFill={"black"}
                  size={{ width: "18px", height: "12px" }}
                />
              </div>
              {timeBlock === "morning" &&
              activateTutorial(["chooseDropdownOption"]) ? (
                <div className="absolute z-[100] left-[220px] w-[450px]">
                  <RulesTutorialDropdown
                    tutorialState={"chooseDropdownOption"}
                  />
                </div>
              ) : (
                isTimeBlockActive[timeBlock] && (
                  <div className="absolute z-[100] flex flex-col bg-white w-[220px] top-0 drop-shadow-lg rounded-md border border-[#E5E5E5] left-[220px] p-0.5">
                    {groupedShiftTemplates[timeBlock].length > 0 ? (
                      groupedShiftTemplates[timeBlock].map((template) => (
                        <div
                          className="flex justify-between py-2 pl-7 pr-2 cursor-pointer hover:bg-[#F5F8ED] rounded"
                          onClick={async () => {
                            await clickHandler({
                              templateId: template.settingsId,
                            });
                          }}
                        >
                          <div>{`${convertTimeToString(
                            template.time.from
                          )} - ${convertTimeToString(template.time.to)}`}</div>
                          <div className="text-xs flex items-center">
                            {`${getDurationFromTime({
                              from: template.time.from,
                              to: template.time.to,
                            })}h`}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="py-2 pl-7 pr-2">No templates found</div>
                    )}
                  </div>
                )
              )}
            </div>
          );
        })}
        <div
          className="flex justify-between relative text-sm font-medium items-center pt-1.5 pb-2 pl-7 pr-2 cursor-pointer hover:bg-[#F5F8ED] rounded"
          onClick={async () => {
            if (!isLoading) {
              if (activateTutorial(["chooseDropdownOption"])) {
                setTutorialRuleState("createRule");
                setAddTimeBlock("dayOff");
              } else {
                await clickHandler({
                  timeBlock: "dayOff",
                });
              }
            }
          }}
        >
          OFF
        </div>
      </div>
    </div>
  );
};

export default ShiftTemplateDropDown;
