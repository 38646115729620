import { useGetLocationsQuery } from "@/api/locationApi";
import { useLocation } from "@/store/location.store";
import { InfinitySpin } from "react-loader-spinner";

const LocationButtons = () => {
  const { data: locationsData, isLoading: isLocationLoading } =
    useGetLocationsQuery({
      all: true,
    });
  const { activeId: activeLocationId, setActiveLocation } = useLocation();
  return (
    <div className="flex bg-[#F5F5F5] rounded-md w-fit p-1">
      <div
        className={`text-sm px-3 py-1.5 font-medium cursor-pointer rounded-sm ${
          activeLocationId === "" ? "bg-white" : ""
        }`}
        onClick={() => {
          setActiveLocation(undefined);
        }}
      >
        All Zones
      </div>
      {locationsData && !isLocationLoading ? (
        locationsData.locations.map(
          (loc: { _id: string; label: string; shortLabel: string }) => (
            <div
              className={`text-sm px-3 py-1.5 font-medium cursor-pointer rounded-sm ${
                activeLocationId === loc._id ? "bg-white" : ""
              }`}
              onClick={() => {
                setActiveLocation(loc);
              }}
            >
              {loc.shortLabel}
            </div>
          )
        )
      ) : (
        <div className="flex justify-center items-center p-1">
          <InfinitySpin width="50" color="#737373" />
        </div>
      )}
    </div>
  );
};

export default LocationButtons;
