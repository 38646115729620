import { InfinitySpin } from "react-loader-spinner";
import { DateTime } from "luxon";
import { Dispatch, useRef, useState } from "react";

import Slider, { Settings } from "react-slick";
import CalendarSwitcher from "./CalendarSwitcher";
import Calendar from "./Calendar";
import { useGetDoctorShiftsQuery } from "../../api/rosterApi";

import { TIME_ZONE } from "../../constants";
import { useLocation } from "../../store/location.store";
import { useGetLeavesQuery } from "../../api/locationApi";
import { decrementMonth, incrementMonth } from "@/utils/date";
import { useRunOnChange } from "@/hooks/runOnChange";

const DoctorMonthCalendar = ({
  month,
  year,
  calenderBack,
  calenderNext,
  doctorId,
  setSelectedDateData,
  selectedDateData,
  initialDate,
}: {
  month: number;
  year: number;
  calenderBack: () => void;
  calenderNext: () => void;
  doctorId: string;
  setSelectedDateData: Dispatch<any>;
  selectedDateData: any | undefined;
  initialDate: Date;
}) => {
  const { activeId: activeLocationId } = useLocation();

  const { data: leaveDates } = useGetLeavesQuery(
    {
      locationId: activeLocationId,
      month: month,
      year: month,
    },
    { skip: !activeLocationId }
  );

  const opType = useRef<"next" | "prev">();

  const {
    isLoading: isDoctorShiftsLoading,
    isFetching: isDoctorShiftsFetching,
    data: doctorShiftsData,
  } = useGetDoctorShiftsQuery({
    doctorId,
    month,
    year,
  });

  const sliderRef = useRef<Slider>(null);

  if (!selectedDateData) {
    setSelectedDateData(
      doctorShiftsData?.find((x: { date: string }) => {
        return DateTime.fromISO(x.date)
          .setZone(TIME_ZONE)
          .equals(DateTime.fromJSDate(initialDate).setZone(TIME_ZONE));
      })
    );
  }

  useRunOnChange({ month, year }, (_, value) => {
    setSelectedDateData(
      doctorShiftsData?.find((x: { date: string }) => {
        return DateTime.fromISO(x.date)
          .setZone(TIME_ZONE)
          .equals(DateTime.fromObject({ ...value }, { zone: TIME_ZONE }));
      })
    );
  });

  const hasShifts =
    doctorShiftsData
      ?.filter((sd: any) => sd.schedules.length > 0)
      .map((sd: { date: string }) =>
        DateTime.fromISO(sd.date).setZone(TIME_ZONE)
      ) ?? [];

  const hasDayOff =
    doctorShiftsData
      ?.filter((sd: any) => sd.isDayOff)
      .map((sd: { date: string }) =>
        DateTime.fromISO(sd.date).setZone(TIME_ZONE)
      ) ?? [];

  const getDateClass = (date: Date) => {
    const isSelected = selectedDateData
      ? DateTime.fromJSDate(date)
          .setZone(TIME_ZONE)
          .equals(DateTime.fromISO(selectedDateData.date).setZone(TIME_ZONE))
      : false;

    const isShift = hasShifts.find((dateTime: DateTime) =>
      dateTime.equals(DateTime.fromJSDate(date).setZone(TIME_ZONE))
    );
    const isDayOff = hasDayOff.find((dateTime: DateTime) =>
      dateTime.equals(DateTime.fromJSDate(date).setZone(TIME_ZONE))
    );
    const isLocationBlocked = leaveDates?.find((leaveDate: string) =>
      DateTime.fromISO(leaveDate)
        .setZone(TIME_ZONE)
        .equals(DateTime.fromJSDate(date).setZone(TIME_ZONE))
    );

    if (isSelected) {
      return "!bg-[#3e7c76] cursor-pointer rounded-full !text-[#DAF7E0]";
    }
    if (isShift) {
      return "!bg-[#daf7e0] cursor-pointer rounded-full !text-[#3e7c76]";
    }
    if (isDayOff) {
      return "!bg-[#f4c94e] cursor-pointer rounded-full !text-[#fcedca]";
    }

    if (isLocationBlocked) {
      return "!text-[#E57041]";
    }

    return "cursor-pointer !text-[#3e7c76]";
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  const sliderSettings: Settings = {
    dots: false,
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerPadding: "0px",
    centerMode: true,
    swipe: false,
    variableWidth: false,
    initialSlide: 0,
    beforeChange: (_, afterIndex) => {
      if (opType.current === "next") {
        setCurrentIndex(afterIndex);
        calenderNext();
      } else {
        setCurrentIndex(afterIndex);
        calenderBack();
      }
    },
  };

  const getMonth = (index: number) => {
    // DECREMENT INDEX
    const prevIndex = currentIndex === 0 ? 2 : currentIndex - 1;
    // INCREMENT INDEX
    const nextIndex = currentIndex === 2 ? 0 : currentIndex + 1;

    if (index === prevIndex) {
      return decrementMonth(month, year);
    } else if (index === currentIndex) {
      return { month, year };
    } else if (index === nextIndex) {
      return incrementMonth(month, year);
    }

    throw new Error("Invalid Index");
  };

  return (
    <div className="rounded-xl drop-shadow-lg w-full slider-container">
      <Slider {...sliderSettings} ref={sliderRef}>
        {Array(3)
          .fill(0)
          .map((_, index) => (
            <div className="h-full w-full" key={index}>
              <div
                className={`bg-white w-full px-1.5 pt-2 pb-4 rounded-xl flex flex-col h-full ${
                  isDoctorShiftsFetching ? "animate-pulseFast" : ""
                }`}
              >
                <CalendarSwitcher
                  {...getMonth(index)}
                  onNext={() => {
                    sliderRef.current?.slickNext();
                    opType.current = "next";
                  }}
                  onPrev={() => {
                    sliderRef.current?.slickPrev();
                    opType.current = "prev";
                  }}
                  className="justify-between border-b-2 py-3 border-[#A5DEDC] text-black"
                  buttonClass="rounded-full w-fit bg-[#DAF7E0] h-fit p-1"
                  iconClass="w-4 h-4 bg-[#DAF7E0] text-[#3E7C76] rounded-full"
                />
                <div className="grid grid-cols-7 gap-1 w-full bg-white rounded-t-xl mt-0">
                  {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((weekDay) => (
                    <div
                      className="col-span-1 h-[50px] w-full text-center text-black3 text-[12px] flex items-center justify-center font-medium"
                      key={weekDay}
                    >
                      {weekDay}
                    </div>
                  ))}
                </div>
                {isDoctorShiftsLoading ? (
                  <div className="flex-grow flex justify-center items-center">
                    <InfinitySpin width="200" color="#4fa94d" />
                  </div>
                ) : (
                  <Calendar
                    month={month}
                    year={year}
                    className="!gap-1 bg-white !w-full rounded-b-2xl !px-1 !py-0"
                    onDayRender={({ day }) => {
                      const dateClass = getDateClass(day);
                      return (
                        <div
                          className="flex justify-center items-center"
                          key={day.getTime()}
                        >
                          <div
                            key={day.getTime()}
                            className={`w-7 h-7 flex items-center justify-center text-black3 font-medium text-[9.7px] ${dateClass}`}
                            onClick={() => {
                              if (dateClass !== "!text-[#E57041]") {
                                setSelectedDateData(
                                  doctorShiftsData?.find(
                                    (x: { date: string }) => {
                                      return DateTime.fromISO(x.date)
                                        .setZone(TIME_ZONE)
                                        .equals(
                                          DateTime.fromJSDate(day).setZone(
                                            TIME_ZONE
                                          )
                                        );
                                    }
                                  ) ?? []
                                );
                              }
                            }}
                          >
                            {DateTime.fromJSDate(day)
                              .setZone(TIME_ZONE)
                              .get("day")}
                          </div>
                        </div>
                      );
                    }}
                  />
                )}
              </div>
            </div>
          ))}
      </Slider>
    </div>
  );
};

export default DoctorMonthCalendar;
