const labelPriorityMap: Record<string, number> = {
  "#97A56B": 1,
  "#E57041": 2,
  "#84ACE2": 3,
  "#DF9C28": 4,
  "#A18072": 5,
  "#9775FA": 6,
};

export const sortLabels = (
  labels: { colorCode: string; _id: string; shortLabel: string }[]
): { colorCode: string; _id: string; shortLabel: string }[] => {
  return labels.sort((a, b) => {
    return labelPriorityMap[a.colorCode] - labelPriorityMap[b.colorCode];
  });
};
