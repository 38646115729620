import { Dispatch, SetStateAction, useState } from "react";
import Modal from "react-responsive-modal";

import { SENIORITIES, TimeBlock } from "@/constants";
import { useSeniority } from "@/store/seniority.state";
import { useLocation } from "@/store/location.store";
import {
  useCreateShiftSettingRuleMutation,
  useGetShiftSettingsRulesQuery,
  useGetShiftSettingsTemplatesQuery,
  useUpdateShiftSettingRuleMutation,
} from "@/api/shiftSettingsApi";
import handleResponse from "@/utils/handleResponse";
import LocationButtons from "./LocationsButtons";
import DeleteRuleModal from "./modals/DeleteRuleModal";
import useGroupShiftsTemplates from "./hooks/useGroupShiftsTemplates";
import ButtonTwo from "../Button/ButtonTwo";
import { useRunOnChange } from "@/hooks/runOnChange";
import Rule from "./Rule";
import { PatternElement } from "@/interface/shiftSettings";
import useSettingsContext from "./hooks/useSettingsContext";
import { Popover } from "react-tiny-popover";
import RulesTutorialDropdown from "./RulesTutorialDropdown";
import { InfinitySpin } from "react-loader-spinner";

// Needs a cleanup, and fix types add context

const Rules = () => {
  const [newRule, setNewRule] = useState<boolean>(false);

  const [deleteRule, setDeleteRule] = useState<any | undefined>(undefined);

  const { activeLocation } = useLocation();

  const { activeId: activeSeniorityId } = useSeniority();
  const activeSeniority = SENIORITIES.find((s) => s.id === activeSeniorityId);

  const seniorityLabel = activeSeniority?.label ?? "";
  const locationLabel = activeLocation?.shortLabel ?? "All Zones";

  const {
    data: shiftTemplates,
    isLoading,
    isFetching,
  } = useGetShiftSettingsTemplatesQuery(
    { locationId: activeLocation?._id, seniority: activeSeniorityId! },
    { skip: !activeSeniorityId }
  );

  const {
    data: shiftRules,
    isLoading: isLoadingShiftRules,
    isFetching: isFetchingShiftRules,
    refetch: refetchShiftRules,
  } = useGetShiftSettingsRulesQuery(
    { locationId: activeLocation?._id, seniority: activeSeniorityId! },
    { skip: !activeSeniorityId }
  );

  const [_, { isLoading: isCreateRuleLoading }] =
    useCreateShiftSettingRuleMutation();
  const [updateRule, { isLoading: isUpdateRuleLoading }] =
    useUpdateShiftSettingRuleMutation();

  useRunOnChange(shiftRules, () => {
    setNewRule(false);
  });

  const { activateTutorial, setTutorialRuleState } = useSettingsContext();

  const removePatternFactory =
    (settingsId: string, dayNumber: number) =>
    async ({
      templateId,
      timeBlock,
    }: {
      templateId?: string;
      timeBlock?: TimeBlock | "dayOff";
    }) => {
      const response = await updateRule({
        shiftSettingsId: settingsId,
        patternRemove: {
          templateId,
          timeBlock,
          dayNumber,
        },
      });
      handleResponse(response, "Rule updated !.", () => {
        refetchShiftRules();
      });
    };

  const updateRuleFactory =
    (settingsId: string) =>
    async ({
      startsOn,
      type,
    }: {
      startsOn?: number;
      type?: "allowed" | "notAllowed";
    }) => {
      const response = await updateRule({
        shiftSettingsId: settingsId,
        startsOn,
        type,
      });
      handleResponse(response, "Rule updated !.", () => {
        refetchShiftRules();
      });
    };

  const groupedShiftTemplates = useGroupShiftsTemplates({ shiftTemplates });

  return (
    <>
      <Modal
        open={Boolean(deleteRule)}
        onClose={() => {
          setDeleteRule(undefined);
        }}
        closeOnOverlayClick={false}
        showCloseIcon={false}
        center
        classNames={{ modal: "rounded-lg" }}
      >
        <DeleteRuleModal
          rule={deleteRule}
          seniorityLabel={seniorityLabel}
          locationLabel={locationLabel}
          closeModal={() => {
            setDeleteRule(undefined);
          }}
          refetchRule={refetchShiftRules}
        />
      </Modal>
      <div
        className={`border border-[#E5E5E5] rounded-lg p-6 flex flex-col max-h-full ${
          isLoading ||
          isFetching ||
          isLoadingShiftRules ||
          isFetchingShiftRules ||
          isCreateRuleLoading ||
          isUpdateRuleLoading
            ? "animate-pulseFast"
            : ""
        }`}
      >
        <LocationButtons />
        {isLoadingShiftRules ? (
          <div className="w-full flex justify-center items-center p-5">
            <InfinitySpin width="200" color="#67823A" />
          </div>
        ) : shiftRules && shiftRules.length > 0 ? (
          <div className="my-5 overflow-y-scroll small-scrollbar">
            <div className="flex flex-col gap-12">
              {shiftRules.map(
                (
                  rule: {
                    settingsId: string;
                    pattern: PatternElement[];
                    ruleNumber: number;
                    startsOn?: number;
                    type: "allowed" | "notAllowed";
                    activeTemplateMenu: string | undefined;
                    setActiveTemplateMenu: Dispatch<
                      SetStateAction<string | undefined>
                    >;
                    locationLabel: string;
                    seniorityLabel: string;
                  },
                  index: number
                ) => (
                  <Rule
                    {...rule}
                    isLoading={isCreateRuleLoading || isUpdateRuleLoading}
                    ruleNumber={index + 1}
                    ruleCount={(shiftRules?.length ?? 0) + (newRule ? 1 : 0)}
                    setDeleteRule={setDeleteRule}
                    groupedShiftTemplates={groupedShiftTemplates}
                    locationLabel={locationLabel}
                    seniorityLabel={seniorityLabel}
                    removePatternFactory={removePatternFactory}
                    updateRule={updateRuleFactory(rule.settingsId)}
                    enableTutorial={!newRule && index === shiftRules.length - 1}
                  />
                )
              ) ?? []}
            </div>
          </div>
        ) : (
          <div className="py-12">
            <div className="text-xl my-2">You don’t have any shift rules!</div>
            <div>Start by adding one below.</div>
          </div>
        )}
        {!newRule ? (
          <Popover
            isOpen={activateTutorial(["clickAddRule"])}
            content={<RulesTutorialDropdown tutorialState={"clickAddRule"} />}
            containerStyle={{ zIndex: "100" }}
            positions={"right"}
          >
            <div
              className={`w-fit pr-1 ${
                activateTutorial(["clickAddRule"]) ? "z-[100]" : ""
              }`}
            >
              <ButtonTwo
                clickHandler={() => {
                  setNewRule(true);
                  if (activateTutorial(["clickAddRule"])) {
                    setTutorialRuleState("clickAddPattern");
                  }
                }}
                variant={"fill"}
                fillClass="bg-[#4B5E2D]"
              >
                + Add a rule for {activeSeniority?.label ?? ""},{" "}
                {activeLocation?.shortLabel ?? "All Zones"}
              </ButtonTwo>
            </div>
          </Popover>
        ) : (
          <Rule
            isLoading={isCreateRuleLoading || isUpdateRuleLoading}
            settingsId={"new-setting-rule"}
            pattern={[]}
            type={"allowed"}
            ruleNumber={(shiftRules?.length ?? 0) + 1}
            ruleCount={(shiftRules?.length ?? 0) + 1}
            groupedShiftTemplates={groupedShiftTemplates}
            locationLabel={locationLabel}
            seniorityLabel={seniorityLabel}
            removePatternFactory={removePatternFactory}
            enableTutorial={true}
          />
        )}
      </div>
    </>
  );
};

export default Rules;
