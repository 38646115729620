import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "react-responsive-modal";
import { useState } from "react";

import navBarBackground from "@/assets/images/BackgroundDetail2.png";
import { useTableViewContext } from "@/hooks/context/useTableViewContext";
import { GROUP_COLORS, TIME_ZONE, TimeBlock } from "@/constants";
import { useSeniority } from "@/store/seniority.state";
import { decrementMonth, incrementMonth } from "@/utils/date";
import useReloadFirebase from "@/hooks/useReloadFirebase";
import { useLocation } from "@/store/location.store";

import Table from "../Icons/Table";
import CarotWide from "../Icons/CarotWide";
import User from "../Icons/User";
import UserRequest from "../Icons/UserRequest";
import Menu from "../Icons/Menu";
import CalendarRound3 from "../Icons/CalendarRound3";
import Calendar2 from "../Icons/Calendar2";
import Grid from "../Icons/Grid";
import AutomateScheduleModal from "../Modals/AutomateSchedulesModal";
import ProgressLoader from "./ProgressLoader";
import { useTableData } from "./hooks/data";
import { remove } from "@firebase/database";

// const LABEL_WIDTHS: Record<string, number> = {
//   "1": 100,
//   "2": 100,
//   "3": 100,
//   "4": 100,
//   "5": 100,
//   "6": 100,
//   "7": 100,
//   "8": 100,
//   "9": 100,
//   "10": 100,
//   "11": 100,
//   "12": 100,
// };

const MonthLabel = ({
  month,
  year,
  nextBackHandlers,
}: {
  month: number;
  year: number;
  nextBackHandlers?: { next: () => void; back: () => void };
}) => {
  const dateTime = DateTime.fromObject({ year, month }, { zone: TIME_ZONE });

  return (
    <div className="flex w-full h-full items-center gap-1">
      <div className="w-fit bg-white h-fit rounded drop-shadow-lg py-2">
        <div
          className={`text-xl ${
            dateTime.month % 2 === 0 ? "text-pink1" : "text-maroon4"
          } font-extrabold vertical-text -scale-100 h-fit`}
        >
          {dateTime.toFormat("yyyy")}
        </div>
      </div>
      <div className={"flex gap-[2px]"}>
        {nextBackHandlers && (
          <div
            className="group bg-white flex items-center px-[2px] cursor-pointer rounded-l-lg drop-shadow-lg"
            onClick={nextBackHandlers.back}
          >
            <CarotWide
              type="left"
              pathFill={"#CFB0A8"}
              classPathFill={
                dateTime.month % 2 === 0
                  ? "group-hover:fill-pink1"
                  : "group-hover:fill-maroon4"
              }
            />
          </div>
        )}
        <div
          className={`w-fit text-7xl bg-white ${
            dateTime.month % 2 === 0 ? "text-pink1" : "text-maroon4"
          } ${
            !nextBackHandlers ? "rounded-lg" : ""
          } font-black px-2 h-full drop-shadow-lg`}
        >
          {dateTime.toFormat("LLLL")}
        </div>
        {nextBackHandlers && (
          <div
            className="group bg-white flex items-center px-[2px] cursor-pointer rounded-r-lg drop-shadow-lg"
            onClick={nextBackHandlers.next}
          >
            <CarotWide
              type="right"
              pathFill={"#CFB0A8"}
              classPathFill={
                dateTime.month % 2 === 0
                  ? "group-hover:fill-pink1"
                  : "group-hover:fill-maroon4"
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

const TopNavBar = () => {
  const {
    month,
    year,
    calenderBack,
    calenderNext,
    resetFilters,
    setTableParams,
    monthLabel,
    monthRef,
    type,
  } = useTableViewContext();

  const { activeId: activeLocationId } = useLocation();
  const { activeId: activeSeniorityId, setActiveId } = useSeniority();

  const { refetchGetShiftsByMonth } = useTableData({
    month,
    year,
    activeLocationId,
    activeSeniorityId,
    type,
  });

  const {
    tableState,
    setTableState,
    setTimeBlockFilters,
    timeBlockFilters,
    progressLoader,
    setProgressLoader,
  } = useTableViewContext();

  useReloadFirebase({
    childKey: "progressBar",
    callback: (progress: number, ref) => {
      if (progress === 1) {
        setProgressLoader(null);
        remove(ref);
        refetchGetShiftsByMonth();
        toast.success("Schedules has been created.", {
          toastId: "schedules-has-been-created",
        });
      } else {
        setProgressLoader(progress);
        refetchGetShiftsByMonth();
      }
    },
  });

  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);

  const prevMonth = decrementMonth(month, year);
  const nextMonth = incrementMonth(month, year);

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        closeOnOverlayClick={false}
        showCloseIcon={false}
        center
        classNames={{ modal: "rounded-lg" }}
      >
        <AutomateScheduleModal
          initialMonth={month}
          initialYear={year}
          closeModal={() => {
            setModalOpen(false);
          }}
          refetchGetShiftsByMonth={refetchGetShiftsByMonth}
        />
      </Modal>
      <div className="w-full h-[100px] bg-gray4 relative p-2">
        <div
          className="w-full h-full opacity-10 bg-[length:1000px_500px] absolute top-0 left-0"
          style={{
            backgroundImage: `url(${navBarBackground})`,
          }}
        ></div>
        <div className="h-[80px] flex items-center justify-between gap-2 relative z-10 overflow-hidden">
          <div
            className={`absolute left-1 ${
              !monthLabel.stationary.show ? "hidden" : ""
            }`}
            ref={(ref) => {
              monthRef!.current.stationary = ref;
            }}
          >
            <MonthLabel
              month={
                monthLabel.stationary.type === "prev" ? prevMonth.month : month
              }
              year={
                monthLabel.stationary.type === "prev" ? prevMonth.year : year
              }
              nextBackHandlers={{
                next: () => {
                  const { month, year } = calenderNext();
                  resetFilters();
                  setTableParams({ month_: month, year_: year });
                },
                back: () => {
                  const { month, year } = calenderBack();
                  resetFilters();
                  setTableParams({ month_: month, year_: year });
                },
              }}
            />
          </div>
          <div
            ref={(ref) => {
              monthRef!.current.animated = ref;
            }}
            className={`absolute ${!monthLabel.animated.show ? "hidden" : ""}`}
          >
            <MonthLabel
              month={
                monthLabel.animated.type === "current" ? month : nextMonth.month
              }
              year={
                monthLabel.animated.type === "current" ? year : nextMonth.year
              }
              {...(monthLabel.animated.hasArrows
                ? {
                    nextBackHandlers: {
                      next: () => {
                        const { month, year } = calenderNext();
                        resetFilters();
                        setTableParams({ month_: month, year_: year });
                      },
                      back: () => {
                        const { month, year } = calenderBack();
                        resetFilters();
                        setTableParams({ month_: month, year_: year });
                      },
                    },
                  }
                : {})}
            />
          </div>
          <div className="absolute right-0 flex gap-2">
            <div className="my-auto">
              {progressLoader !== null ? (
                <ProgressLoader
                  progress={progressLoader}
                  doubleClickHandler={() => {
                    setProgressLoader(null);
                  }}
                />
              ) : (
                <button
                  className="bg-green4 flex items-center py-2 px-1.5 rounded-md w-[120px]"
                  onClick={() => {
                    setModalOpen(true);
                  }}
                >
                  <div className="scale-[.90]">
                    <Grid fill="white" width="30px" height="30px" />
                  </div>
                  <span className="text-white text-sm leading-none text-left ml-[3px]">
                    Automate Schedules
                  </span>
                </button>
              )}
            </div>
            <div className="bg-white w-[115px] h-[60px] p-1 rounded-lg my-auto">
              <div className="grid grid-cols-2 grid-rows-2 gap-1">
                {(
                  [
                    { key: "morning", label: "Morning" },
                    { key: "noon", label: "Noon" },
                    { key: "night", label: "Night" },
                    { key: "standBy", label: "Stand By" },
                  ] as unknown as { key: TimeBlock; label: string }[]
                ).map((obj) => (
                  <div
                    className={`text-white ${
                      timeBlockFilters.includes(obj.key)
                        ? GROUP_COLORS[obj.key].bgColor10
                        : "bg-gray5"
                    } text-[9px] rounded-md flex items-center justify-center cursor-pointer h-fit leading-none p-0.5`}
                    key={obj.key}
                    onClick={() => {
                      if (timeBlockFilters.includes(obj.key)) {
                        setTimeBlockFilters(
                          timeBlockFilters.filter(
                            (timeBlock) => timeBlock !== obj.key
                          )
                        );
                      } else {
                        setTimeBlockFilters([...timeBlockFilters, obj.key]);
                      }
                    }}
                  >
                    {obj.label}
                  </div>
                ))}
              </div>
            </div>
            <div className="flex h-[85px] items-center gap-1">
              <div className="flex flex-col gap-1 justify-center items-center w-[60px]">
                <div
                  className={`bg-lightGreen4 shadow-xl rounded-xl h-[41px] w-[45px] flex justify-center items-center`}
                >
                  {
                    {
                      users: (
                        <User
                          pathFill={"#FFFEF8"}
                          size={{ height: "35", width: "25" }}
                        />
                      ),
                      location: (
                        <Calendar2
                          pathFill={"#FFFEF8"}
                          size={{ height: "35", width: "25" }}
                        />
                      ),
                      requests: (
                        <UserRequest
                          pathFill={"#FFFEF8"}
                          size={{ height: "35", width: "25" }}
                        />
                      ),
                    }[tableState]
                  }
                </div>{" "}
                <div
                  className={`text-white bg-lightGreen4 font-[450] text-[12px] text-center rounded-lg px-1 w-fit text-nowrap`}
                >
                  {
                    {
                      users: "Users",
                      location: "Day View",
                      requests: "Requests",
                    }[tableState]
                  }
                </div>{" "}
              </div>
              <div className="flex flex-col gap-3">
                {tableState !== "users" && (
                  <div
                    className={`bg-white shadow-xl rounded-lg h-[26px] w-[28px] flex justify-center items-center cursor-pointer`}
                    onClick={() => {
                      setTableState("users");
                      setTableParams({
                        tableState_: "users",
                      });
                    }}
                  >
                    <User pathFill={"#71928D"} />
                  </div>
                )}
                {tableState !== "location" && (
                  <div
                    className={`bg-white shadow-xl rounded-lg h-[26px] w-[28px] flex justify-center items-center cursor-pointer`}
                    onClick={() => {
                      setTableState("location");
                      setTableParams({
                        tableState_: "location",
                      });
                    }}
                  >
                    <Calendar2 pathFill={"#71928D"} />
                  </div>
                )}
                {tableState !== "requests" && (
                  <div
                    className={`bg-white shadow-xl rounded-lg h-[26px] w-[28px] flex justify-center items-center cursor-pointer`}
                    onClick={() => {
                      setTableState("requests");
                      setTableParams({
                        tableState_: "requests",
                      });
                    }}
                  >
                    <UserRequest pathFill={"#71928D"} />
                  </div>
                )}
              </div>
            </div>
            <div className="flex h-[85px] items-center gap-1 bg-white rounded-md px-2">
              <div className="flex items-center bg-green12 rounded-lg p-0.5">
                <Table
                  pathFill={"white"}
                  rectFill={"#61763A"}
                  size={{ height: "47.5px", width: "57px" }}
                />
              </div>
              <div className="flex flex-col gap-1 items-center">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    if (!activeSeniorityId) {
                      setActiveId(1);
                    }
                    navigate("/dashboard");
                  }}
                >
                  <CalendarRound3 />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    if (!activeSeniorityId) {
                      setActiveId(1);
                    }
                    navigate(
                      `/dashboard/${window.btoa(
                        DateTime.fromObject(
                          { month, year },
                          { zone: TIME_ZONE }
                        )
                          .toJSDate()
                          .getTime()
                          .toString()
                      )}`
                    );
                  }}
                >
                  <Menu pathFill={"#545454"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopNavBar;
