import { toast } from "react-toastify";
import { useModal } from "../../../hooks/useModal";
import EditRound from "../../Icons/EditRound";
import DeleteGroupSubGroup from "../../Modals/DeleteGroupSubGroupModal";
import EditGroup from "../../Modals/EditGroupModal";
import DeleteRound from "../../Icons/DeleteRound";
// import GroupRulesModal from "../../Modals/GroupRulesModal";
// import NoteRound from "../../Icons/NoteRound";
import EditSubGroup from "../../Modals/EditSubGroupModal";
import LeaveQuotaModal from "../../Modals/LeaveQuotaModal";
import CalenderX from "../../Icons/CalenderX";
import { HiSearch } from "react-icons/hi";
import { useRef } from "react";
import Input from "../../Input";

const GroupBar = ({
  activeGroupId,
  activeSubGroupId,
  activeGroupData,
  activeSubGroupData,
  unassignedDoctors,
  triggerReload,
  unassignedDoctorsInGroup,
  setSearchQuery,
  setSearchType,
  setActiveGroupId,
  setActiveSubGroupId,
  setTriggerReload,
}: {
  activeGroupId: string;
  activeSubGroupId: string;
  activeGroupData: any;
  activeSubGroupData: any;
  unassignedDoctors: { doctors: any[] };
  triggerReload: boolean;
  unassignedDoctorsInGroup: any[];
  doctorTags: any[];
  activeTags: any[];
  assignActiveTags: (value: any[]) => void;
  setSearchQuery: (value: string) => void;
  setSearchType: (value: string) => void;
  setActiveGroupId: (value: string) => void;
  setActiveSubGroupId: (value: string) => void;
  setActiveGroupData: (value: any) => void;
  setTriggerReload: (value: boolean) => void;
}) => {
  const { openModal, closeModal } = useModal();
  const dropdownArea = useRef<HTMLDivElement>(null);
  return (
    <>
      <div className="flex items-center  justify-between max-w-[95%] mx-auto ">
        {activeSubGroupId == "" && (
          <div className="flex justify-center items-center 3xl:gap-3 gap-2 ">
            {activeGroupId ? (
              <button
                className="bg-primary  text-secondary rounded-full  text-sm"
                onClick={() => {
                  openModal(
                    <EditGroup
                      group={activeGroupData}
                      unassignedDoctors={unassignedDoctors.doctors}
                      onSuccess={() => {
                        setTriggerReload(!triggerReload);
                      }}
                    />,
                  );
                }}
              >
                <EditRound className="3xl:h-9 3xl:w-9 w-6 h-6" />
              </button>
            ) : null}
            {activeGroupId ? (
              <button
                className="bg-primary text-secondary rounded-full  text-sm"
                onClick={() => {
                  openModal(
                    <DeleteGroupSubGroup
                      onSuccess={(isGroup) => {
                        if (isGroup) {
                          toast.success("Group Deleted!");
                          setActiveGroupId("");
                        } else {
                          toast.success("Sub Group Deleted!");
                          setActiveSubGroupId("");
                        }
                        setTriggerReload(!triggerReload);
                        closeModal();
                      }}
                      closeModal={() => closeModal()}
                      group={activeGroupData}
                      subGroup={activeGroupData.subGroups.find(
                        (subGroup: { _id: any }) =>
                          subGroup._id === activeSubGroupId,
                      )}
                    />,
                  );
                }}
              >
                <DeleteRound className="3xl:h-9 3xl:w-9 w-6 h-6" />
              </button>
            ) : null}
            {/* {activeGroupId ? (
              // open rule popup
              <button
                className="bg-primary text-secondary rounded-full  text-sm"
                onClick={() => {
                  openModal(<GroupRulesModal groupId={activeGroupId} />);
                }}
              >
                <NoteRound className="3xl:h-9 3xl:w-9 w-6 h-6" />
              </button>
            ) : null} */}
          </div>
        )}
        {activeSubGroupId != "" && (
          <div className="flex justify-center items-center 3xl:gap-3 gap-2">
            {activeGroupId ? (
              <button
                className="bg-[#FFDEBB] text-secondary rounded-full  text-sm"
                onClick={() => {
                  openModal(
                    <EditSubGroup
                      subGroup={activeSubGroupData}
                      unassignedDoctorsInSubGroup={unassignedDoctorsInGroup}
                      onSuccess={() => {
                        setTriggerReload(!triggerReload);
                      }}
                    />,
                  );
                }}
              >
                <EditRound
                  bgColor="#FFDEBB"
                  textColor="#DF9C28"
                  className="3xl:h-9 3xl:w-9 w-6 h-6"
                />
              </button>
            ) : null}
            {activeGroupId ? (
              <button
                className="bg-primary text-secondary rounded-full  text-sm"
                onClick={() => {
                  openModal(
                    <DeleteGroupSubGroup
                      onSuccess={(isGroup) => {
                        if (isGroup) {
                          toast.success("Group Deleted!");
                          setActiveGroupId("");
                        } else {
                          toast.success("Sub Group Deleted!");
                          setActiveSubGroupId("");
                        }
                        setTriggerReload(!triggerReload);
                        closeModal();
                      }}
                      closeModal={() => closeModal()}
                      group={activeGroupData}
                      subGroup={activeGroupData.subGroups.find(
                        (subGroup: { _id: any }) =>
                          subGroup._id === activeSubGroupId,
                      )}
                    />,
                  );
                }}
              >
                <DeleteRound
                  bgColor="#FFDEBB"
                  textColor="#DF9C28"
                  className="3xl:h-9 3xl:w-9 w-6 h-6"
                />
              </button>
            ) : null}
            {activeGroupId || activeSubGroupId ? (
              <button
                className="bg-primary text-secondary rounded-full  text-sm"
                onClick={() => {
                  openModal(<LeaveQuotaModal subGroupId={activeSubGroupId} />);
                }}
              >
                <CalenderX
                  bgColor="#FFDEBB"
                  textColor="#DF9C28"
                  className="3xl:h-9 3xl:w-9 w-6 h-6"
                />
              </button>
            ) : null}
          </div>
        )}
        {activeGroupId || activeSubGroupId ? (
          <div
            ref={dropdownArea}
            className="relative   w-full px-10 3xl:scale-100 scale-90"
          >
            <div className="max-w-md ">
              <Input
                type="text"
                icon={{
                  content: (
                    <div className="3xl:h-11 3xl:w-11 h-10 w-10 bg-secondary rounded-full flex justify-center items-center -mt-3.5 -mr-3 ">
                      {" "}
                      <HiSearch size={20} className="text-white" />
                    </div>
                  ),
                  position: "right",
                }}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                placeholder="Search User or Tag..."
                className="!text-sm  placeholder:text-sm !rounded-3xl !placeholder:text-sm search-bar-shadow"
              />
            </div>
          </div>
        ) : null}
        <div className="flex items-center gap-4 3xl:scale-100 scale-90">
          <div className="w-20 font-bold 3xl:text-lg text-base">Filter By</div>
          <select
            name=""
            id=""
            className="!text-sm outline-none  3xl:h-11 h-10 w-60 px-2 font-semibold placeholder:text-sm !rounded-xl !placeholder:text-sm search-bar-shadow"
            placeholder="Filter By"
            onChange={(event) => {
              setSearchType(event.target.value);
            }}
          >
            <option value="name">By Doctor</option>
            <option value="tag">By Tag</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default GroupBar;
