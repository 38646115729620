import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CACHE_LIFE } from "../constants";
import { ShiftHours } from "@/components/SchedulingSettings/WorkingHoursItem";
import getQueryString from "@/utils/getQueryString";

const API_URL = import.meta.env.VITE_API_URL;

export const shiftSettingsApi = createApi({
  reducerPath: "shiftSettingsApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/api` }),
  keepUnusedDataFor: CACHE_LIFE,
  endpoints: (builder) => ({
    getShiftSettingsHours: builder.query<ShiftHours, { seniority: number }>({
      query: ({ seniority }) => ({
        url: `/shift/settings/hours?seniority=${seniority}`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    createOrUpdateShiftSettingsHours: builder.mutation<
      any,
      ShiftHours & { seniority: number }
    >({
      query: (body) => ({
        url: `/shift/settings/hours`,
        method: "POST",
        body,
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    getShiftSettingsTemplates: builder.query<
      any,
      { seniority: number; locationId?: string }
    >({
      query: ({ seniority, locationId }) => ({
        url: `/shift/settings/templates${getQueryString({
          seniority,
          locationId,
        })}`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    createShiftSettingTemplate: builder.mutation<any, any>({
      query: ({ shiftTemplate, variations, seniority, locationId }) => ({
        url: `/shift/settings/templates`,
        method: "POST",
        body: { shiftTemplate, variations, seniority, locationId },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    updateShiftSettingTemplate: builder.mutation<any, any>({
      query: ({
        shiftSettingsId,
        addVariations,
        removeVariations,
        update,
      }) => ({
        url: `/shift/settings/templates`,
        method: "PUT",
        body: {
          shiftSettingsId,
          addVariations,
          removeVariations,
          update,
        },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    getShiftSettingsRules: builder.query<
      any,
      { seniority: number; locationId?: string }
    >({
      query: ({ seniority, locationId }) => ({
        url: `/shift/settings/rules${getQueryString({
          seniority,
          locationId,
        })}`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    createShiftSettingRule: builder.mutation<any, any>({
      query: ({ timeBlock, templateId, seniority, locationId }) => ({
        url: `/shift/settings/rules`,
        method: "POST",
        body: { timeBlock, templateId, seniority, locationId },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    updateShiftSettingRule: builder.mutation<any, any>({
      query: ({
        shiftSettingsId,
        patternAdd,
        patternRemove,
        startsOn,
        type,
      }) => ({
        url: `/shift/settings/rules`,
        method: "PUT",
        body: {
          shiftSettingsId,
          patternAdd,
          patternRemove,
          startsOn,
          type,
        },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    deleteShiftSettings: builder.mutation<any, any>({
      query: ({ shiftSettingsId }) => ({
        url: `/shift/settings/delete`,
        method: "DELETE",
        body: {
          shiftSettingsId,
        },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
  }),
});

export const {
  useGetShiftSettingsHoursQuery,
  useCreateOrUpdateShiftSettingsHoursMutation,
  useGetShiftSettingsTemplatesQuery,
  useCreateShiftSettingTemplateMutation,
  useUpdateShiftSettingTemplateMutation,
  useGetShiftSettingsRulesQuery,
  useCreateShiftSettingRuleMutation,
  useUpdateShiftSettingRuleMutation,
  useDeleteShiftSettingsMutation,
} = shiftSettingsApi;
