import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";

import { GROUP_COLORS, Key, TimeObject } from "@/constants";
import { useTableViewContext } from "@/hooks/context/useTableViewContext";

import { Shift, ShiftType } from "@/interface/shift";
import ShiftCardLocation from "./ShiftCardLocation";
import ShiftCardTopBar from "./ShiftCardTopBar";
import useShiftOperations from "./hooks/shiftOperations";
import ShiftCardBottomBar from "./ShiftCardBottomBar";
import { DateTime } from "luxon";

type ShiftCardProps = {
  shifts: Shift[];
  className?: string;
  isFetching: boolean;
  date: Date;
  groupKey: Key;
  closeAddShiftCard?: () => void;
};

const getMultipleShiftId = (
  shiftsData: {
    _id: string;
    time: { to: string; from: string };
    location: { _id: string; shortLabel: string; label: string };
    type: ShiftType;
    slots: any[];
  }[]
) => {
  return shiftsData
    .map((sD) => sD._id)
    .sort((a, b) => {
      return a.localeCompare(b);
    })
    .join("-");
};

const ShiftCard = ({
  shifts,
  className,
  isFetching,
  date,
  groupKey,
}: ShiftCardProps) => {
  const { editShiftId, setEditShiftId } = useTableViewContext();

  const [mode, setMode] = useState<"delete" | "none">("none");

  const {
    isUpdateLoading,
    isDeleteShiftLoading,
    isAssignRandomBulkLoading,
    isLoadingCreateShift,
    handleAssignRandomBulk,
  } = useShiftOperations({
    date,
    shiftIds: shifts?.map((shiftData) => shiftData._id) ?? [],
  });

  const isLoading =
    isUpdateLoading ||
    isDeleteShiftLoading ||
    isAssignRandomBulkLoading ||
    isLoadingCreateShift ||
    isFetching;

  return (
    <div
      className={`col-span-1 relative transition-all ${className ?? ""} h-fit`}
    >
      <div
        className={`rounded-xl p-1.5 bg-white ${
          shifts && editShiftId === getMultipleShiftId(shifts)
            ? `${GROUP_COLORS[groupKey].borderColor2} border-[1.25px]`
            : ``
        } ${isLoading ? "animate-pulseFast" : ""} h-full`}
      >
        <ShiftCardTopBar
          mode={mode}
          displayType={"time"}
          handleOnBarClick={() => {
            if (editShiftId && editShiftId === getMultipleShiftId(shifts)) {
              setEditShiftId(undefined);
              setMode("none");
            } else {
              setEditShiftId(getMultipleShiftId(shifts));
            }
          }}
          handleGridClick={async () => {
            await handleAssignRandomBulk();
          }}
          isActive={editShiftId === getMultipleShiftId(shifts)}
          shift={shifts[0]}
          groupKey={groupKey}
          isLoading={isLoading}
          isAssignRandomLoading={isAssignRandomBulkLoading}
        />
        <div className="max-w-md mx-auto flex flex-col gap-2">
          <AnimatePresence>
            {shifts?.[0].slots.length === 0 ? (
              <motion.div
                key="noDataTransition"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.2 }}
                className={`pb-0.5 pt-1.5 border-x-0 border-t-0 border-b-[#BDBDBD] transition-opacity`}
              >
                <div className="font-medium text-sm p-1 text-black2 text-center">
                  No Data
                </div>
              </motion.div>
            ) : null}
            {shifts.map((shift) => (
              <ShiftCardLocation
                groupKey={groupKey}
                shift={shift}
                isFetching={isFetching}
                date={date}
              />
            ))}
          </AnimatePresence>
        </div>
        {editShiftId === getMultipleShiftId(shifts) && (
          <ShiftCardBottomBar
            type={mode}
            setType={setMode}
            groupKey={groupKey}
            shifts={shifts}
            date={date}
          />
        )}
      </div>
    </div>
  );
};

export default ShiftCard;
