import { ReactNode } from "react";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: { position: "right" | "left"; content: ReactNode };
  errorMsg?: string;
  className?: string;
}
const Input = (props: InputProps) => {
  const { icon, errorMsg = "", className, ...nativeProps } = props;
  return (
    <div className="relative flex w-full flex-wrap items-stretch m-auto">
      <div className="absolute z-[1] left-0 p-3.5">
        {icon ? (icon.position === "left" ? icon.content : null) : null}
      </div>
      <input
        {...nativeProps}
        className={`py-1 ${icon?.position === "left" ? "!pl-10" : ""} ${
          errorMsg ? "!border-red-500 !focus:border-red-500" : ""
        } placeholder-slate-400 placeholder:text-lg font-bold font-DM text-lg 3xl:h-11 h-10 rounded-md dm text-slate-600 relative bg-white border-2 border-primary outline-none focus:outline-none focus:ring-2 focus:ring-primary w-full px-4 ${className}`}
      />
      {errorMsg ? (
        <small className="text-red-500 font-semibold p-0.5">{errorMsg}</small>
      ) : null}
      <div className="absolute right-0 flex justify-center items-center 3xl:h-11 h-10 3xl:w-11 w-10 z-[2]">
        {icon ? (icon.position === "right" ? icon.content : null) : null}
      </div>
    </div>
  );
};

export default Input;
