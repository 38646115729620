import { useState } from "react";
import { useFormik } from "formik";
import { z } from "zod";
import Slider from "rc-slider";

import Button from "../Button/index";
import Input from "../Input/index";
import Typography from "../Typography/index";
import TagInput from "../TagInput/TagInput";
import NavTab, { getSenioritiesNavTabs } from "../NavTabs";

import { useModal } from "../../hooks/useModal";
import { useSeniority } from "../../store/seniority.state";
import { doctorApi, useUpdateDoctorMutation } from "../../api/doctorApi";
import handleResponse from "../../utils/handleResponse";
import ClockFour from "../Icons/ClockFour";
import ConfirmationModal from "./EmailConfirmationModal";
import { Send } from "../Icons/Send";

interface EditModalProps {
  onSubmit?: () => void;
  doctor: any;
}

export interface EditDoctorForm {
  email: string | undefined;
  doctorId: string;
  name: string;
  nickName?: string;
  phoneNumber?: string;
  experience: number;
  seniority: number;
  efficiencyScore: number;
  tags: string[];
}

const EditDoctor = (props: EditModalProps) => {
  const { onSubmit, doctor } = props;
  const [selectedSeniority, setSelectedSeniority] = useState<number>(
    doctor.seniority?.id ?? doctor.seniority
  );

  const { isAccepted, isInvited, resendHandler } = props.doctor;

  const { closeModal } = useModal();

  const { disabledSeniorities } = useSeniority();

  const [updateDoctor, { isLoading }] = useUpdateDoctorMutation();

  // State for modal visibility and email tracking
  const [isModalVisible, setModalVisible] = useState(false);
  const [pendingEmail, setPendingEmail] = useState<string>("");

  const formik = useFormik<EditDoctorForm>({
    initialValues: {
      doctorId: doctor._id,
      name: doctor.user?.name ?? "",
      nickName: doctor.user?.nickName ?? "",
      email: doctor.user?.email ?? "",
      phoneNumber: doctor.user?.phoneNumber ?? "",
      experience: doctor?.experience,
      seniority: doctor?.seniority?.id ?? doctor.seniority,
      efficiencyScore: doctor?.efficiencyScore,
      tags: doctor.tags ?? [],
    },
    validate: (values) => {
      const schema = z.object({
        doctorId: z.string().nonempty("Doctor id is required"),
        name: z.string().nonempty("Name is required"),
        nickName: z.string().nonempty("Nick Name is required"),
        phoneNumber: z.string().optional(),
        experience: z
          .number({
            required_error: "Experience is required",
            invalid_type_error: "Experience must be a number",
          })
          .min(0)
          .max(100),
        seniority: z.number({ required_error: "Seniority is required" }),
        efficiencyScore: z
          .number({
            required_error: "Efficiency score is required",
            invalid_type_error: "Efficiency score must be a number",
          })
          .min(0)
          .max(10),
        tags: z.array(z.string()).default([]),
      });

      const result = schema.safeParse(values);
      if (!result.success) {
        return Object.fromEntries(
          result.error.errors.map((err) => [err.path[0], err.message])
        );
      }
      return {};
    },
    onSubmit: async (values) => {
      if (values.email !== doctor.user?.email) {
        // If the email has changed, show the modal
        setPendingEmail(values.email!);
        setModalVisible(true);
      } else {
        // Proceed with save if the email hasn't changed
        await handleSave(values);
      }
    },
  });

  const handleModalConfirm = async () => {
    setModalVisible(false); // Close the modal
    await handleSave({ ...formik.values, email: pendingEmail }); // Save with updated email
  };

  const handleModalCancel = () => {
    setModalVisible(false); // Close the modal without saving
  };

  const handleSave = async (values: EditDoctorForm) => {
    const resp = await updateDoctor({
      doctorId: doctor._id,
      isEmailUpdated: pendingEmail ? true : false,
      ...Object.fromEntries(
        Object.entries(values).filter(([key, value]) => {
          if (!(key === "phoneNumber" && value === "")) {
            return [key, value];
          }
        })
      ),
    });

    handleResponse(resp, "Updated doctor successfully", () => {
      onSubmit?.();
      doctorApi.util.invalidateTags(["Doctor"]);
      closeModal();
    });
  };

  return (
    <>
      {/* Modal */}
      <ConfirmationModal
        isVisible={isModalVisible}
        onConfirm={handleModalConfirm}
        onCancel={handleModalCancel}
        email={pendingEmail}
      />

      <form
        onSubmit={formik.handleSubmit}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
          if (e.key === "Escape") {
            closeModal();
          }
        }}
        className="outer-shadow bg-white w-full max-w-2xl px-8 py-6 mx-auto rounded-xl"
      >
        <div className="">
          <Typography
            tag="div"
            className="font-semibold text-xl text-black2 mb-4"
          >
            Update Profile
          </Typography>
        </div>
        {!doctor.user.email ? (
          <>
            <div className="col-span-1 gap-4">
              <div
                id="alert"
                className="flex items-center border-l-4 border-yellow-500 p-4 text-sm text-yellow-700 rounded-md shadow-sm"
                style={{ backgroundColor: "#FFEBD1" }}
              >
                <ClockFour />
                <span className="font-medium">
                  This is a temporary account. Enter email ID to invite them to
                  your calendar.
                </span>
              </div>
            </div>
          </>
        ) : (
          <> 
          {!isAccepted && isInvited && (
            <>
              <div className="col-span-1 gap-4">
                <div
                  id="alert"
                  className="flex items-center border-yellow-500 p-1 text-sm text-[#5081B8] rounded-md shadow-sm"
                  style={{ backgroundColor: "#D8E5F0" }}
                >
                  <Send showBackground={false} size={17} />
                  <span className="font-medium flex items-center ml-0.5">
                    This user has been invited to your calendar, but haven’t joined yet.
                    <Button
                      className="inline-flex px-2 !h-8 rounded-full text-sm font-medium bg-secondary text-white !w-fit"
                      onClick={() => {
                        resendHandler && resendHandler();
                      }}
                    >
                      Resend Invite
                    </Button>
                  </span>
                </div>
              </div>

            </>
          )}
          </>
        )}
        <br />
        <div className="col-span-1 grid grid-cols-2 gap-4">
          <div className="col-span-1">
            <Typography
              tag="div"
              className="font-semibold text-base text-black3 mb-2"
            >
              Doctor Name
            </Typography>
            <Input
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              onBlur={formik.handleBlur}
              type="text"
              placeholder="Name"
              className="!text-base !placeholder:text-sm"
              errorMsg={formik.touched.name ? formik.errors.name : ""}
            />
          </div>
          <div className="col-span-1">
            <Typography
              tag="div"
              className="font-semibold text-base text-black3 mb-2"
            >
              Nick Name
            </Typography>
            <Input
              name="nickName"
              onChange={formik.handleChange}
              value={formik.values.nickName}
              onBlur={formik.handleBlur}
              type="text"
              placeholder="Nick Name"
              className="!text-base !placeholder:text-sm"
              errorMsg={formik.touched.nickName ? formik.errors.nickName : ""}
            />
          </div>
          <div className="col-span-1">
            <Typography
              tag="div"
              className="font-semibold text-base text-black3 mb-2"
            >
              Email
            </Typography>
            <Input
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              onBlur={formik.handleBlur}
              errorMsg={formik.touched.email ? formik.errors.email : ""}
              type="email"
              placeholder="name@guac.sg"
            />
          </div>
          <div className="col-span-1">
            <Typography
              tag="div"
              className="font-semibold text-base text-black3 mb-2"
            >
              Phone Number
            </Typography>
            <Input
              name="phoneNumber"
              onChange={formik.handleChange}
              value={formik.values.phoneNumber}
              onBlur={formik.handleBlur}
              errorMsg={
                formik.touched.phoneNumber ? formik.errors.phoneNumber : ""
              }
              type="tel"
              placeholder=""
            />
          </div>
          <div className="col-span-1">
            <Typography
              tag="div"
              className="font-semibold text-base text-black3 mb-2"
            >
              Experience ({formik.values.experience} years)
            </Typography>
            <Slider
              min={0}
              max={100}
              styles={{
                track: {
                  background: "#67823A",
                },
                handle: {
                  background: "#67823A",
                },
              }}
              value={formik.values.experience}
              onChange={(val) => {
                formik.setFieldValue("experience", val);
              }}
            />
          </div>
          <div className="col-span-1">
            <Typography
              tag="div"
              className="font-semibold text-base text-black3 mb-2"
            >
              Efficiency Score ({formik.values.efficiencyScore} out of 10)
            </Typography>
            <Slider
              min={0}
              max={10}
              styles={{
                track: {
                  background: "#67823A",
                },
                handle: {
                  background: "#67823A",
                },
              }}
              value={formik.values.efficiencyScore}
              onChange={(val) => {
                formik.setFieldValue("efficiencyScore", val);
              }}
            />
          </div>
        </div>

        <div className="col-span-2">
          <NavTab
            tabs={getSenioritiesNavTabs(disabledSeniorities)}
            tabType="neumorphism"
            activeId={selectedSeniority}
            onChange={(id) => {
              setSelectedSeniority(id);
              formik.setFieldValue("seniority", id);
            }}
          />
        </div>

        <div className="col-span-2 mb-8">
          <Typography
            tag="div"
            className="font-semibold text-base text-black3 mb-2"
          >
            Tags
          </Typography>
          <TagInput
            onChange={(tags) => formik.setFieldValue("tags", tags)}
            initialTags={doctor.tags}
          />
        </div>

        <div className="flex items-center justify-center gap-8">
          <Button
            disabled={isLoading}
            className="flex justify-center !bg-transparent !border-2 border-secondary text-center !text-secondary !rounded-xl h-10"
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            type="submit"
            className="flex justify-center text-center !rounded-xl"
          >
            {isLoading ? "Saving..." : "Save"}
          </Button>
        </div>
      </form>
    </>
  );
};

export default EditDoctor;
