import { RuleTutorialState } from "@/interface/shiftSettings";
import { create } from "zustand";

interface ProfileState {
  username: string;
  setUsername: (username: string) => void;
  role: string;
  setRole: (role: string) => void;
  profilePhoto: string;
  setProfilePhoto: (profilePhoto: string) => void;
  userId: string;
  setUserId: (userId: string) => void;
  featureData: { rulesTutorial: RuleTutorialState | "completed" };
  setFeatureData: (featureData: {
    rulesTutorial: RuleTutorialState | "completed";
  }) => void;
}

export const useProfile = create<ProfileState>((set) => ({
  username: "",
  setUsername: (username: string) => set({ username }),
  role: "",
  setRole: (role: string) => set({ role }),
  profilePhoto: "",
  setProfilePhoto: (profilePhoto: string) => set({ profilePhoto }),
  userId: "",
  setUserId: (userId: string) => set({ userId }),
  featureData: { rulesTutorial: "completed" },
  setFeatureData: (featureData: {
    rulesTutorial: RuleTutorialState | "completed";
  }) => set({ featureData }),
}));
