const Invited = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.00146484" width="19.9986" height="19.9974" rx="9.99871" fill="#D8E5F0" />
            <path d="M8.82592 17.9105C11.4162 18.2872 13.9228 17.4461 15.7252 15.6444C15.9565 15.4132 16.1747 15.167 16.3754 14.9105C16.4241 14.8481 16.4131 14.7585 16.3501 14.7111L15.6239 14.1629C15.5628 14.1168 15.4765 14.1279 15.4297 14.1876C15.2608 14.4019 15.0776 14.6085 14.8834 14.8027C13.5896 16.0965 11.8696 16.8083 10.0405 16.8083C8.23029 16.8083 6.52787 16.1108 5.23856 14.8436C3.91741 13.544 3.1763 11.7546 3.19124 9.9016C3.20618 8.09406 3.91741 6.39694 5.19699 5.11743C5.3912 4.92324 5.5971 4.74008 5.81209 4.57121C5.87185 4.5238 5.88289 4.43742 5.83678 4.37701L5.28857 3.65088C5.24116 3.58788 5.15087 3.57684 5.08917 3.62555C4.8326 3.82624 4.58643 4.04447 4.3552 4.27569C2.8366 5.79421 2 7.81283 2 9.96071C2 12.5093 3.1776 14.8767 5.27558 16.4414C6.31743 17.2181 7.53985 17.7248 8.82592 17.9118V17.9105Z" fill="#5081B8" />
            <path d="M8.89545 2.00355C8.29919 2.08928 7.71396 2.24126 7.15342 2.45624C7.08002 2.48482 7.043 2.56731 7.07288 2.6407L7.41453 3.48439C7.44311 3.55519 7.523 3.59026 7.59445 3.56298C8.06341 3.38372 8.55185 3.25707 9.05004 3.18433C9.12539 3.17329 9.17801 3.10379 9.16891 3.0278L9.05589 2.12435C9.04615 2.04576 8.97405 1.99186 8.89545 2.0029V2.00355Z" fill="#5081B8" />
            <path d="M16.5175 12.585L17.3613 12.926C17.4347 12.9559 17.5172 12.9189 17.5457 12.8455C17.7607 12.285 17.9121 11.7004 17.9972 11.1035C18.0082 11.0256 17.9536 10.9528 17.8757 10.9431L16.9722 10.8307C16.8962 10.821 16.8267 10.8743 16.8163 10.9496C16.7442 11.4478 16.6176 11.9362 16.4389 12.4051C16.4117 12.4766 16.4467 12.5564 16.5175 12.585Z" fill="#5081B8" />
            <path d="M13.0018 2.48828C12.4432 2.26745 11.86 2.10963 11.265 2.0187C11.187 2.00701 11.1143 2.06027 11.1033 2.13885L10.9818 3.041C10.9714 3.11634 11.0234 3.18649 11.0987 3.19818C11.5963 3.27547 12.0834 3.40732 12.5504 3.59047C12.6212 3.6184 12.7018 3.58398 12.731 3.51383L13.0811 2.67339C13.1116 2.60064 13.0759 2.51751 13.0025 2.48828H13.0018Z" fill="#5081B8" />
            <path d="M13.342 11.7451L10.2951 10.752C10.2165 10.7267 10.1632 10.8313 10.2301 10.88L11.1706 11.5613C11.2473 11.6171 11.2538 11.7295 11.1836 11.7932L8.31658 14.3976C8.2289 14.4775 8.0873 14.4282 8.06847 14.3112L7.40854 10.2974C7.3975 10.2305 7.43323 10.1642 7.49558 10.1363L17.2424 5.79898L7.33449 9.67971C7.30332 9.69205 7.26889 9.69335 7.23707 9.68425L4.77535 8.95552C4.62855 8.91201 4.63375 8.70287 4.78184 8.6665L17.8088 5.46709C17.9452 5.43332 18.0485 5.5892 17.9647 5.70156L13.5089 11.6931C13.4706 11.7444 13.4037 11.7659 13.3427 11.7464L13.342 11.7451Z" fill="#5081B8" />
        </svg>



    );
};

export default Invited;
