import React, { ReactNode } from "react";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: { position: "left" | "right"; content: ReactNode };
  variant?: "primary" | "ghost" | "bordered" | "borderless" | "danger" | "danger-bordered";
  className?: string;
}

const Button = (props: ButtonProps) => {
  const { icon, variant = "primary", className, ...nativeProps } = props;

  const variantClasses: Record<typeof variant, string> = {
    primary: "bg-secondary",
    ghost: "bg-transparent",
    bordered:
      "flex justify-center !bg-transparent !border-2 border-secondary text-center !text-secondary rounded-xl h-10",
    borderless:
      "flex justify-center bg-transparent text-center !text-secondary rounded-xl h-10",
    danger: "bg-dangerRed text-white border-dangerRed", // 🔴 Danger Variant
    "danger-bordered":
      "flex justify-center !bg-transparent !border-2 text-center border-dangerRed text-dangerRed rounded-xl h-10", // 🔴 No hover, always red border & text
  };

  const appliedTextColor = variant.startsWith("danger") ? "" : "text-white";

  return (
    <button
      {...nativeProps}
      className={`${
        variantClasses[variant]
      } w-full flex items-center gap-x-2 rounded-md ${appliedTextColor} h-12 font-bold disabled:bg-opacity-75 ${
        className ?? ""
      } ${
        nativeProps.disabled
          ? "cursor-not-allowed !opacity-75"
          : "cursor-pointer"
      }`}
    >
      {icon?.position === "left" && icon.content}
      {nativeProps.children}
      {icon?.position === "right" && icon.content}
    </button>
  );
};

export default Button;
