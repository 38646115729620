import { useMemo } from "react";
import { DateTime } from "luxon";

import { TIME_ZONE, TimeBlock, TIMING_LIST } from "@/constants";
import { convert12To24 } from "@/utils/date";

type GroupedShiftTemplates = Record<TimeBlock, any[]>;

const useGroupShiftsTemplates = ({
  shiftTemplates,
}: {
  shiftTemplates: any[];
}): GroupedShiftTemplates => {
  return useMemo(() => {
    const groupedShiftTemplates: GroupedShiftTemplates = {
      morning: [],
      noon: [],
      night: [],
    };

    if (shiftTemplates?.length > 0) {
      TIMING_LIST.slice(0, 3).forEach((timeElement) => {
        shiftTemplates.forEach((shiftTemplate) => {
          const fromTime24 = convert12To24(shiftTemplate.time.from);
          const fromTime = DateTime.fromObject(
            { hour: fromTime24.hours, minute: fromTime24.minutes },
            { zone: TIME_ZONE }
          );
          if (
            timeElement.time?.some(
              (t) =>
                fromTime >=
                  DateTime.fromObject(
                    {
                      hour: t.from.hour,
                      minute: t.from.min,
                    },
                    { zone: TIME_ZONE }
                  ) &&
                fromTime <=
                  DateTime.fromObject(
                    {
                      hour: t.to.hour,
                      minute: t.to.min,
                    },
                    { zone: TIME_ZONE }
                  )
            )
          ) {
            groupedShiftTemplates[timeElement.key as TimeBlock].push(
              shiftTemplate
            );
          }
        });
      });
    }

    return groupedShiftTemplates;
  }, [shiftTemplates]);
};

export default useGroupShiftsTemplates;
