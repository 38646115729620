import { useState } from "react";
import { HiSearch } from "react-icons/hi";
import { MutatingDots } from "react-loader-spinner";
import { toast } from "react-toastify";

import { useCreateLocation } from "../../store/location.store";
import api from "../../api/api.ts";
import { useSeniority } from "../../store/seniority.state";
import { useGetLocationsQuery } from "../../api/locationApi.ts";
import { useGetGroupsQuery } from "../../api/groupApi.ts.ts";

import Typography from "../Typography";

import DoctorCardVar from "../Cards/DoctorCardVar";
import Input from "../Input/index";
import Badge from "../Icons/Badge";
import Button from "../Button/index";
import GroupPill from "../Pills/GroupPill";

import { useModal } from "../../hooks/useModal";
import { getSeniority } from "../../utils/fetchSeniorityColor";

import defaultAvatar from "../../assets/images/avatar-default-light.webp";
import { SENIORITIES } from "@/constants.ts";

const LocationModal = () => {
  // convert this to a form
  const {
    seniorities,
    setSeniorities,
    groups: locationGroups,
    setGroups,
    closedDates,
    label,
  } = useCreateLocation();

  const [searchQuery, setSearchQuery] = useState("");

  const toggleSeniority = (id: number) => {
    if (seniorities.includes(id)) {
      setSeniorities(seniorities.filter((seniority) => seniority !== id));
    } else {
      setSeniorities([...seniorities, id]);
    }
  };

  const { closeModal } = useModal();

  const { isLoading: isGroupsLoading, data: groups } = useGetGroupsQuery({
    q: searchQuery,
  });

  const { refetch: refetchLocations, isLoading: isLocationLoading } =
    useGetLocationsQuery({
      all: true,
    });

  const { fetchFn: addLocation, isLoading: isLocationAddLoading } =
    api.location.useCreateLocation();

  const createLocation = async () => {
    const payload = {
      label,
      seniorities,
      groups: locationGroups.map((group) => group._id),
      closedDates,
    };

    const res = await addLocation({ data: payload });
    const { isSuccess, isError, error } = res.unwrap();

    if (isSuccess) {
      toast.success("Location created successfully");
      await refetchLocations();
    }

    if (isError) {
      toast.error(error?.response?.data?.message);
    }

    closeModal();
  };

  return (
    <div className="flex bg-white flex-col w-[430px] gap-6 outer-shadow px-8 py-6 mx-auto rounded-xl">
      <div className="font-semibold text-xl text-black2">
        Enable This Location For
      </div>
      <div className="grid grid-cols-3 gap-x-4">
        {SENIORITIES.map((seniority, i) => {
          return (
            <div key={i} className="flex items-center col-span-1">
              <input
                id={`checkbox-${seniority.id}`}
                type="checkbox"
                checked={seniorities.includes(seniority.id)}
                onChange={(e) => toggleSeniority(seniority.id)}
                className="w-5 h-5 !rounded-lg text-blue-600 bg-gray-100 border-gray-300  "
              />
              <label
                htmlFor={`checkbox-${seniority.id}`}
                className="ml-3 text-base font-semibold text-black1 "
              >
                <Typography
                  tag="span"
                  className={`${seniority.textColor} whitespace-nowrap`}
                >
                  {seniority.label}
                </Typography>
              </label>
            </div>
          );
        })}
      </div>

      <div className="font-semibold text-xl text-black2">
        Assign User Groups to Location
      </div>
      <div className="flex flex-wrap items-center gap-2">
        {locationGroups.map((group) => (
          <GroupPill
            name={group.title}
            className="!py-2 px-4 "
            onDelete={() => {
              setGroups(
                locationGroups.filter(
                  (g: { _id: string }) => g._id !== group._id
                )
              );
            }}
          />
        ))}
      </div>
      <Input
        type="text"
        icon={{
          content: <HiSearch size={20} />,
          position: "left",
        }}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search Group or People..."
        className="!text-sm placeholder:text-sm !rounded-2xl !placeholder:text-sm"
      />
      <div className="flex flex-col gap-y-2 max-h-72 overflow-y-auto p-2 small-scrollbar">
        {isGroupsLoading ? (
          <div className="flex flex-col justify-center items-center">
            <MutatingDots
              height={100}
              width="100"
              color="#67823A"
              secondaryColor="#67823A"
              radius="12.5"
              ariaLabel="mutating-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : null}
        {!isGroupsLoading &&
          groups?.map((group: any) => (
            <DoctorCardVar
              onClick={() => {
                if (locationGroups.map((g) => g._id).includes(group._id)) {
                  setGroups(
                    locationGroups.filter(
                      (g: { _id: string }) => g._id !== group._id
                    )
                  );
                } else {
                  setGroups([...locationGroups, group]);
                }
              }}
              selected={locationGroups.map((g) => g._id).includes(group._id)}
              key={group._id}
              variant="group"
              name={group.title}
              icon={
                <Badge
                  height={24}
                  width={24}
                  className="absolute top-0 right-0"
                  varient={getSeniority(group.seniority.id)}
                />
              }
              image={defaultAvatar}
            />
          ))}
        {!isGroupsLoading && !groups?.length ? (
          <div className="flex flex-col justify-center items-center min-h-72">
            <Typography tag="p" className="font-semibold text-black3">
              No groups found.
            </Typography>
          </div>
        ) : null}
      </div>

      <div className="flex items-center justify-center gap-8">
        <Button
          disabled={isLocationAddLoading || isLocationLoading}
          className="flex justify-center !bg-transparent !border-2 border-secondary text-center !text-secondary !rounded-xl h-10"
          onClick={() => {
            closeModal();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={isLocationAddLoading || isLocationLoading}
          className="flex justify-center text-center !rounded-xl"
          onClick={createLocation}
        >
          {isLocationAddLoading || isLocationAddLoading
            ? "Adding..."
            : "Submit"}
        </Button>
      </div>
    </div>
  );
};

export default LocationModal;
