import { motion } from "framer-motion";

import DoctorInfoWindowDetails from "../../Estimation/UserDataWindowComponents/DoctorInfoWindowDetails";

import { useUpdateDoctorMutation } from "../../../api/doctorApi";
import { useSeniority } from "../../../store/seniority.state";
import handleResponse from "../../../utils/handleResponse";

export const borderColors = {
  "3": "border-[#DF9C28]",
  "2": "border-[#84ACE2]",
  "1": "border-[#E57041]",
};

const DROPDOWN_LENGTH = 220;

const UserProfile = ({
  doctorId,
  name,
  nickName,
  email,
  experience,
  seniority,
  efficiency,
  phoneNumber,
  isInvited = true, // Default value for old users
  isAccepted = true, // Default value for old users
  groups,
  subGroups,
  tags,
  type,
  isIdle,
  resendHandler,
  onUpdate,
}: {
  doctorId?: string;
  name?: string;
  nickName?: string;
  email: string;
  experience: number;
  seniority?: { id: number; label: string };
  efficiency: number;
  phoneNumber?: string;
  isInvited?: boolean;
  isAccepted?: boolean;
  groups: any[];
  subGroups: any[];
  tags: any[];
  isIdle?: boolean;
  resendHandler?:() => void;
  type: { loc: "top" | "bottom"; page: "user-management" | "group-management" };
  onUpdate?: () => void;
}) => {
  const { activeId: activeSeniorityId } = useSeniority();
  const [updateDoctor, { isLoading }] = useUpdateDoctorMutation();
  return (
    <motion.div
      key="user-profile-card"
      initial={{
        y: type.loc === "top" ? -1 * DROPDOWN_LENGTH : DROPDOWN_LENGTH,
      }}
      animate={{ y: 0 }}
      exit={{ y: type.loc === "top" ? -1 * DROPDOWN_LENGTH : DROPDOWN_LENGTH }}
      transition={{ type: "interia", duration: 0.4 }}
      className={`col-span-2 row-span-3 h-full w-full flex justify-center items-center drop-shadow-lg rounded-xl group bg-white border-l-[4px] ${
        borderColors[activeSeniorityId?.toString() as "1" | "2" | "3"]
      } font-bold relative p-4 z-[2]`}
    >
      <DoctorInfoWindowDetails
        doctorId={doctorId ?? undefined}
        name={name ?? undefined}
        nickName={nickName ?? undefined}
        type={type.page}
        email={email}
        phone={phoneNumber ?? "No Phone Number Added"}
        experience={experience}
        seniority={seniority}
        effeciency={efficiency}
        isInvited={isInvited}
        isAccepted={isAccepted}
        groups={groups}
        subGroups={subGroups}
        tags={tags}
        isIdle={isIdle}
        isLoading={isLoading}
        resendHandler={resendHandler}
        onIdleSwitch={async (isIdle: boolean) => {
          const resp = await updateDoctor({ doctorId, isIdle });
          handleResponse(resp, "Succesfully updated Doctor.", () => {
            onUpdate?.();
          });
        }}
      />
    </motion.div>
  );
};

export default UserProfile;
