import { InfinitySpin } from "react-loader-spinner";
import { DateTime } from "luxon";
import { Dispatch } from "react";

import CalendarSwitcher from "./CalendarSwitcher";
import Calendar from "./Calendar";

import { TIME_ZONE } from "../../constants";
import { useLocation } from "../../store/location.store";
import { useGetLeavesQuery } from "../../api/locationApi";
import { addRemoveDateRanges } from "@/utils/date";
import { AnimatePresence, motion } from "framer-motion";

const isDateEqual = (dateOne: Date, dateTwo: Date) => {
  return DateTime.fromJSDate(dateOne)
    .setZone(TIME_ZONE)
    .equals(DateTime.fromJSDate(dateTwo).setZone(TIME_ZONE));
};

const MonthCalendar = ({
  month,
  year,
  calenderBack,
  calenderNext,
  setSelectedDates,
  isMultiSelect,
  selectedDates,
}: {
  month: number;
  year: number;
  calenderBack: () => void;
  calenderNext: () => void;
  setSelectedDates: Dispatch<Date[]>;
  selectedDates: Date[];
  isMultiSelect: boolean;
}) => {
  const { activeId: activeLocationId } = useLocation();

  const { data: leaveDates, isLoading } = useGetLeavesQuery(
    {
      locationId: activeLocationId,
      month: month,
      year: month,
    },
    { skip: !activeLocationId }
  );

  const getDateState = (date: Date) => {
    const isSelected =
      selectedDates.find((sDate) => isDateEqual(date, sDate)) ?? false;

    const isLocationBlocked = leaveDates?.find((leaveDate: string) =>
      DateTime.fromISO(leaveDate)
        .setZone(TIME_ZONE)
        .equals(DateTime.fromJSDate(date).setZone(TIME_ZONE))
    );

    if (isSelected) {
      return "selected";
    }

    if (isLocationBlocked) {
      return "locationBlocked";
    }

    return "normal";
  };

  const getDateClass = (
    dateState: "selected" | "locationBlocked" | "normal"
  ) => {
    if (dateState === "selected") {
      return "!bg-secondary !text-white cursor-pointer rounded-md";
    }

    if (dateState === "locationBlocked") {
      return "!text-[#E57041]";
    }

    return "cursor-pointer !text-black";
  };

  return (
    <div className="rounded-xl h-full w-full relative">
      <AnimatePresence>
        <motion.div
          key={`${month}-${year}`}
          initial={{ opacity: 0, zIndex: 0 }}
          animate={{ opacity: 1, zIndex: 10 }}
          exit={{ opacity: 0, zIndex: 0 }}
          transition={{ type: "interia", duration: 0.2 }}
          className={`absolute w-full px-1.5 pt-2 pb-4 rounded-xl flex flex-col h-full border-2 border-[#F4F4F5] ${
            isLoading ? "animate-pulseFast" : ""
          }`}
        >
          <CalendarSwitcher
            month={month}
            year={year}
            onNext={() => {
              calenderNext();
            }}
            onPrev={() => {
              calenderBack();
            }}
            className="justify-between py-3 text-black"
            buttonClass="p-1 rounded-md h-6 w-6 border-[1.5px] border-[#F4F4F5]"
            iconClass="w-4 h-4 text-[#A1A1AA]"
          />
          <div className="grid grid-cols-7 gap-1 w-full rounded-t-xl mt-0">
            {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((weekDay) => (
              <div
                className="col-span-1 h-[50px] w-full text-center text-black3 text-[12px] flex items-center justify-center font-medium"
                key={weekDay}
              >
                {weekDay}
              </div>
            ))}
          </div>
          {isLoading ? (
            <div className="flex-grow flex justify-center items-center">
              <InfinitySpin width="200" color="#4fa94d" />
            </div>
          ) : (
            <Calendar
              month={month}
              year={year}
              className="!gap-1 !w-full !px-1 !py-0"
              onDayRender={({ day }) => {
                const dateState = getDateState(day);
                const dateClass = getDateClass(dateState);
                return (
                  <div
                    className="flex justify-center items-center"
                    key={day.getTime()}
                  >
                    <div
                      key={day.getTime()}
                      className={`w-9 h-9 flex items-center justify-center text-zinc-950 font-normal text-[14px] select-none ${dateClass}`}
                      onClick={() => {
                        if (dateState !== "locationBlocked") {
                          if (isMultiSelect) {
                            setSelectedDates(
                              addRemoveDateRanges(day, selectedDates)
                            );
                          } else {
                            if (
                              selectedDates.find((sDate) =>
                                isDateEqual(sDate, day)
                              )
                            ) {
                              setSelectedDates(
                                selectedDates.filter(
                                  (sDate) => !isDateEqual(sDate, day)
                                )
                              );
                            } else {
                              setSelectedDates([...selectedDates, day]);
                            }
                          }
                        }
                      }}
                    >
                      {DateTime.fromJSDate(day).setZone(TIME_ZONE).get("day")}
                    </div>
                  </div>
                );
              }}
            />
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default MonthCalendar;
