const Grip = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.66675 7.99984C4.66675 7.26346 5.2637 6.6665 6.00008 6.6665C6.73646 6.6665 7.33342 7.26346 7.33342 7.99984C7.33342 8.73622 6.73646 9.33317 6.00008 9.33317C5.2637 9.33317 4.66675 8.73622 4.66675 7.99984Z"
        fill="#737373"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.66675 3.33333C4.66675 2.59695 5.2637 2 6.00008 2C6.73646 2 7.33342 2.59695 7.33342 3.33333C7.33342 4.06971 6.73646 4.66667 6.00008 4.66667C5.2637 4.66667 4.66675 4.06971 4.66675 3.33333Z"
        fill="#737373"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.66675 12.6668C4.66675 11.9304 5.2637 11.3335 6.00008 11.3335C6.73646 11.3335 7.33342 11.9304 7.33342 12.6668C7.33342 13.4032 6.73646 14.0002 6.00008 14.0002C5.2637 14.0002 4.66675 13.4032 4.66675 12.6668Z"
        fill="#737373"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.66675 7.99984C8.66675 7.26346 9.2637 6.6665 10.0001 6.6665C10.7365 6.6665 11.3334 7.26346 11.3334 7.99984C11.3334 8.73622 10.7365 9.33317 10.0001 9.33317C9.2637 9.33317 8.66675 8.73622 8.66675 7.99984Z"
        fill="#737373"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.66675 3.33333C8.66675 2.59695 9.2637 2 10.0001 2C10.7365 2 11.3334 2.59695 11.3334 3.33333C11.3334 4.06971 10.7365 4.66667 10.0001 4.66667C9.2637 4.66667 8.66675 4.06971 8.66675 3.33333Z"
        fill="#737373"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.66675 12.6668C8.66675 11.9304 9.2637 11.3335 10.0001 11.3335C10.7365 11.3335 11.3334 11.9304 11.3334 12.6668C11.3334 13.4032 10.7365 14.0002 10.0001 14.0002C9.2637 14.0002 8.66675 13.4032 8.66675 12.6668Z"
        fill="#737373"
      />
    </svg>
  );
};

export default Grip