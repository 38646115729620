import { toast } from "react-toastify";
import { HiDotsVertical } from "react-icons/hi";
import { DateTime } from "luxon";
import { Modal } from "react-responsive-modal";

import Button from "../Button";
import Typography from "../Typography";
import ContextMenu from "../ContextMenu";
import { useModal } from "../../hooks/useModal";
import ConfirmationModal from "../Modals/Confirmation";
import api from "../../api/api";
import { useLocation } from "../../store/location.store";
import LocationSettingsModal from "../Modals/LocationSettings";
import { InnerRoundedCalendar } from "../Calender/RoundedCalander";
import { useSeniority } from "../../store/seniority.state";
import { useCalendar } from "../../store/calendar.state";
import { TIME_ZONE } from "../../constants";
import { formatDateAPI } from "../../utils/formatDate";
import {
  useGetCalendarQuery,
  useGetLeavesQuery,
  useGetLocationsQuery,
} from "../../api/locationApi";
import useMonthCalendar from "../../hooks/useMonthCalendar";
import { useState } from "react";

const ModalCalendar = ({
  setModalOpen,
}: {
  setModalOpen: (value: boolean) => void;
}) => {
  const { month, year } = useCalendar();

  const {
    month: monthMonth,
    year: monthYear,
    calenderBack,
    calenderNext,
  } = useMonthCalendar({ initialMonth: month, initialYear: year });

  const { activeId: activeSeniorityId } = useSeniority();

  const { fetchFn: setLeaveDates } = api.location.useSetLeaveDates();
  const { refetch: refetchLocations } = useGetLocationsQuery({
    all: true,
  });

  const { activeId: activeLocationId } = useLocation();

  const { data: leaveDates } = useGetLeavesQuery(
    {
      locationId: activeLocationId,
      month: monthMonth,
      year: monthYear,
    },
    { skip: !activeLocationId }
  );

  const { refetch: refetchCalendar } = useGetCalendarQuery(
    {
      locationId: activeLocationId,
      month,
      year,
      seniority: activeSeniorityId,
    },
    { skip: !activeLocationId }
  );

  return (
    <InnerRoundedCalendar
      onCancel={() => setModalOpen(false)}
      initial={{
        selectedDates:
          leaveDates?.map((d: string) =>
            DateTime.fromISO(d).setZone(TIME_ZONE).toJSDate()
          ) ?? [],
      }}
      showFooter={true}
      onSubmit={async (dates) => {
        const setLeave = await setLeaveDates({
          data: {
            locationId: activeLocationId,
            dates: dates.map((d) => formatDateAPI(d)),
          },
        });

        const { isSuccess, isError, error } = setLeave.unwrap();

        if (isSuccess) {
          toast.success("Leave dates set successfully!");
          setModalOpen(false);
          await refetchLocations();
          await refetchCalendar();
        }
        if (isError) {
          toast.error(error?.response?.data?.message);
          setModalOpen(false);
        }
      }}
      month={monthMonth}
      year={monthYear}
      calenderBack={calenderBack}
      calenderNext={calenderNext}
    />
  );
};

interface LocationItemProps {
  name: string;
  isActive: boolean;
  skeleton?: boolean;
  disabled?: boolean;
  showContextMenu?: boolean;
  onSwitch?: () => void;
  onMenuOpen?: (isOpen: boolean) => void;
}

const LocationItem = (props: LocationItemProps) => {
  const {
    name,
    isActive,
    showContextMenu = true,
    onSwitch,
    skeleton = false,
    disabled = false,
    onMenuOpen,
  } = props;

  const { openModal } = useModal();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isContextMenuOpen, setContextMenuOpen] = useState(false);

  const { fetchFn: removeLocation } = api.location.useRemoveLocation();

  const { activeId: activeLocationId } = useLocation();

  const { refetch: refetchLocations, data: locationsData } =
    useGetLocationsQuery({
      all: true,
    });

  if (skeleton) {
    return (
      <div className={`cursor-pointer py-6`}>
        <div className={`text-center`}>
          <div
            className={"animate-pulse bg-white w-2/3 h-12 mx-auto rounded"}
          ></div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`${isActive ? "active " : "py-14"} cursor-pointer `}
      onClick={() => {
        if (disabled) return;
        onSwitch?.();
      }}
    >
      <Modal
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        closeOnOverlayClick={false}
        showCloseIcon={false}
        center
      >
        <ModalCalendar setModalOpen={setModalOpen} />
      </Modal>
      <div
        className={`flex ${
          isActive
            ? "justify-center text-secondary"
            : "justify-center text-white"
        } items-center gap-x-4 px-1`}
      >
        <Typography tag={"h6"} className={`${isActive ? "active" : ""}  `}>
          {name}
        </Typography>
        {isActive && showContextMenu ? (
          <ContextMenu
            className="!absolute right-0"
            onToggle={(isOpen) => {
              onMenuOpen?.(isOpen);
            }}
            loc={"left"}
            isOpen={isContextMenuOpen}
            setOpen={setContextMenuOpen}
            menu={
              <div className="flex flex-col divide-y-2 border-2 rounded-lg bg-white drop-shadow-lg">
                <button
                  className="hover:bg-slate-300 text-start p-3"
                  onClick={() => {
                    setModalOpen(true);
                  }}
                >
                  <Typography
                    tag="span"
                    className="whitespace-nowrap p-3 font-semibold text-black1"
                  >
                    View Calendar
                  </Typography>
                </button>
                <button
                  className="hover:bg-slate-300 text-start p-3"
                  onClick={() => {
                    openModal(
                      <LocationSettingsModal
                        location={locationsData?.locations.find(
                          (loc: any) => loc.id === activeLocationId
                        )}
                      />
                    );
                  }}
                >
                  <Typography
                    tag="span"
                    className="whitespace-nowrap p-3 font-semibold text-black1"
                  >
                    Settings
                  </Typography>
                </button>
                <button
                  className="hover:bg-slate-300 text-start p-3"
                  onClick={() => {
                    openModal(
                      <ConfirmationModal
                        message={`Do you want to remove the location ${name}?`}
                        onConfirm={async (close) => {
                          const removeloc = await removeLocation({
                            data: {
                              locationId: activeLocationId,
                            },
                          });
                          const { isSuccess, isError, error } =
                            removeloc.unwrap();

                          if (isSuccess) {
                            toast.success("Location removed successfully!");
                            refetchLocations();
                            close();
                          }
                          if (isError) {
                            toast.error(error?.response?.data?.message);
                            close();
                          }
                        }}
                      />
                    );
                  }}
                >
                  <Typography
                    tag="span"
                    className="whitespace-nowrap p-3 font-semibold text-black1"
                  >
                    Remove Location
                  </Typography>
                </button>
              </div>
            }
          >
            <Button className="!bg-transparent">
              <HiDotsVertical className="text-black3" size={20} />
            </Button>
          </ContextMenu>
        ) : null}
      </div>
    </div>
  );
};

export default LocationItem;
