import { AnimatePresence, motion } from "framer-motion";
import Delete from "../Icons/Delete";
import { Add } from "../Icons/Add";
import RivetTwo from "../Icons/RivetTwo";
import InputSlot, { InputSlotMode } from "./InputSlot";
import { sortLabels } from "../../utils/sortLabels";
import { useRef, useState } from "react";
import Undo from "../Icons/Undo";
import { ShiftType } from "../../interface/shift";
import FilterSlot from "./FilterSlot";
import { useCreateScheduleMutation } from "../../api/rosterApi";
import handleResponse from "../../utils/handleResponse";
import { Popover } from "react-tiny-popover";
import MetaData from "../Cards/MetaData";

type SlotProps = {
  index: number;
  totalSlots: number;
  shiftId: string;
  slot: { schedule?: any; filter?: any; labels?: any[] };
  isLoading: boolean;
  deleteSchedule: () => Promise<void>;
  onScheduleAdd: () => Promise<void>;
  triggerOnEnter: (index: number) => void;
  mode: InputSlotMode;
  setMode: (mode: InputSlotMode) => void;
  type: ShiftType;
};

const Slot = ({
  index,
  totalSlots,
  slot,
  shiftId,
  isLoading,
  deleteSchedule,
  onScheduleAdd,
  mode,
  setMode,
  triggerOnEnter,
  type,
}: SlotProps) => {
  const [canUndo, setCanUndo] = useState<boolean>(false);
  const deleteRef = useRef<{
    timeout?: NodeJS.Timeout;
    doctorId?: string;
    shiftId?: string;
  }>({});

  const [createSchedule, { isLoading: isCreateScheduleLoading }] =
    useCreateScheduleMutation();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div
      key={index}
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.1 }}
      className={`group relative flex items-center justify-center ${
        index === totalSlots - 1
          ? "py-1.5"
          : "border-b-[0.4px] border-b-[#BDBDBD] py-1.5"
      } ${isCreateScheduleLoading ? "animate-pulseFast" : ""}`}
    >
      <AnimatePresence>
        {mode === "doctor" ? (
          <InputSlot
            setMode={setMode}
            shiftId={shiftId}
            slotIndex={index}
            onScheduleAdd={onScheduleAdd}
            triggerOnEnter={triggerOnEnter}
            schedule={slot.schedule}
            filter={slot.filter}
            labels={slot.labels}
          />
        ) : mode === "filter" ? (
          <FilterSlot
            setMode={setMode}
            shiftId={shiftId}
            slotIndex={index}
            onScheduleAdd={onScheduleAdd}
            schedule={slot.schedule}
            filter={slot.filter}
          />
        ) : (
          <div
            className={`flex justify-center w-full items-center relative h-[30px]`}
          >
            <div className="flex gap-1 absolute left-2">
              {type === "onCall" ? (
                <div className="bg-orange4 text-white font-semibold text-xs p-1 rounded-lg">
                  OC
                </div>
              ) : (
                type === "standBy" && (
                  <div className="bg-blue4 text-white font-semibold text-xs p-1 rounded-lg">
                    SB
                  </div>
                )
              )}
              {slot.filter && (
                <div
                  className={`cursor-pointer`}
                  onClick={() => {
                    setMode("filter");
                  }}
                >
                  <RivetTwo />
                </div>
              )}
              {slot.schedule && (
                <Popover
                  isOpen={isOpen}
                  content={<MetaData metaData={slot.schedule.metaData} />}
                  onClickOutside={() => {
                    setIsOpen(false);
                  }}
                  containerStyle={{ zIndex: "100" }}
                >
                  <div
                    className="cursor-pointer w-4 h-4 rounded-full hover:bg-emerald-700"
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  ></div>
                </Popover>
              )}
            </div>
            {slot.schedule ? (
              <div
                className={`font-medium text-sm text-black2 text-center cursor-pointer`}
                onClick={() => {
                  setMode("doctor");
                }}
              >
                {slot.schedule.doctor.user.nickName ??
                  slot.schedule.doctor.user.name}
              </div>
            ) : (
              <button
                onClick={() => {
                  setMode("doctor");
                }}
              >
                <Add stroke="stroke-black2" className="h-4 w-4" />
              </button>
            )}
            <div className="flex absolute right-0">
              <div className="grid grid-cols-3 grid-rows-2 grid-flow-col gap-[1px] w-[57px] h-[24px]">
                {sortLabels([...(slot.labels ?? [])]).map(
                  (label: {
                    shortLabel: string;
                    colorCode: string;
                    _id: string;
                  }) => (
                    <div
                      key={label._id}
                      className="row-span-1 col-span-1 font-medium text-white text-[7px] rounded-sm flex items-center justify-center leading-none"
                      style={{ backgroundColor: label.colorCode }}
                    >
                      <div>{label.shortLabel.toUpperCase()}</div>
                    </div>
                  )
                )}
              </div>
              {!isLoading && canUndo && (
                <div
                  className="bg-primary rounded-full h-6 w-6 flex justify-center items-center my-auto animate-pulseFast cursor-pointer"
                  onClick={async () => {
                    if (deleteRef.current.timeout) {
                      clearTimeout(deleteRef.current.timeout);
                      const response = await createSchedule({
                        doctorId: deleteRef.current.doctorId,
                        slotIndex: index,
                        shiftId,
                      });
                      handleResponse(response, "Schedule restored", () => {
                        onScheduleAdd();
                        setCanUndo(false);
                        deleteRef.current = {};
                      });
                    }
                  }}
                >
                  <Undo />
                </div>
              )}
              {!isLoading && slot.schedule && (
                <div
                  className={`rounded my-auto cursor-pointer ml-1`}
                  onClick={async () => {
                    setCanUndo(true);
                    await deleteSchedule();
                    deleteRef.current.doctorId = slot.schedule.doctor._id;
                    deleteRef.current.timeout = setTimeout(async () => {
                      deleteRef.current = {};
                      setCanUndo(false);
                    }, 3000);
                  }}
                >
                  <Delete
                    className="h-3.5 w-3.5 group-hover:block hidden"
                    stroke="stroke-pink1 transition duration-500"
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default Slot;
