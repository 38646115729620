import { FC, useEffect } from "react";
import { motion } from "framer-motion";
import { InfinitySpin } from "react-loader-spinner";

import HorizontalScrollContainer from "../HorizontalScrollContainer";
import ShiftPill from "./ShiftPill";
import UserRequestCard from "./UserRequestCard";
import { formatDateAPI, formatTime } from "../../utils/formatDate";
import { Shift } from "../../interface/shift";
import Grid from "../Icons/Grid";

import {
  useAutoAcceptDayOffRequestsMutation,
  useAutoAcceptShiftRequestsMutation,
  useProcessDayOffRequestMutation,
  useProcessShiftRequestMutation,
} from "../../api/requestsApi";
import useHandleSuccessErrors from "../../hooks/handleSuccessErrors";
import { useSeniority } from "../../store/seniority.state";
import { useLocation } from "../../store/location.store";
import { checkContested, shiftRequestFilter } from "../../utils/shiftRequests";
import { useEstimationContext } from "../../hooks/context/useEstimationContext";
import { useRefetchContext } from "../../hooks/context/useRefetchContext";
import handleResponse from "../../utils/handleResponse";

interface RequestPhaseWindowProps {
  date: Date;
  shifts?: Array<Shift>;
  shiftRequests?: {
    requestedBy: { user: { name: string } };
    shifts: Shift[];
    _id: string;
    name: string;
    time: string;
    customTime?: { from: string; to: string };
    reason?: string;
    completedShift?: Shift;
    status: "completed" | "pending" | "rejected";
  }[];
  dayOffRequests?: any[];
  dayOff: { doctors: any[]; _id: string; totalDoctorsRequired: number };
  isLoading: boolean;
  isFetching: boolean;
  refetchShiftRequests: () => Promise<void>;
  refetchDayOffRequests: () => Promise<void>;
  refetchStats: () => Promise<void>;
  onShiftRequestProcess: () => Promise<void>;
  onDayOffRequestProcess: () => Promise<void>;
  refetchDayOffs: () => Promise<void>;
  deadline: string | undefined;
}

const RequestPhaseWindow: FC<RequestPhaseWindowProps> = ({
  date,
  shifts,
  shiftRequests,
  dayOffRequests,
  dayOff,
  isLoading,
  isFetching,
  refetchDayOffRequests,
  refetchShiftRequests,
  onShiftRequestProcess,
  onDayOffRequestProcess,
  refetchStats,
  refetchDayOffs,
}) => {
  const { activeId: activeSeniorityId } = useSeniority();
  const { activeId: activeLocationId } = useLocation();

  const { requestTab, setRequestTab } = useEstimationContext();

  const [
    processShiftRequest,
    { isLoading: isProcessShiftRequestLoading, isSuccess, isError, error },
  ] = useProcessShiftRequestMutation();

  const [
    processDayOffRequest,
    {
      isLoading: isDayOffLoading,
      isSuccess: isDayOffSuccess,
      isError: isDayOffError,
      error: dayOffError,
    },
  ] = useProcessDayOffRequestMutation();

  const [
    autoAcceptShiftRequests,
    {
      isLoading: isAutoAcceptLoading,
      isSuccess: isAutoAcceptSuccess,
      isError: isAutoAcceptError,
      error: autoAcceptError,
    },
  ] = useAutoAcceptShiftRequestsMutation();

  const [autoAcceptDayOffRequests, { isLoading: isAutoAcceptDayOffLoading }] =
    useAutoAcceptDayOffRequestsMutation();

  useHandleSuccessErrors({
    isSuccess: isAutoAcceptSuccess,
    isError: isAutoAcceptError,
    error: autoAcceptError,
    successMessage: "Shift Requests has been processed",
    successFunction: async () => {
      await refetchShiftRequests();
      await refetchStats();
      await onShiftRequestProcess();
    },
  });

  useHandleSuccessErrors({
    isSuccess,
    isError,
    error,
    successMessage: "Shift Request has been processed",
    successFunction: async () => {
      await refetchShiftRequests();
      await refetchStats();
      await onShiftRequestProcess();
    },
  });

  useHandleSuccessErrors({
    isSuccess: isDayOffSuccess,
    isError: isDayOffError,
    error: dayOffError,
    successMessage: "Day Off Request has been processed",
    successFunction: async () => {
      await refetchDayOffRequests();
      await refetchDayOffs();
      await refetchStats();
      await onDayOffRequestProcess();
    },
  });

  const { refetchMap, setRefetchMap } = useRefetchContext();

  useEffect(() => {
    if (activeLocationId) {
      const arg = {
        locationId: activeLocationId,
        date: formatDateAPI(date),
        seniority: activeSeniorityId,
      };
      if (refetchMap.shiftRequests[JSON.stringify(arg)]) {
        if (shiftRequests && shiftRequests.length > 0) {
          refetchShiftRequests();
        }
        refetchMap.shiftRequests[JSON.stringify(arg)] = null;
        setRefetchMap({
          ...refetchMap,
          shiftRequests: { ...refetchMap.shiftRequests },
        });
      }
    }
  }, [refetchMap, activeLocationId, activeSeniorityId, date]);

  const selectedShiftRequests =
    shiftRequests?.filter(shiftRequestFilter(requestTab ?? "", "all")) ?? [];

  const selectedDayOffRequests =
    dayOffRequests?.filter(
      (dR) => dR.requestedBy.seniority.id === activeSeniorityId
    ) ?? [];

  const shouldAllowAutoAccept = (() => {
    if (requestTab !== "dayOff") {
      const pendingSelectedShiftRequests = selectedShiftRequests.filter(
        (sR) => sR.status === "pending"
      );

      if (pendingSelectedShiftRequests.length === 0) {
        return false;
      }
      return !checkContested(pendingSelectedShiftRequests);
    } else {
      const pendingSelectedDayOffRequests = selectedDayOffRequests.filter(
        (dR) => dR.status === "pending"
      );
      if (pendingSelectedDayOffRequests.length === 0) {
        return false;
      }
      return (
        pendingSelectedDayOffRequests.length <= dayOff.totalDoctorsRequired
      );
    }
  })();

  return (
    <motion.div
      key="request-phase-window"
      className="relative w-full h-full transition duration-500"
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.8 }}
    >
      {isLoading ? (
        <div className="w-full h-full flex justify-center items-center">
          <InfinitySpin width="200" color="#67823A" />
        </div>
      ) : (
        <div className="w-full h-full">
          <div className="flex justify-between">
            <HorizontalScrollContainer className="w-full">
              {[
                ...(shifts?.map((val) => ({
                  name: `${formatTime(val.time.from)} - ${formatTime(
                    val.time.to
                  )}${
                    val.type !== "normal"
                      ? ` (${val.type === "onCall" ? "On Call" : "Stand By"})`
                      : ""
                  }`,
                  key: val._id,
                  doctorsRequired: val.totalDoctorsRequired,
                  doctorsFilled:
                    val.doctorsFilled +
                    (shiftRequests?.filter(shiftRequestFilter(val._id))
                      .length ?? 0),
                })) ?? []),
                {
                  name: "Day Off",
                  key: "dayOff",
                  doctorsRequired: dayOff.totalDoctorsRequired,
                  doctorsFilled:
                    (dayOffRequests?.filter((dR) => dR.status === "pending")
                      ?.length ?? 0) + dayOff.doctors.length,
                },
              ].map((pill) => {
                const isContested = pill.doctorsFilled > pill.doctorsRequired;

                return (
                  <ShiftPill
                    onClick={() => setRequestTab(pill.key)}
                    className="flex w-fit justify-between transition-colors relative"
                    style={{
                      zIndex:
                        (selectedShiftRequests.length +
                          (dayOffRequests?.length ?? 0)) *
                        4,
                    }}
                    isActive={requestTab === pill.key}
                    isActiveClassName={`${
                      isContested ? "bg-maroon2" : "bg-teal3"
                    } border border-teal2 text-white`}
                    key={pill.key}
                  >
                    {pill.name}
                    {requestTab === pill.key ? (
                      <div
                        className={`bg-white ml-4 ${
                          isContested ? "text-maroon2" : "text-teal3"
                        } rounded-md px-1`}
                      >
                        {pill.doctorsFilled}/{pill.doctorsRequired}
                      </div>
                    ) : (
                      <></>
                    )}
                  </ShiftPill>
                );
              })}
            </HorizontalScrollContainer>
            <div
              className={` px-2 py-1 rounded-lg ${
                shouldAllowAutoAccept ? "cursor-pointer bg-white" : "bg-gray2"
              } relative`}
              style={{
                zIndex:
                  (selectedShiftRequests.length +
                    (dayOffRequests?.length ?? 0)) *
                  4,
              }}
              onClick={async () => {
                if (shouldAllowAutoAccept && requestTab === "dayOff") {
                  const response = await autoAcceptDayOffRequests({
                    dayOffId: dayOff._id,
                    seniority: activeSeniorityId,
                  });

                  handleResponse(
                    response,
                    "Successfully accepeted day off requests",
                    () => {
                      refetchDayOffRequests();
                      refetchDayOffs();
                      refetchStats();
                    }
                  );
                } else if (shouldAllowAutoAccept) {
                  await autoAcceptShiftRequests({ shiftId: requestTab });
                }
              }}
            >
              <Grid
                className={
                  isAutoAcceptLoading || isAutoAcceptDayOffLoading
                    ? "animate-spin"
                    : ""
                }
                fill={"#838383"}
                width="23"
                height="23"
              />
            </div>
          </div>
          {selectedShiftRequests.length > 0 ? (
            <div className="grid grid-cols-2 mt-8 gap-x-10 gap-y-2">
              {selectedShiftRequests.map((sR, index) => (
                <UserRequestCard
                  row={{
                    numberRows: Math.ceil(selectedShiftRequests.length / 2),
                    number: Math.floor(index / 2),
                  }}
                  key={sR._id}
                  seniority={activeSeniorityId ?? 1}
                  reason={sR.reason}
                  name={sR.requestedBy.user.name}
                  status={sR.status}
                  locationLabel={sR.completedShift?.location.shortLabel}
                  customTime={sR.customTime}
                  isDisabled={
                    sR?.completedShift
                      ? sR.completedShift.location._id !== activeLocationId
                      : false
                  }
                  acceptHandler={async () => {
                    await processShiftRequest({
                      shiftRequestId: sR._id,
                      status: "completed",
                      shiftId: requestTab,
                    });
                  }}
                  rejectHandler={async () => {
                    await processShiftRequest({
                      shiftRequestId: sR._id,
                      status: "rejected",
                      shiftId: requestTab,
                    });
                  }}
                  isLoading={isProcessShiftRequestLoading || isFetching}
                />
              ))}
            </div>
          ) : requestTab !== "dayOff" ? (
            <div className="w-full h-full flex justify-center items-center font-medium">
              No shift requests sent
            </div>
          ) : (
            <div className="grid grid-cols-2 mt-8 gap-x-10 gap-y-2">
              {selectedDayOffRequests.map((dR, index) => (
                <UserRequestCard
                  row={{
                    numberRows: Math.ceil(selectedDayOffRequests.length / 2),
                    number: Math.floor(index / 2),
                  }}
                  seniority={activeSeniorityId ?? 1}
                  name={dR.requestedBy.user.name}
                  status={dR.status}
                  reason={dR.reason}
                  acceptHandler={async () => {
                    await processDayOffRequest({
                      dayOffRequestId: dR._id,
                      status: "completed",
                    });
                  }}
                  rejectHandler={async () => {
                    await processDayOffRequest({
                      dayOffRequestId: dR._id,
                      status: "rejected",
                    });
                  }}
                  isLoading={isDayOffLoading || isFetching}
                />
              )) ?? []}
            </div>
          )}
        </div>
      )}
    </motion.div>
  );
};

export default RequestPhaseWindow;
