import { useState } from "react";
import { HiMenu } from "react-icons/hi";
import Button from "../Button/index";
import { IoMdClose } from "react-icons/io";
import Guac from "../Icons/Guac";
import Typography from "../Typography";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../hooks/useModal";
import ResetPasswordModal from "../Modals/ResetPasswordModal";

// interface SideBarProps {
//   className?: string;
// }

const SideBar = () => {
  const navigate = useNavigate();

  const { openModal } = useModal();

  const sideBarListItems = [
    {
      id: 1,
      name: "Reset Password",
      onClick: () => {
        openModal(<ResetPasswordModal />);
      },
    },
    {
      id: 2,
      name: "Logout",
      onClick: () => {
        localStorage.removeItem("token");
        navigate("/");
      },
    },
  ];

  const [isExpanded, setIsExpanded] = useState(false);
  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="flex z-[60]">
      <div className="h-full flex flex-col rounded-r-2xl bg-secondary py-4 min-w-[6rem]">
        <div className="w-full flex flex-col gap-6 justify-center items-center pb-10">
          <Guac />
          <Button className="!bg-transparent flex items-center justify-center">
            <HiMenu
              className="text-[#FFFEF8] h-10 w-10 cursor-pointer"
              onClick={handleExpand}
            />
          </Button>
        </div>
      </div>
      {isExpanded ? (
        <div className="w-96 shadow-2xl flex flex-col p-4 !bg-white h-screen border-r-2 border-r-primary shadow-inset">
          <div className="flex items-center !justify-between p-4 border-b-2 border-b-primary w-full">
            <Typography
              tag="h2"
              className="mb-0 leading-normal font-semibold pl-2 text-xl"
            >
              GUAC
            </Typography>
            <IoMdClose
              className=" text-black cursor-pointer"
              size={25}
              onClick={handleExpand}
            />
          </div>
          <div className="flex-grow"></div>
          <div className="flex justify-center mt-4">
            <ul className="!bg-white rounded-lg w-full text-gray-900">
              {sideBarListItems.map((item) => {
                return (
                  <li
                    className="p-3 py-1 w-full"
                    key={item.id}
                    onClick={item.onClick}
                  >
                    <Typography
                      tag="p"
                      className="font-DM !font-bold text-[#64748B] cursor-pointer"
                    >
                      {item.name}
                    </Typography>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SideBar;
