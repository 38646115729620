import { convertTimeToString, Time } from "@/utils/date";
import Grip from "../Icons/Grip";
import { TimeBlock } from "@/constants";
import Cross from "../Icons/Cross";

export const PATTERN_LABEL_MAP: Record<TimeBlock | "dayOff", string> = {
  morning: "Morning shift",
  noon: "Afternoon shift",
  night: "Night shift",
  dayOff: "Off day",
};

const PatternElement = ({
  timeBlock,
  template,
  removePattern,
}: {
  timeBlock?: TimeBlock | "dayOff";
  template?: {settingsId:string; time:{from:Time;to:Time}};
  removePattern?: ({
    timeBlock,
    templateId,
  }: {
    timeBlock?: TimeBlock | "dayOff";
    templateId?: string;
  }) => Promise<void>;
}) => {
  return (
    <div className="bg-white border rounded-md border-[#D4D4D4] font-medium flex gap-1 px-3.5 py-2 text-sm items-center group relative hover:bg-[#F5F8ED]">
      {removePattern && (
        <div
          className="bg-white rounded-full h-[16px] w-[16px] group-hover:flex hidden absolute -top-1 -right-1 items-center justify-center cursor-pointer drop-shadow-lg"
          onClick={async () => {
            await removePattern({
              templateId: template?.settingsId,
              timeBlock,
            });
          }}
        >
          <Cross stroke="#737373" size={{ height: "5px", width: "5px" }} />
        </div>
      )}
      <Grip />
      {timeBlock
        ? PATTERN_LABEL_MAP[timeBlock]
        : template
        ? `${convertTimeToString(template.time.from)} - ${convertTimeToString(
            template.time.to
          )}`
        : "ERROR!"}
    </div>
  );
};

export default PatternElement;
