import { useLocation } from "@/store/location.store";
import ButtonTwo from "../Button/ButtonTwo";
import { useSeniority } from "@/store/seniority.state";
import { SENIORITIES, SHIFT_DOCTORS_REQUIRED_LIMIT } from "@/constants";
import {
  addToTime,
  convertTimeToString,
  getDurationFromTime,
  Time,
} from "@/utils/date";
import SortTwo from "../Icons/SortTwo";
import { Dispatch, useState } from "react";
import { Check } from "../Icons/Check";
import Cross from "../Icons/Cross";
import CarotVert from "../Icons/CarotVert";
import Modal from "react-responsive-modal";
import ShiftVariationModal from "./modals/ShiftVariationModal";
import { CarotRight } from "../Icons/CarotRight";
import LocationButtons from "./LocationsButtons";
import Edit from "../Icons/Edit";
import Delete from "../Icons/Delete";
import {
  useCreateShiftSettingTemplateMutation,
  useGetShiftSettingsTemplatesQuery,
  useUpdateShiftSettingTemplateMutation,
} from "@/api/shiftSettingsApi";
import TimeInput from "./TimeInput";
import handleResponse from "@/utils/handleResponse";
import DeleteTemplateModal from "./modals/DeleteTemplateModal";
import deepEqual from "deep-equal";
import { toast } from "react-toastify";
import { ShiftTemplate, ShiftTemplateParent } from "@/interface/shiftSettings";

interface Row_ extends ShiftTemplateParent {
  setCreateNew: Dispatch<React.SetStateAction<boolean>>;
  setActiveEdit: Dispatch<
    React.SetStateAction<ShiftTemplateParent | undefined>
  >;
  setIsActiveTemplate: Dispatch<
    React.SetStateAction<ShiftTemplateParent | undefined>
  >;
  setDeleteTemplate: (template: ShiftTemplateParent) => void;
  activeEdit: ShiftTemplate | undefined;
  postAcceptHandler: () => void;
}

const Templates = () => {
  const [activeTemplate, setIsActiveTemplate] = useState<
    ShiftTemplateParent | undefined
  >(undefined);

  const [deleteTemplate, setDeleteTemplate] = useState<
    ShiftTemplate | undefined
  >(undefined);

  const [createNew, setCreateNew] = useState<boolean>(false);
  const [activeEdit, setActiveEdit] = useState<ShiftTemplateParent | undefined>(
    undefined
  );

  const { activeLocation } = useLocation();

  const { activeId: activeSeniorityId } = useSeniority();
  const activeSeniority = SENIORITIES.find((s) => s.id === activeSeniorityId);

  const seniorityLabel = activeSeniority?.label ?? "";
  const locationLabel = activeLocation?.shortLabel ?? "All Zones";

  const {
    data: shiftTemplates,
    isLoading,
    isFetching,
    refetch,
  } = useGetShiftSettingsTemplatesQuery(
    { locationId: activeLocation?._id, seniority: activeSeniorityId! },
    { skip: !activeSeniorityId }
  );

  return (
    <>
      <Modal
        open={Boolean(activeTemplate)}
        onClose={() => {
          setIsActiveTemplate(undefined);
        }}
        closeOnOverlayClick={false}
        showCloseIcon={true}
        styles={{ closeIcon: { height: 17, width: 20 } }}
        center
        classNames={{ modal: "rounded-lg" }}
      >
        <ShiftVariationModal
          locationLabel={activeLocation?.label ?? "All Zones"}
          seniorityLabel={activeSeniority?.label ?? ""}
          initData={activeTemplate!}
          closeModal={() => {
            setIsActiveTemplate(undefined);
          }}
          closeEditRow={() => {
            setActiveEdit(undefined);
            setCreateNew(false);
          }}
          postSaveHandler={() => {
            refetch();
          }}
        />
      </Modal>
      <Modal
        open={Boolean(deleteTemplate)}
        onClose={() => {
          setDeleteTemplate(undefined);
        }}
        closeOnOverlayClick={false}
        showCloseIcon={false}
        center
        classNames={{ modal: "rounded-lg" }}
      >
        <DeleteTemplateModal
          template={deleteTemplate}
          seniorityLabel={seniorityLabel}
          locationLabel={locationLabel}
          closeModal={() => {
            setDeleteTemplate(undefined);
          }}
          refetchTemplates={refetch}
        />
      </Modal>
      <div
        className={`border border-[#E5E5E5] rounded-lg p-6 ${
          isLoading || isFetching ? "animate-pulseFast" : ""
        }`}
      >
        <LocationButtons />
        {shiftTemplates && shiftTemplates.length > 0 ? (
          <div className="my-8">
            <div className="flex flex-col w-[95%] gap-10">
              <div className="flex w-full justify-between gap-1 px-4">
                <div className="text-sm font-medium text-[#737373] flex items-center gap-1 w-[200px]">
                  Shift timings <SortTwo />
                </div>
                <div className="text-sm font-medium text-[#737373] w-[120px]">
                  Duration
                </div>
                <div className="text-sm font-medium text-[#737373] w-[120px]">
                  Slots
                </div>
                <div className="text-sm font-medium text-[#737373] w-[200px]">
                  Days
                </div>
                <div className="text-sm font-medium text-[#737373] w-[200px]"></div>
              </div>
              {shiftTemplates?.map((row: ShiftTemplateParent) => (
                <Row
                  {...row}
                  key={row.settingsId}
                  setActiveEdit={setActiveEdit}
                  setCreateNew={setCreateNew}
                  activeEdit={activeEdit}
                  setIsActiveTemplate={setIsActiveTemplate}
                  setDeleteTemplate={setDeleteTemplate}
                  postAcceptHandler={() => {
                    refetch();
                  }}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className="py-12">
            <div className="text-xl my-2">
              You don’t have any shift templates!
            </div>
            <div>Start by adding one below.</div>
          </div>
        )}

        {createNew ? (
          <EditableRow
            setIsActiveTemplate={setIsActiveTemplate}
            closeHandler={() => {
              setCreateNew(false);
            }}
            postAcceptHandler={() => {
              refetch();
            }}
          />
        ) : (
          <ButtonTwo
            clickHandler={() => {
              setCreateNew(true);
              setActiveEdit(undefined);
            }}
            variant={"fill"}
            fillClass="bg-[#4B5E2D]"
          >
            + Add a shift for {seniorityLabel}, {locationLabel}
          </ButtonTwo>
        )}
      </div>
    </>
  );
};

const Row = ({
  settingsId,
  time,
  slotsCount,
  daysOfWeek,
  variations,
  setActiveEdit,
  setDeleteTemplate,
  setCreateNew,
  setIsActiveTemplate,
  activeEdit,
  postAcceptHandler,
}: Row_) => {
  const [showVariations, setShowVariations] = useState(false);
  return (
    <>
      {activeEdit && activeEdit.settingsId === settingsId ? (
        <EditableRow
          initData={{ time, slotsCount, variations, daysOfWeek, settingsId }}
          setIsActiveTemplate={setIsActiveTemplate}
          closeHandler={() => setActiveEdit(undefined)}
          postAcceptHandler={postAcceptHandler}
        />
      ) : (
        <div
          className={`px-4 py-2 rounded-md ${
            variations.length > 0 ? "bg-[#F5F5F5] " : "rounded-md"
          }`}
        >
          <div
            className={`flex justify-between gap-1 ${
              showVariations && variations.length > 0 ? "mb-4" : ""
            }`}
          >
            <div className="w-[200px]">
              <div className="text-sm flex gap-1.5">
                {`${convertTimeToString(time.from)} - ${convertTimeToString(
                  time.to
                )}`}
                {variations.length > 0 && (
                  <div
                    className="cursor-pointer flex items-center"
                    onClick={() => {
                      setShowVariations(!showVariations);
                    }}
                  >
                    <CarotVert
                      type={showVariations ? "up" : "down"}
                      pathFill={"black"}
                      size={{ width: "10", height: "8" }}
                    />
                  </div>
                )}
              </div>
              {daysOfWeek.length > 3 && variations.length > 0 && (
                <div className="text-[10px]">On most days</div>
              )}
            </div>
            <div className="text-sm w-[120px]">
              {getDurationFromTime(time)} hours
            </div>
            <div className="text-sm w-[120px]">{slotsCount}</div>
            <div className="text-sm w-[200px] text-[#737373]">
              {daysOfWeek.length === 7
                ? "All Week"
                : `${daysOfWeek
                .map((dW) => `${dW[0].toUpperCase()}${dW.slice(1)}`)
                .join(", ")}`}
            </div>
            <div className="flex w-[200px] opacity-0 hover:opacity-100 transition-opacity duration-100 gap-6">
              <div className="flex-grow"></div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  setCreateNew(false);
                  setActiveEdit({
                    settingsId,
                    daysOfWeek,
                    time,
                    variations,
                    slotsCount,
                  });
                }}
              >
                <Edit stroke={"black"} />
              </div>{" "}
              <div
                className="cursor-pointer"
                onClick={() => {
                  setDeleteTemplate({
                    settingsId,
                    daysOfWeek,
                    time,
                    variations,
                    slotsCount,
                  });
                }}
              >
                <Delete stroke={"black"} />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            {showVariations &&
              variations.map((v) => (
                <div className="flex justify-between gap-1">
                  <div className="text-sm w-[200px] pl-2">
                    {`${convertTimeToString(
                      v.time.from
                    )} - ${convertTimeToString(v.time.to)}`}
                  </div>
                  <div className="text-sm w-[120px]">
                    {getDurationFromTime(v.time)} hours
                  </div>
                  <div className="text-sm w-[120px]">{v.slotsCount}</div>
                  <div className="text-sm w-[200px] text-[#737373]">
                    {v.daysOfWeek.length === 7
                      ? "All Week"
                      : `${v.daysOfWeek
                      .map((dW) => `${dW[0].toUpperCase()}${dW.slice(1)}`)
                      .join(", ")}`}
                  </div>
                  <div className="w-[200px]"></div>
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

const EditableRow = ({
  setIsActiveTemplate,
  initData,
  closeHandler,
  postAcceptHandler,
}: {
  setIsActiveTemplate: (activeTemplate: ShiftTemplateParent) => void;
  initData?: ShiftTemplateParent;
  closeHandler: () => void;
  postAcceptHandler: () => void;
}) => {
  const [time, setTime] = useState<{ from: Time; to: Time }>(
    initData
      ? {
          from: {
            hours: initData.time.from.hours,
            minutes: initData.time.from.minutes,
            amOrPm: initData.time.from.amOrPm,
          },
          to: {
            hours: initData.time.to.hours,
            minutes: initData.time.to.minutes,
            amOrPm: initData.time.to.amOrPm,
          },
        }
      : {
          from: { hours: 9, minutes: 0, amOrPm: "am" },
          to: { hours: 5, minutes: 0, amOrPm: "pm" },
        }
  );
  const [duration, setDuration] = useState<number>(
    initData ? getDurationFromTime(initData.time) : 8
  );
  const [slotsCount, setSlotsCount] = useState<number>(
    initData ? initData.slotsCount : 4
  );

  const { activeLocation } = useLocation();

  const { activeId: activeSeniorityId } = useSeniority();

  const [createShiftTemplate, { isLoading: isCreateShiftTemplateLoading }] =
    useCreateShiftSettingTemplateMutation();

  const [updateShiftTemplate, { isLoading: isUpdateShiftTemplateLoading }] =
    useUpdateShiftSettingTemplateMutation();

  const canSave =
    !initData ||
    !deepEqual(
      { time: initData.time, slotsCount: initData.slotsCount },
      { time, slotsCount }
    );

  return (
    <div
      className={`bg-[#F5F8ED] drop-shadow-md px-4 py-2.5 ${
        isCreateShiftTemplateLoading || isUpdateShiftTemplateLoading
          ? "animate-pulseFast"
          : ""
      }`}
    >
      <div className="flex w-full justify-between gap-6">
        <div className="flex gap-2">
          <div className="flex flex-col gap-1">
            <div className="text-xs font-medium text-[#737373]">Start Time</div>
            <div className="h-[40px] py-1 px-3 rounded-md border border-[#D4D4D4] bg-white">
              <TimeInput
                time={time.from}
                setTime={(time_) => {
                  setTime({ ...time, from: time_ });
                  setDuration(
                    getDurationFromTime({ from: time_, to: time.to })
                  );
                }}
                className={"text-sm w-full h-full outline-none"}
                errorClass={"bg-red-200 rounded-md"}
              />
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <div className="text-xs font-medium text-[#737373]">End Time</div>
            <div className="h-[40px] py-1 px-3 rounded-md border border-[#D4D4D4] bg-white">
              <TimeInput
                time={time.to}
                setTime={(time_) => {
                  setTime({ ...time, to: time_ });
                  setDuration(
                    getDurationFromTime({ from: time.from, to: time_ })
                  );
                }}
                className={"text-sm w-full h-full outline-none"}
                errorClass={"bg-red-200 rounded-md"}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <div className="text-xs font-medium text-[#737373]">Duration</div>
          <div className="h-[40px] py-1 px-3 rounded-md border border-[#D4D4D4] bg-white">
            <input
              className="text-sm w-full h-full outline-none"
              type="number"
              value={duration}
              onChange={(e) => {
                const duration_ =
                  Number(e.target.value) >= 24
                    ? 24
                    : Number(e.target.value) <= 1
                    ? 1
                    : Number(e.target.value);

                setDuration(duration_);
                setTime({
                  ...time,
                  to: addToTime({ time: time.from, hours: duration_ }),
                });
              }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <div className="text-xs font-medium text-[#737373]">Slots</div>
          <div className="flex gap-2">
            <div
              className="h-[40px] w-[40px] bg-white flex justify-center items-center rounded-md text-2xl cursor-pointer"
              onClick={() => {
                if (slotsCount > 1) {
                  setSlotsCount(slotsCount - 1);
                }
              }}
            >
              -
            </div>
            <div className="h-[40px] w-[100px] py-1 px-3 rounded-md border border-[#D4D4D4] bg-white">
              <input
                type="number"
                className="text-sm w-full h-full outline-none"
                value={slotsCount}
                onChange={(e) => {
                  setSlotsCount(Number(e.target.value));
                }}
              />
            </div>
            <div
              className="h-[40px] w-[40px] bg-white flex justify-center items-center rounded-md text-2xl cursor-pointer"
              onClick={() => {
                if (slotsCount < SHIFT_DOCTORS_REQUIRED_LIMIT) {
                  setSlotsCount(slotsCount + 1);
                }
              }}
            >
              +
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="h-[16px]"></div>
          <div className="w-[80px] flex-grow flex justify-center">
            <div className="flex w-[60px] items-center justify-between">
              <div
                className="cursor-pointer"
                onClick={async () => {
                  if (!initData && canSave) {
                    const resp = await createShiftTemplate({
                      shiftTemplate: {
                        time,
                        slotsCount,
                        daysOfWeek: [
                          "mon",
                          "tue",
                          "wed",
                          "thu",
                          "fri",
                          "sat",
                          "sun",
                        ],
                      },
                      seniority: activeSeniorityId,
                      locationId: activeLocation?._id,
                    });
                    handleResponse(resp, "Shift template created !", () => {
                      postAcceptHandler();
                      closeHandler();
                    });
                  } else if (canSave) {
                    const resp = await updateShiftTemplate({
                      update: { time, slotsCount },
                      shiftSettingsId: initData.settingsId,
                    });
                    handleResponse(resp, "Shift template updated !", () => {
                      postAcceptHandler();
                      closeHandler();
                    });
                  } else {
                    toast.warning(
                      "Cannot save, please modify or add a correct input."
                    );
                  }
                }}
              >
                <Check stroke={"#4B5E2D"} />
              </div>{" "}
              <div
                className="cursor-pointer"
                onClick={() => {
                  closeHandler();
                }}
              >
                <Cross
                  stroke="#DC2626"
                  size={{ width: "21px", height: "20px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="cursor-pointer flex gap-1 text-sm text-[#4B5E2D] font-medium items-center mt-2"
        onClick={() => {
          setIsActiveTemplate({
            settingsId: initData ? initData.settingsId : "new-template",
            time,
            slotsCount,
            variations: initData ? initData.variations : [],
            daysOfWeek: initData ? initData.daysOfWeek : [],
          });
        }}
      >
        Add variations for this shift{" "}
        <CarotRight pathFill="black" size={{ height: "15px", width: "10px" }} />
      </div>
    </div>
  );
};

export default Templates;
