import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useGetShifts } from "../hooks/estimation/useGetShifts";
import { usePrefetch as usePrefetchRoster } from "../api/rosterApi";
import { usePrefetch as usePrefetchRequests } from "../api/requestsApi";
import { usePrefetch as usePrefetchDoctors } from "../api/doctorApi";
import { DateTime } from "luxon";
import { TIME_ZONE } from "../constants";
import { useSeniority } from "../store/seniority.state";
import { formatDateAPI } from "../utils/formatDate";
import { useLocation } from "../store/location.store";

export const PrefetchContext = createContext<{
  triggerDoctorPrefetch: () => void;
  triggerStatsPrefetch: () => void;
  triggerRequestsPrefetch: () => void;
}>({
  triggerDoctorPrefetch: () => undefined,
  triggerStatsPrefetch: () => undefined,
  triggerRequestsPrefetch: () => undefined,
});

export const PrefetchContextProvider: FC<
  PropsWithChildren<{ activeDate: Date; prevDate: Date; nextDate: Date }>
> = ({ children, activeDate, prevDate, nextDate }) => {
  const { data: shiftData } = useGetShifts({
    activeDate,
    prevDate,
    nextDate,
  });

  const { activeId: activeLocationId } = useLocation();

  const [triggerDoctor, setTriggerDoctor] = useState(false);
  const [triggerStats, setTriggerStats] = useState(false);
  const [triggerRequests, setTriggerRequests] = useState(false);

  const { activeId: activeSeniorityId } = useSeniority();

  const prefetchDoctors = usePrefetchDoctors("getDoctors");
  const prefetchDoctorsFilter = usePrefetchRoster("getDoctorsFilter");
  // const prefetchDoctorSuggestions = usePrefetchRoster("getDoctorsSuggestion");
  const prefetchStats = usePrefetchRoster("getDoctorStats");

  const prefetchShiftRequests = usePrefetchRequests("getShiftRequests");
  const prefetchDayOffRequests = usePrefetchRequests("getDayOffRequests");

  const dateTime = DateTime.fromJSDate(activeDate).setZone(TIME_ZONE);

  const refStats = useRef<{
    callback: ((force: boolean) => void) | null;
    isFirst: boolean;
  }>({ callback: null, isFirst: true });
  const refDoctors = useRef<{
    callback: ((force: boolean) => void) | null;
    isFirst: boolean;
  }>({ callback: null, isFirst: true });
  const refRequests = useRef<{
    callback: ((force: boolean) => void) | null;
    isFirst: boolean;
  }>({ callback: null, isFirst: true });

  refStats.current.callback = useCallback(
    (force: boolean) => {
      prefetchStats(
        {
          month: dateTime.get("month"),
          year: dateTime.get("year"),
          seniority: activeSeniorityId,
        },
        { force }
      );
    },
    [activeSeniorityId, dateTime]
  );

  useEffect(() => {
    refStats.current.callback?.(false);
  }, [activeSeniorityId, dateTime]);

  useEffect(() => {
    if (!refStats.current.isFirst) {
      refStats.current.callback?.(true);
    }
  }, [triggerStats]);

  useEffect(() => {
    setTimeout(() => {
      refStats.current.isFirst = false;
    }, 30);
  }, []);

  refDoctors.current.callback = useCallback(
    (force: boolean) => {
      if (shiftData) {
        shiftData.flat().forEach((shift: { _id: unknown }) => {
          prefetchDoctorsFilter(
            {
              shiftId: shift._id,
            },
            { force }
          );
        });
      }
    },
    [shiftData]
  );

  useEffect(() => {
    prefetchDoctors({});
  }, []);

  useEffect(() => {
    refDoctors.current.callback?.(false);
  }, [shiftData]);

  useEffect(() => {
    if (!refDoctors.current.isFirst) {
      refDoctors.current.callback?.(true);
    }
  }, [triggerDoctor]);

  useEffect(() => {
    setTimeout(() => {
      refDoctors.current.isFirst = false;
    }, 30);
  }, []);

  refRequests.current.callback = useCallback(
    (force: boolean) => {
      if (activeLocationId) {
        [1, 2, 3].forEach((seniority) => {
          prefetchShiftRequests(
            {
              locationId: activeLocationId,
              date: formatDateAPI(prevDate),
              seniority,
            },
            { force }
          );
          prefetchShiftRequests(
            {
              locationId: activeLocationId,
              date: formatDateAPI(nextDate),
              seniority,
            },
            { force }
          );
        });
        prefetchDayOffRequests(
          {
            date: formatDateAPI(prevDate),
          },
          { force }
        );
        prefetchDayOffRequests(
          {
            date: formatDateAPI(nextDate),
          },
          { force }
        );
      }
    },
    [activeLocationId, nextDate, prevDate]
  );

  useEffect(() => {
    refRequests.current.callback?.(false);
  }, [activeLocationId, nextDate, prevDate]);

  useEffect(() => {
    if (!refRequests.current.isFirst) {
      refRequests.current.callback?.(true);
    }
  }, [triggerRequests]);

  useEffect(() => {
    setTimeout(() => {
      refRequests.current.isFirst = false;
    }, 30);
  }, []);

  return (
    <PrefetchContext.Provider
      value={{
        triggerDoctorPrefetch: () => {
          setTriggerDoctor((triggerDoctor) => !triggerDoctor);
        },
        triggerStatsPrefetch: () => {
          setTriggerStats((triggerStats) => !triggerStats);
        },
        triggerRequestsPrefetch: () => {
          setTriggerRequests((triggerRequests) => !triggerRequests);
        },
      }}
    >
      {children}
    </PrefetchContext.Provider>
  );
};
