import { FC } from "react";
import Edit from "./Icons/Edit";
import Delete from "./Icons/Delete";
import { BsPerson } from "react-icons/bs";
import { FaRegEnvelope } from "react-icons/fa";
import EditAdminUserModal from "../components/Modals/EditAdminUser";
import Password from "../components/Modals/Password";
import { useModal } from "../hooks/useModal";
import {
  useDeleteAdminUserMutation,
  useSetOrganisationAdminUserActiveInactiveMutation,
} from "../api/organisationApi";
import useHandleSuccessErrors from "../hooks/handleSuccessErrors";
import handleResponse from "@/utils/handleResponse";

interface OrganizationProps {
  name: string;
  email: string;
  isActive: boolean;
  userId: string;
  orgId: string;
  reloadUsers: () => void;
}
const OrganizationUserCard: FC<OrganizationProps> = ({
  email,
  isActive,
  name,
  userId,
  orgId,
  reloadUsers,
}) => {
  const { openModal } = useModal();

  const [toggleOrganisationAdminUserActive] =
    useSetOrganisationAdminUserActiveInactiveMutation();

  const [
    deleteAdminUser,
    {
      isLoading: isDeleteLoading,
      error: deleteError,
      isError: isDeleteError,
      isSuccess: isDeleteSuccess,
    },
  ] = useDeleteAdminUserMutation();

  useHandleSuccessErrors({
    isSuccess: isDeleteSuccess,
    isError: isDeleteError,
    error: deleteError,
    successMessage: "Super Admin Delete !",
    successFunction: async () => {
      reloadUsers();
    },
  });

  return (
    <div
      className={`outer-shadow rounded-md w-full p-3 ${
        isDeleteLoading ? "animate-pulseFast" : ""
      }`}
    >
      <div className="flex justify-between items-center pb-2.5 border-b">
        <div className="font-semibold flex items-center gap-2">
          <div className="font-bold border-2 rounded-full p-1.5 border-green3">
            <BsPerson className="h-5 w-5 text-green3" />
          </div>
          {name}
        </div>
        <div className="flex items-center justify-end gap-x-2">
          <span
            className="border-2 rounded-full border-green3 p-1.5 cursor-pointer"
            onClick={() =>
              openModal(
                <EditAdminUserModal
                  userId={userId}
                  initialValues={{ name, email }}
                  onSubmit={(password) => {
                    if (password) {
                      openModal(<Password password={password} />);
                    }
                    reloadUsers();
                  }}
                />
              )
            }
          >
            <Edit />
          </span>
          <span
            className="border-2 rounded-full border-green3 p-1.5 cursor-pointer"
            onClick={async () => {
              await deleteAdminUser({ userId, orgId });
            }}
          >
            <Delete />
          </span>
        </div>
      </div>
      <div className="flex justify-between items-center pt-2.5 ">
        <div className="flex items-center justify-end gap-3">
          <FaRegEnvelope className="h-4 w-4 text-green3" />
          <div className="text-sm font-medium"> {email}</div>
        </div>
        <div
          className="font-medium text-sm flex items-center gap-2 cursor-pointer"
          onClick={async () => {
            const resp = await toggleOrganisationAdminUserActive({
              userId,
              orgId,
            });
            handleResponse(resp, "Status toggled", () => {
              reloadUsers();
            });
          }}
        >
          <span
            className={`rounded-full ring ${
              isActive
                ? "ring-green-500 bg-green-500"
                : "ring-red-500 bg-red-500"
            }  p-1.5 outer-shadow h-4 w-4 `}
          ></span>
          {isActive ? "Active" : "In Active"}
        </div>
      </div>
    </div>
  );
};

export default OrganizationUserCard;
