import { GROUP_COLORS, Key, ShiftState } from "@/constants";
import { Add } from "../Icons/Add";
import { Minus } from "../Icons/Minus";
import Pen from "../Icons/Pen";
import { Shift } from "@/interface/shift";
import Delete from "../Icons/Delete";
import useShiftOperations from "./hooks/shiftOperations";
import Plus from "../Icons/Plus";
import CrossTwo from "../Icons/CrossTwo";
import { Check } from "../Icons/Check";
import { useLocation } from "@/store/location.store";
import { useSeniority } from "@/store/seniority.state";

const ShiftCardBottomBar = ({
  type,
  groupKey,
  setType,
  shift,
  shifts,
  date,
  shiftState,
  closeAddShiftCard,
}: {
  type: "delete" | "edit" | "add" | "none";
  setType:
    | ((type: "delete" | "edit" | "none") => void)
    | ((type: "delete" | "none") => void);
  groupKey: Key;
  shift?: Shift;
  shifts?: Shift[];
  date: Date;
  shiftState?: ShiftState;
  closeAddShiftCard?: () => void;
}) => {
  const {
    handleAddEmptySlot,
    handleRemoveEmptySlot,
    handleCreateShift,
    handleDeleteShift,
    handleUpdateShift,
    handleDeleteShifts,
  } = useShiftOperations({
    slots: shift?.slots,
    date,
    shiftId: shift?._id,
    shiftIds: shifts?.map((shift) => shift._id),
    onShiftCreate: () => {
      closeAddShiftCard?.();
    },
    onShiftDelete: () => {
      setType("none");
    },
    onShiftsDelete: () => {
      setType("none");
    },
    onShiftUpdate: () => {
      setType("none");
    },
    createEditShiftData: shiftState,
  });

  const isAdd = !shifts && !shift;

  const isMultiple = Boolean(shifts);

  const { activeId: activeLocationId } = useLocation();
  const { activeId: activeSeniorityId } = useSeniority();

  return (
    <div
      className={`${GROUP_COLORS[groupKey].bgColor10} px-1 py-0.5 rounded-md transition-all flex justify-between items-center`}
    >
      {(type === "none" || type === "edit") && !isAdd && (
        <div
          className="cursor-pointer my-0.5"
          onClick={() => {
            setType("delete");
          }}
        >
          <Delete stroke="stroke-black" />
        </div>
      )}
      {(type === "none" || type === "delete") && !isAdd && !isMultiple && (
        <div
          className="cursor-pointer"
          onClick={() => {
            (setType as (type: "delete" | "edit" | "none") => void)("edit");
          }}
        >
          <Pen pathFill="black" />
        </div>
      )}
      {(isAdd || isMultiple) && <div className="flex flex-grow"></div>}
      {type === "none" && !isMultiple && (
        <div
          className={`flex items-center text-xs justify-between rounded-md ${GROUP_COLORS[groupKey].bgColor6} w-12`}
        >
          <button
            className="p-0.5 cursor-pointer rounded-sm"
            onClick={() => handleRemoveEmptySlot()}
          >
            <Minus className="h-4 w-4" stroke={`stroke-black`} />
          </button>
          <button
            className="p-0.5 cursor-pointer rounded-sm"
            onClick={() => handleAddEmptySlot()}
          >
            <Add className="h-4 w-4 " stroke={`stroke-black`} />
          </button>
        </div>
      )}
      {type !== "none" && (
        <div
          className={`flex items-center text-xs justify-between rounded-md ${GROUP_COLORS[groupKey].bgColor6} p-0.5`}
        >
          {
            <div
              className={`flex ${GROUP_COLORS[groupKey].bgColor11} text-white text-[10px] rounded-md py-0.5 px-1 items-center`}
            >
              {type === "delete" && (
                <>
                  <Delete stroke="stroke-white" />
                  delete
                </>
              )}
              {!isMultiple && type === "edit" && (
                <>
                  <Pen pathFill="white" />
                  edit
                </>
              )}
              {isAdd && type === "add" && (
                <>
                  <Plus
                    color={"white"}
                    size={{ height: "15px", width: "15px" }}
                  />{" "}
                  add
                </>
              )}
            </div>
          }
          <button
            className="text-white p-0.5 cursor-pointer rounded-sm mx-1"
            onClick={() => {
              if (shift || shifts) {
                setType("none");
              } else {
                closeAddShiftCard?.();
              }
            }}
          >
            <CrossTwo pathFill="black" />
          </button>
          <div className="h-[20px] w-[1px] bg-white"></div>
          <button
            className="text-white p-0.5 cursor-pointer rounded-sm mx-0.5"
            onClick={async () => {
              if (type === "delete" && shift) {
                await handleDeleteShift();
              } else if (type === "delete" && shifts) {
                await handleDeleteShifts();
              } else if (shift) {
                await handleUpdateShift();
              } else {
                await handleCreateShift({
                  activeLocationId,
                  activeSeniorityId,
                });
              }
            }}
          >
            <Check stroke="black" size={{ height: "15px", width: "15px" }} />
          </button>
        </div>
      )}
    </div>
  );
};

export default ShiftCardBottomBar;
