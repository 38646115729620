import { DateTime } from "luxon";
import { TIME_ZONE } from "../constants";
import { roundToNearestQuarter } from "./math";

export const getDateFromHash = (dateHash: string): Date =>
  new Date(Number(window.atob(dateHash)));

export const getHashFromDate = (date: Date): string =>
  window.btoa(date.getTime().toString());

export const incrementMonth = (month: number, year: number) => {
  let newMonth = month;
  let newYear = year;
  if (month === 12) {
    newMonth = 1;
    newYear += 1;
  } else {
    newMonth += 1;
  }
  return { month: newMonth, year: newYear };
};

export const decrementMonth = (month: number, year: number) => {
  let newMonth = month;
  let newYear = year;
  if (month === 1) {
    newMonth = 12;
    newYear -= 1;
  } else {
    newMonth -= 1;
  }
  return { month: newMonth, year: newYear };
};

export function convertToAmPm(dateString: string): string {
  const date = new Date(dateString);

  return date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
}

export const getDateRange = (date: Date) => {
  const nextDate = DateTime.fromJSDate(date)
    .setZone(TIME_ZONE)
    .plus({ day: 1 })
    .toJSDate();
  const prevDate = DateTime.fromJSDate(date)
    .setZone(TIME_ZONE)
    .plus({ day: -1 })
    .toJSDate();

  return { prevDate, activeDate: date, nextDate };
};

export const getJSDate = (date: string) => {
  return DateTime.fromISO(date).setZone(TIME_ZONE).toJSDate();
};

export const getTimeString = (timeObject: {
  hour: number;
  minute: number;
  amOrPm: "am" | "pm";
}): string =>
  `${timeObject.hour.toString().padStart(2, "0")}:${timeObject.minute
    .toString()
    .padStart(2, "0")} ${timeObject.amOrPm.toUpperCase()}`;

/**
 * @description This is a special util function that can add multiple ranges in the same array (Used in)
 * @param date
 * @param dates
 * @returns
 */
export const addRemoveDateRanges = (date: Date, dates: Date[]) => {
  if (dates.length > 0) {
    let modifier = 0;
    if (dates.find((date_) => date_.getTime() < date.getTime())) {
      modifier = -1;
    } else if (dates.find((date_) => date_.getTime() > date.getTime())) {
      modifier = 1;
    }
    const datesToAdd = [date];
    let dayCount = 1;
    while (
      !dates.find((date_) =>
        DateTime.fromJSDate(date_)
          .setZone(TIME_ZONE)
          .equals(
            DateTime.fromJSDate(date)
              .setZone(TIME_ZONE)
              .plus({ day: modifier * dayCount })
          )
      )
    ) {
      datesToAdd.push(
        DateTime.fromJSDate(date)
          .setZone(TIME_ZONE)
          .plus({ day: modifier * dayCount })
          .toJSDate()
      );
      dayCount++;
    }
    return [...dates, ...datesToAdd];
  }
  return [date];
};

export const isDatesEqual = (date1: Date, date2: Date) => {
  return DateTime.fromJSDate(date1)
    .setZone(TIME_ZONE)
    .equals(DateTime.fromJSDate(date2).setZone(TIME_ZONE));
};

const currentDateTime = () => {
  return DateTime.now().setZone(TIME_ZONE);
};

export const currentWeekDates = () => {
  const todayDateTime = currentDateTime().set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  const weekDay = todayDateTime.weekday;

  return Array(7)
    .fill(0)
    .map((_, i) => {
      if (weekDay > i + 1) {
        return todayDateTime.minus({ days: weekDay - i + 1 });
      } else if (weekDay < i + 1) {
        return todayDateTime.plus({ days: i + 1 - weekDay });
      }
      return todayDateTime;
    })
    .map((dateTime) => dateTime.toJSDate());
};

export const weekFromCurrentDates = () => {
  const todayDateTime = currentDateTime().set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  return Array(7)
    .fill(0)
    .map((_, i) => {
      return todayDateTime.plus({ days: i }).toJSDate();
    });
};

export const currentMonthDates = () => {
  const todayDateTime = currentDateTime();

  return Array(todayDateTime.daysInMonth)
    .fill(0)
    .map((_, i) => {
      return DateTime.fromObject(
        { day: i + 1, month: todayDateTime.month, year: todayDateTime.year },
        { zone: TIME_ZONE }
      ).toJSDate();
    });
};

export const getDuration = ({ from, to }: { from: string; to: string }) => {
  return Math.round(
    DateTime.fromISO(to)
      .setZone(TIME_ZONE)
      .diff(DateTime.fromISO(from).setZone(TIME_ZONE), "hours").hours
  );
};

export const convertTimeToString = ({
  hours,
  minutes,
  amOrPm,
}: {
  hours: number;
  minutes: number;
  amOrPm: "am" | "pm";
}) => {
  return `${hours}${
    minutes !== 0 ? `:${minutes.toString().padStart(2, "0")}` : ""
  }${amOrPm}`;
};

export interface Time {
  hours: number;
  minutes: number;
  amOrPm: "am" | "pm";
}

export const convert12To24 = ({
  hours,
  minutes,
  amOrPm,
}: Time): { hours: number; minutes: number } => {
  const hours24 = amOrPm === "am" ? hours % 12 : (hours % 12) + 12;
  return { hours: hours24, minutes };
};

export const convert24To12 = ({
  hours,
  minutes,
}: {
  hours: number;
  minutes: number;
}): Time => {
  const amOrPm = hours > 11 ? "pm" : "am";
  const hours12 = hours % 12 || 12;

  return { amOrPm, minutes, hours: hours12 };
};

export const getDurationFromTime = ({
  from,
  to,
}: {
  from: Time;
  to: Time;
}): number => {
  const to24 = convert12To24(to);
  const from24 = convert12To24(from);

  const isNextDay = (() => {
    if (to24.hours === from24.hours) {
      return to24.minutes < from24.minutes;
    }
    return to24.hours < from24.hours;
  })();

  return roundToNearestQuarter(
    DateTime.fromObject(
      { hour: to24.hours, minute: to24.minutes },
      { zone: TIME_ZONE }
    )
      .plus({ day: isNextDay ? 1 : 0 })
      .diff(
        DateTime.fromObject(
          { hour: from24.hours, minute: from24.minutes },
          { zone: TIME_ZONE }
        ),
        "hours"
      ).hours
  );
};

export const addToTime = ({
  time,
  hours,
}: {
  time: Time;
  hours: number;
}): Time => {
  const twentyFour = convert12To24(time);
  const dateTime = DateTime.fromObject(
    { hour: twentyFour.hours, minute: twentyFour.minutes },
    { zone: TIME_ZONE }
  ).plus({ hours });
  return convert24To12({ hours: dateTime.hour, minutes: dateTime.minute });
};
