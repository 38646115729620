const ButtonTwo = ({
  clickHandler,
  disabled,
  variant,
  children,
  fillClass = "bg-secondary",
}: {
  clickHandler: () => void;
  disabled?: boolean;
  variant: "fill" | "outlined";
  fillClass?: string;
  children: JSX.Element | string | Array<JSX.Element | string>;
}) => {
  return (
    <button
      onClick={clickHandler}
      disabled={disabled}
      className={`border border-[#E4E4E7] rounded-lg ${
        variant === "fill"
          ? `${fillClass} text-white`
          : "text-secondary bg-white"
      } text-sm font-medium py-3 px-4 relative`}
    >
      {disabled && (
        <div className="absolute w-full h-full right-0 top-0 opacity-50 bg-white z-50"></div>
      )}
      {children}
    </button>
  );
};

export default ButtonTwo;
