import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";

import useMonthCalendar from "@/hooks/useMonthCalendar";
import { useLocation } from "@/store/location.store";
import { useSeniority } from "@/store/seniority.state";
import { getDateFromHash, getHashFromDate } from "@/utils/date";
import { useGetLocationsQuery } from "@/api/locationApi";
import { useCalendar } from "@/store/calendar.state";
import { Key } from "@/constants";

export type SetTableParamsArgs = {
  activeDate_?: Date | null;
  seniority?: number | null;
  locationId?: string | null;
  tableState_?: "location" | "requests" | "users";
  isShiftTimeToggle_?: boolean;
  isLocationFilter_?: boolean;
  isDayOffFilter_?: boolean;
  month_?: number;
  year_?: number;
};

export const TableViewContext = createContext<{
  month: number;
  calenderBack: () => { month: number; year: number };
  year: number;
  calenderNext: () => { month: number; year: number };
  editShiftId: string | undefined;
  setEditShiftId: Dispatch<SetStateAction<string | undefined>>;
  tableState: "location" | "requests" | "users";
  setTableState: Dispatch<SetStateAction<"location" | "requests" | "users">>;
  isShiftTimeToggle: boolean;
  setIsShiftTimeToggle: Dispatch<SetStateAction<boolean>>;
  isDayOffFilter: boolean;
  setIsDayOffFilter: Dispatch<SetStateAction<boolean>>;
  isLocationFilter: boolean;
  setIsLocationFilter: Dispatch<SetStateAction<boolean>>;
  activeDate: Date | undefined;
  setActiveDate: Dispatch<SetStateAction<Date | undefined>>;
  searchQuery: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;
  monthLabel: {
    animated: {
      type: "next" | "current" | "prev";
      hasArrows: boolean;
      show: boolean;
    };
    stationary: {
      type: "next" | "current" | "prev";
      hasArrows: boolean;
      show: boolean;
    };
  };
  setMonthLabel: Dispatch<
    SetStateAction<{
      animated: {
        type: "next" | "current" | "prev";
        hasArrows: boolean;
        show: boolean;
      };
      stationary: {
        type: "next" | "current" | "prev";
        hasArrows: boolean;
        show: boolean;
      };
    }>
  >;
  monthRef:
    | React.MutableRefObject<{
        stationary: HTMLDivElement | null;
        animated: HTMLDivElement | null;
      }>
    | undefined;
  scrollRef:
    | React.MutableRefObject<{
        divSideBar: HTMLDivElement | null;
        divTable: HTMLDivElement | null;
        isSyncingSideBarScroll: boolean;
        isSyncingTableScroll: boolean;
      }>
    | undefined;
  doctorSort: {
    name: "asc" | "desc" | null;
    efficiency: "asc" | "desc" | null;
    experience: "asc" | "desc" | null;
  };
  setDoctorSort: Dispatch<
    SetStateAction<{
      name: "asc" | "desc" | null;
      efficiency: "asc" | "desc" | null;
      experience: "asc" | "desc" | null;
    }>
  >;
  doctorFilter: {
    tags: string[];
    group: string | undefined;
    subGroups: string[];
  };
  setDoctorFilter: Dispatch<
    SetStateAction<{
      tags: string[];
      group: string | undefined;
      subGroups: string[];
    }>
  >;
  resetFilters: () => void;
  setTableParams: (args: SetTableParamsArgs) => void;
  onScrollHandlers: {
    sideBar: () => void;
    table: () => void;
  };
  menuOptions: { edit: boolean };
  setMenuOptions: Dispatch<SetStateAction<{ edit: boolean }>>;
  type: "increment" | "decrement";
  setType: Dispatch<SetStateAction<"increment" | "decrement">>;
  timeBlockFilters: Key[];
  setTimeBlockFilters: Dispatch<SetStateAction<Key[]>>;
  progressLoader: null | number;
  setProgressLoader: Dispatch<SetStateAction<null | number>>;
}>({
  month: 1,
  calenderBack: () => ({ month: 0, year: 0 }),
  year: 1,
  calenderNext: () => ({ month: 0, year: 0 }),
  tableState: "location",
  editShiftId: undefined,
  setEditShiftId: () => undefined,
  setTableState: () => undefined,
  isShiftTimeToggle: false,
  setIsShiftTimeToggle: () => undefined,
  isDayOffFilter: false,
  setIsDayOffFilter: () => undefined,
  isLocationFilter: false,
  setIsLocationFilter: () => undefined,
  activeDate: undefined,
  setActiveDate: () => undefined,
  searchQuery: "",
  setSearchQuery: () => undefined,
  scrollRef: undefined,
  doctorSort: { name: "asc", efficiency: "asc", experience: "asc" },
  doctorFilter: { tags: [], group: undefined, subGroups: [] },
  setDoctorFilter: () => undefined,
  monthLabel: {
    animated: { type: "current", hasArrows: true, show: false },
    stationary: { type: "current", hasArrows: true, show: true },
  },
  monthRef: undefined,
  setMonthLabel: () => undefined,
  setDoctorSort: () => undefined,
  resetFilters: () => undefined,
  setTableParams: () => undefined,
  onScrollHandlers: { sideBar: () => undefined, table: () => undefined },
  menuOptions: { edit: false },
  setMenuOptions: () => undefined,
  type: "decrement",
  setType: () => undefined,
  timeBlockFilters: [],
  setTimeBlockFilters: () => undefined,
  progressLoader: null,
  setProgressLoader: () => undefined,
});

export const TableViewContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { setActiveLocation, activeId: activeLocationId } = useLocation();

  const { setActiveId: setActiveSeniorityId, activeId: activeSeniorityId } =
    useSeniority();

  const [searchParams, setSearchParams] = useSearchParams();

  const { month: initMonth, year: initYear } = useCalendar();

  const { month, calenderBack, year, calenderNext, setMonth, setYear } =
    useMonthCalendar({ initialMonth: initMonth, initialYear: initYear });

  const [menuOptions, setMenuOptions] = useState({ edit: false });

  // move this to menu options
  const [isShiftTimeToggle, setIsShiftTimeToggle] = useState(false);

  const [monthLabel, setMonthLabel] = useState<{
    animated: {
      type: "next" | "current" | "prev";
      hasArrows: boolean;
      show: boolean;
    };
    stationary: {
      type: "next" | "current" | "prev";
      hasArrows: boolean;
      show: boolean;
    };
  }>({
    animated: { type: "current", hasArrows: true, show: false },
    stationary: { type: "current", hasArrows: true, show: true },
  });

  // Filter States // move this to menu options
  const [isLocationFilter, setIsLocationFilter] = useState(true);
  const [isDayOffFilter, setIsDayOffFilter] = useState(true);
  const [editShiftId, setEditShiftId] = useState<string | undefined>(undefined);

  const [tableState, setTableState] = useState<
    "location" | "requests" | "users"
  >("location");

  const [activeDate, setActiveDate] = useState<Date | undefined>();
  const [searchQuery, setSearchQuery] = useState("");

  const [doctorSort, setDoctorSort] = useState<{
    name: "asc" | "desc" | null;
    efficiency: "asc" | "desc" | null;
    experience: "asc" | "desc" | null;
  }>({ name: null, efficiency: null, experience: null });

  const [doctorFilter, setDoctorFilter] = useState<{
    tags: string[];
    group: string | undefined;
    subGroups: string[];
  }>({ tags: [], group: undefined, subGroups: [] });

  const resetFilters = () => {
    setIsLocationFilter(false);
    setActiveDate(undefined);
    setIsDayOffFilter(false);
  };

  const monthRef = useRef<{
    stationary: HTMLDivElement | null;
    animated: HTMLDivElement | null;
  }>({
    stationary: null,
    animated: null,
  });

  const scrollRef = useRef<{
    divSideBar: HTMLDivElement | null;
    divTable: HTMLDivElement | null;
    isSyncingSideBarScroll: boolean;
    isSyncingTableScroll: boolean;
  }>({
    divSideBar: null,
    divTable: null,
    isSyncingSideBarScroll: false,
    isSyncingTableScroll: false,
  });

  const sideBarHandler = () => {
    if (!scrollRef.current.isSyncingSideBarScroll) {
      scrollRef.current.isSyncingTableScroll = true;
      scrollRef.current.divTable!.scrollTop =
        scrollRef.current.divSideBar!.scrollTop;
    }
    scrollRef.current.isSyncingTableScroll = false;
  };

  const tableHandler = () => {
    if (!scrollRef.current.isSyncingTableScroll) {
      scrollRef.current.isSyncingSideBarScroll = true;
      scrollRef.current.divSideBar!.scrollTop =
        scrollRef.current.divTable!.scrollTop;
    }
    scrollRef.current.isSyncingSideBarScroll = false;
  };

  const { data: locationsData } = useGetLocationsQuery({
    all: true,
  });

  const [type, setType] = useState<"increment" | "decrement">("decrement");

  const [progressLoader, setProgressLoader] = useState<null | number>(null);

  const [timeBlockFilters, setTimeBlockFilters] = useState<Key[]>([
    "morning",
    "noon",
    "night",
    "standBy",
  ]);

  const setTableParams = ({
    locationId,
    activeDate_,
    seniority,
    tableState_,
    month_,
    year_,
    isShiftTimeToggle_,
    isLocationFilter_,
    isDayOffFilter_,
  }: SetTableParamsArgs) => {
    setSearchParams({
      ...(locationId || (activeLocationId && locationId === undefined)
        ? { locationId: locationId ?? activeLocationId }
        : {}),
      ...(seniority || (activeSeniorityId && seniority === undefined)
        ? { seniority: seniority?.toString() ?? activeSeniorityId!.toString() }
        : {}),
      ...(activeDate_ || (activeDate && activeDate_ === undefined)
        ? { activeDate: getHashFromDate(activeDate_ ?? activeDate!) }
        : {}),
      month: month_?.toString() ?? month.toString(),
      year: year_?.toString() ?? year.toString(),
      tableState: tableState_ ?? tableState,
      isShiftTimeToggle: (isShiftTimeToggle_ ?? isShiftTimeToggle).toString(),
      isLocationFilter: (isLocationFilter_ ?? isLocationFilter).toString(),
      isDayOffFilter: (isDayOffFilter_ ?? isDayOffFilter).toString(),
    });
  };

  useEffect(() => {
    if (locationsData?.locations.length) {
      if (
        searchParams.get("month") &&
        searchParams.get("year") &&
        searchParams.get("tableState") &&
        searchParams.get("isShiftTimeToggle") &&
        searchParams.get("isLocationFilter") &&
        searchParams.get("isDayOffFilter")
      ) {
        setActiveLocation(
          searchParams.get("locationId")
            ? locationsData.locations.find(
                (loc: { _id: string }) =>
                  loc._id === searchParams.get("locationId")!
              )
            : undefined
        );
        setActiveSeniorityId(
          searchParams.get("seniority")
            ? Number(searchParams.get("seniority")!)
            : undefined
        );
        setActiveDate(
          searchParams.get("activeDate")
            ? getDateFromHash(searchParams.get("activeDate")!)
            : undefined
        );
        setTableState(
          searchParams.get("tableState") as "location" | "requests" | "users"
        );
        setIsShiftTimeToggle(searchParams.get("isShiftTimeToggle") === "true");
        setIsDayOffFilter(searchParams.get("isDayOffFilter") === "true");
        setIsLocationFilter(searchParams.get("isLocationFilter") === "true");
        setMonth(Number(searchParams.get("month")));
        setYear(Number(searchParams.get("year")));
      } else {
        setActiveLocation(undefined);
        setTimeout(() => setTableParams({}), 0);
      }
    }
  }, [locationsData]);

  return (
    <TableViewContext.Provider
      value={{
        month,
        monthLabel,
        editShiftId,
        setEditShiftId,
        setMonthLabel,
        monthRef,
        calenderBack,
        year,
        calenderNext,
        tableState,
        setTableState,
        isShiftTimeToggle,
        setIsShiftTimeToggle,
        isDayOffFilter,
        setIsDayOffFilter,
        isLocationFilter,
        setIsLocationFilter,
        activeDate,
        setActiveDate,
        searchQuery,
        setSearchQuery,
        scrollRef,
        doctorSort,
        setDoctorSort,
        resetFilters,
        setTableParams,
        doctorFilter,
        setDoctorFilter,
        onScrollHandlers: { sideBar: sideBarHandler, table: tableHandler },
        menuOptions,
        setMenuOptions,
        type,
        setType,
        timeBlockFilters,
        setTimeBlockFilters,
        progressLoader,
        setProgressLoader,
      }}
    >
      {children}
    </TableViewContext.Provider>
  );
};
