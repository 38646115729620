import {
  useCreateShiftMutation,
  useDeleteShiftMutation,
  useUpdateShiftMutation,
  useDeleteShiftsMutation,
} from "@/api/shiftsApi";
import { useTableData } from "./data";
import useHandleOnScheduleChange from "@/hooks/estimation/handleOnScheduleAdd";
import { getDateRange, getTimeString } from "@/utils/date";
import {
  useAssignRandomnMutation,
  useAutoAssignmentBulkMutation,
} from "@/api/rosterApi";
import handleResponse from "@/utils/handleResponse";
import { toast } from "react-toastify";
import { Slot } from "@/interface/shift";
import { SHIFT_DOCTORS_REQUIRED_LIMIT, ShiftState } from "@/constants";
import { useTableViewContext } from "@/hooks/context/useTableViewContext";
import { formatDateAPI } from "@/utils/formatDate";

const useShiftOperations = ({
  slots,
  date,
  shiftId,
  shiftIds,
  createEditShiftData,
  onShiftCreate,
  onShiftUpdate,
  onShiftDelete,
  onShiftsDelete,
}: {
  slots?: Slot[];
  date: Date;
  shiftId?: string;
  shiftIds?: string[];
  createEditShiftData?: ShiftState;
  onShiftCreate?: () => void;
  onShiftUpdate?: () => void;
  onShiftDelete?: () => void;
  onShiftsDelete?: () => void;
}) => {
  const { month, year, type } = useTableViewContext();
  const { refetchGetShiftsByMonth } = useTableData({ month, year, type });

  const [updateShift, { isLoading: isUpdateLoading }] =
    useUpdateShiftMutation();

  const [deleteShift, { isLoading: isDeleteShiftLoading }] =
    useDeleteShiftMutation();

  const [deleteShifts, { isLoading: isDeleteShiftsLoading }] =
    useDeleteShiftsMutation();

  const [createShift, { isLoading: isLoadingCreateShift }] =
    useCreateShiftMutation();

  const onScheduleChange = useHandleOnScheduleChange({
    ...getDateRange(date),
  });

  const [assignRandomn, { isLoading: isAssignRandomLoading }] =
    useAssignRandomnMutation();

  const [assignRandomnBulk, { isLoading: isAssignRandomBulkLoading }] =
    useAutoAssignmentBulkMutation();

  const handleAddEmptySlot = async () => {
    if (shiftId && slots && slots.length < SHIFT_DOCTORS_REQUIRED_LIMIT) {
      const response = await updateShift({
        shiftId,
        totalDoctorsRequired: slots.length + 1,
      });
      handleResponse(response, "Shift update successfull", async () => {
        await refetchGetShiftsByMonth();
        await onScheduleChange();
      });
    } else {
      toast.warning("Doctors Required limit reached.");
    }
  };

  const handleRemoveEmptySlot = async () => {
    if (
      shiftId &&
      slots &&
      slots.length > slots.filter((slot) => slot.schedule).length
    ) {
      const response = await updateShift({
        shiftId,
        totalDoctorsRequired: slots.length - 1,
      });
      handleResponse(response, "Shift update successfull", async () => {
        await refetchGetShiftsByMonth();
        await onScheduleChange();
      });
    }
  };

  const handleDeleteShift = async () => {
    if (shiftId) {
      const response = await deleteShift({
        shiftId,
      });
      handleResponse(response, "Shift Deleted.", async () => {
        await refetchGetShiftsByMonth();
        onShiftDelete?.();
      });
    }
  };

  const handleDeleteShifts = async () => {
    if (shiftIds) {
      const response = await deleteShifts({
        shiftIds,
      });
      handleResponse(response, "Shifts Deleted.", async () => {
        await refetchGetShiftsByMonth();
        onShiftsDelete?.();
      });
    }
  };

  const handleUpdateShift = async () => {
    if (createEditShiftData && shiftId) {
      const response = await updateShift({
        shiftId,
        time: {
          from: getTimeString(createEditShiftData.time.from),
          to: getTimeString(createEditShiftData.time.to),
        },
        type: createEditShiftData.type,
      });
      handleResponse(response, "Shift Updated.", async () => {
        await refetchGetShiftsByMonth();
        onShiftUpdate?.();
      });
    }
  };

  const handleCreateShift = async ({
    activeSeniorityId,
    activeLocationId,
  }: {
    activeSeniorityId: number | undefined;
    activeLocationId: string | undefined;
  }) => {
    if (createEditShiftData && activeLocationId !== "" && activeSeniorityId) {
      const response = await createShift({
        time: {
          from: getTimeString(createEditShiftData.time.from),
          to: getTimeString(createEditShiftData.time.to),
        },
        type: createEditShiftData.type,
        totalDoctorsRequired: 1,
        locationId: activeLocationId,
        seniority: activeSeniorityId,
        date: formatDateAPI(date),
      });
      handleResponse(response, "Shift Created.", async () => {
        await refetchGetShiftsByMonth();
        onShiftCreate?.();
      });
    } else {
      toast.warning(
        "Shift cannot be created until seniority or location selected."
      );
    }
  };

  const handleAssignRandom = async () => {
    if (shiftId) {
      const response = await assignRandomn({
        shiftId,
      });
      handleResponse(response, "Schedules auto assigned.", async () => {
        await refetchGetShiftsByMonth();
        await onScheduleChange();
      });
    }
  };

  const handleAssignRandomBulk = async () => {
    if (shiftIds) {
      const response = await assignRandomnBulk({
        shiftIds,
      });
      handleResponse(response, "Schedules auto assigned.", async () => {
        await refetchGetShiftsByMonth();
        await onScheduleChange();
      });
    }
  };

  return {
    handleAddEmptySlot,
    handleRemoveEmptySlot,
    handleDeleteShift,
    handleUpdateShift,
    handleAssignRandom,
    handleCreateShift,
    handleAssignRandomBulk,
    handleDeleteShifts,
    isAssignRandomLoading,
    isAssignRandomBulkLoading,
    isDeleteShiftLoading,
    isDeleteShiftsLoading,
    isLoadingCreateShift,
    isUpdateLoading,
  };
};

export default useShiftOperations;
