import { TimeBlock } from "@/constants";
import { Time } from "@/utils/date";
import { useState } from "react";
import Pattern from "./Pattern";
import Delete from "../Icons/Delete";
import {
  PatternElement,
  ShiftTemplate,
  Rule as RuleType,
} from "@/interface/shiftSettings";
import { Popover } from "react-tiny-popover";
import useSettingsContext from "./hooks/useSettingsContext";
import RulesTutorialDropdown from "./RulesTutorialDropdown";
import { useUpdateFeatureDataMutation } from "@/api/userApi";
import handleResponse from "@/utils/handleResponse";

const STARTS_ON_OPTIONS = [
  { value: -1, label: "Any day of the week" },
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" },
  { value: 7, label: "Sunday" },
];

const Rule = ({
  settingsId,
  pattern,
  ruleNumber,
  ruleCount,
  startsOn,
  type,
  groupedShiftTemplates,
  locationLabel,
  seniorityLabel,
  setDeleteRule,
  removePatternFactory,
  isLoading,
  updateRule,
  enableTutorial,
}: {
  settingsId: string;
  pattern: PatternElement[];
  ruleNumber: number;
  ruleCount: number;
  startsOn?: number;
  type: "allowed" | "notAllowed";
  groupedShiftTemplates: Record<TimeBlock, ShiftTemplate[]>; // use context
  locationLabel: string;
  seniorityLabel: string;
  removePatternFactory: (
    settingsId: string,
    dayNumber: number
  ) => ({
    timeBlock,
    time,
  }: {
    timeBlock?: TimeBlock | "dayOff";
    time?: {
      from: Time;
      to: Time;
    };
  }) => Promise<void>;
  setDeleteRule?: (rule: RuleType & { ruleNumber: number }) => void;
  isLoading: boolean;
  updateRule?: (args: {
    startsOn?: number;
    type?: "allowed" | "notAllowed";
  }) => Promise<void>;
  enableTutorial: boolean;
}) => {
  const [startsOnState, setStartsOn] = useState(startsOn);
  const [typeState, setTypeState] = useState(type);

  const { activateTutorial, setTutorialRuleState, setEnableRuleTutorial } =
    useSettingsContext();

  const [updateUser] = useUpdateFeatureDataMutation();

  return (
    <div
      key={settingsId}
      className={`flex w-full gap-2 pb-6 ${
        ruleCount === ruleNumber ? "" : "border-b border-[#E5E5E5]"
      }`}
    >
      <div className="text-sm font-medium flex w-[65px] h-[26px] items-center">
        Rule #{ruleNumber}
      </div>
      <Pattern
        isLoading={isLoading}
        settingsId={settingsId}
        startsOn={startsOnState}
        pattern={pattern}
        menuPos={ruleCount === ruleNumber ? "top" : "bottom"}
        groupedShiftTemplates={groupedShiftTemplates}
        locationLabel={locationLabel}
        seniorityLabel={seniorityLabel}
        removePatternFactory={removePatternFactory}
        enableTutorial={enableTutorial}
      />
      <div className="flex-grow"></div>
      <Popover
        isOpen={activateTutorial(["ruleType"]) && enableTutorial}
        content={<RulesTutorialDropdown tutorialState={"ruleType"} />}
        containerStyle={{ zIndex: "100" }}
        positions={"bottom"}
        align={"end"}
        onClickOutside={async () => {
          if (activateTutorial(["ruleType"])) {
            setTutorialRuleState("ruleStartsOn");
            const resp = await updateUser({
              rulesTutorial: "ruleStartsOn",
            });
            handleResponse(resp);
          }
        }}
      >
        <div className={`text-sm font-medium mr-2 w-[170px]`}>
          <div className=" text-nowrap">This pattern should be</div>
          <select
            value={typeState}
            disabled={settingsId === "new-setting-rule"}
            onChange={async (e) => {
              setTypeState(e.target.value as "allowed" | "notAllowed");
              await updateRule?.({
                type: e.target.value as "allowed" | "notAllowed",
              });
            }}
            className={`text-sm ${
              typeState === "allowed" ? "text-green-600" : "text-orange-600"
            } outline-none border border-[#D4D4D4] rounded-md px-2 py-1 w-full`}
          >
            <option value="allowed" className="text-green-600">
              Followed
            </option>
            <option value="notAllowed" className="text-orange-600">
              Avoided
            </option>
          </select>
        </div>
      </Popover>
      <Popover
        isOpen={activateTutorial(["ruleStartsOn"]) && enableTutorial}
        content={<RulesTutorialDropdown tutorialState={"ruleStartsOn"} />}
        containerStyle={{ zIndex: "100" }}
        positions={"bottom"}
        align={"end"}
        onClickOutside={async () => {
          if (activateTutorial(["ruleStartsOn"])) {
            setEnableRuleTutorial(false);
            const resp = await updateUser({
              rulesTutorial: "completed",
            });
            handleResponse(resp, undefined, () => {
              setEnableRuleTutorial(false);
            });
          }
        }}
      >
        <div
          className={`text-sm font-medium w-[170px]  ${
            activateTutorial(["ruleStartsOn"]) ? "z-100" : ""
          }`}
        >
          <div>This rule should start</div>
          <select
            value={startsOnState?.toString() ?? "-1"}
            disabled={settingsId === "new-setting-rule"}
            onChange={async (e) => {
              setStartsOn(Number(e.target.value));
              await updateRule?.({
                startsOn: Number(e.target.value),
              });
            }}
            className="text-sm text-[#52525] outline-none border border-[#D4D4D4] rounded-md px-2 py-1"
          >
            {STARTS_ON_OPTIONS.map((opt) => (
              <option value={opt.value.toString()} className="text-[#52525]">
                {opt.label}
              </option>
            ))}
          </select>
        </div>
      </Popover>
      {settingsId !== "new-setting-rule" && setDeleteRule && (
        <div className="cursor-pointer opacity-0 hover:opacity-100 duration-150">
          <div
            onClick={() => {
              setDeleteRule({ settingsId, pattern, ruleNumber });
            }}
          >
            <Delete stroke={"#DC2626"} strokeWidth={"1"} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Rule;
