import { Shift, ShiftType } from "@/interface/shift";
import useGroupShifts from "./hooks/useGroupShifts";
import ShiftCardMultiple from "./ShiftCardMultiple";
import { DateTime } from "luxon";
import { GROUP_COLORS, Key, TIMING_LIST } from "@/constants";
import { useState } from "react";
import CarotVert from "../Icons/CarotVert";
import Plus from "../Icons/Plus";
import { useTableViewContext } from "@/hooks/context/useTableViewContext";
import ShiftCardTiming from "./ShiftCardTiming";

const ShiftsColumn = ({
  shifts,
  date,
  isFetching,
}: {
  shifts: Shift[];
  date: Date;
  isFetching: boolean;
}) => {
  const groupedShifts = useGroupShifts({ shifts, dayOff: { doctors: [] } });
  const { timeBlockFilters } = useTableViewContext();

  const [groupState, setGroupState] = useState<
    Record<Key, { list: "close" | "open"; isAdd: boolean }>
  >(
    Object.fromEntries(
      TIMING_LIST.map((tB) => {
        return [tB.key, { list: "open", isAdd: false }];
      })
    ) as Record<Key, { list: "close" | "open"; isAdd: boolean }>
  );

  return (
    <div className="flex flex-col gap-2 py-1">
      {TIMING_LIST.filter((tO) => {
        return (
          timeBlockFilters.length === 0 || timeBlockFilters.includes(tO.key)
        );
      }).map((tO) => {
        const shiftsByTime = groupedShifts.find(
          (gS) => gS.key === tO.key
        )?.byTime;
        return (
          <div
            className={`${GROUP_COLORS[tO.key].bgColor9} px-2 ${
              groupState[tO.key].list === "open" ? "pb-2" : ""
            } rounded-lg`}
          >
            <div
              className={`w-full flex justify-center items-center font-medium text-sm ${
                GROUP_COLORS[tO.key].textColor5
              } relative`}
            >
              {shiftsByTime && shiftsByTime.length > 0 && (
                <div
                  className="absolute left-3 cursor-pointer"
                  onClick={() => {
                    setGroupState({
                      ...groupState,
                      [tO.key]:
                        groupState[tO.key].list === "close"
                          ? { ...groupState[tO.key], list: "open" }
                          : {
                              ...groupState[tO.key],
                              list: "close",
                              isAdd: false,
                            },
                    });
                  }}
                >
                  <CarotVert
                    type={groupState[tO.key].list === "open" ? "up" : "down"}
                    classPathFill={GROUP_COLORS[tO.key].strokeColor}
                  />
                </div>
              )}
              <div className="my-1">{tO.label}</div>
              <div
                className="absolute right-3 cursor-pointer"
                onClick={() => {
                  setGroupState({
                    ...groupState,
                    [tO.key]: groupState[tO.key].isAdd
                      ? { ...groupState[tO.key], isAdd: false }
                      : { ...groupState[tO.key], isAdd: true, list: "open" },
                  });
                }}
              >
                <Plus stroke={GROUP_COLORS[tO.key].strokeColor} />
              </div>
            </div>
            {groupState[tO.key].list === "open" && (
              <div className="flex flex-col gap-2">
                {groupState[tO.key].isAdd && (
                  <ShiftCardTiming
                    isFetching={isFetching}
                    date={date}
                    default_={{
                      time: GROUP_COLORS[tO.key].defaultTimeObject!,
                      type: ["morning", "noon", "night"].includes(tO.key)
                        ? "normal"
                        : (tO.key as ShiftType),
                    }}
                    groupKey={tO.key}
                    closeAddShiftCard={() => {
                      setGroupState({
                        ...groupState,
                        [tO.key]: { ...groupState[tO.key], isAdd: false },
                      });
                    }}
                  />
                )}
                {shiftsByTime?.map((byTime) => {
                  if (byTime.shifts.length > 1) {
                    return (
                      <ShiftCardMultiple
                        groupKey={tO.key}
                        shifts={byTime.shifts}
                        isFetching={isFetching}
                        date={DateTime.fromISO(
                          byTime.shifts[0].date
                        ).toJSDate()}
                      />
                    );
                  } else {
                    return (
                      <ShiftCardTiming
                        groupKey={tO.key}
                        shift={byTime.shifts[0]}
                        isFetching={isFetching}
                        date={DateTime.fromISO(
                          byTime.shifts[0].date
                        ).toJSDate()}
                      />
                    );
                  }
                }) ?? []}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ShiftsColumn;
