const CAMEL_CASE_REGEX = /([a-z])([A-Z])/g;

const keyFormatter = (key: string) => {
  return key
    .replace(CAMEL_CASE_REGEX, "$1 $2")
    .replace(/^./, (match) => match.toUpperCase());
};

const FormatTable = ({ object }: { object: Record<string, any> }) => {
  return (
    <table className="w-full border border-black">
      <tbody>
        {Object.entries(object).map(([key, value]: [string, any]) => (
          <tr>
            <td className="border border-black p-1">{keyFormatter(key)}</td>
            <td className="border border-black p-1">
              {typeof value === "object" &&
              value !== null &&
              !Array.isArray(value) ? (
                <FormatTable object={value} />
              ) : Array.isArray(value) ? (
                value.length > 0 ? (
                  <FormatTable
                    object={Object.fromEntries(
                      value.map((v, index) => [index + 1, v])
                    )}
                  />
                ) : (
                  "No elements in Array"
                )
              ) : (
                value.toString()
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const MetaData = ({ metaData }: { metaData?: Record<string, any> }) => {
  return (
    <div className="bg-green-200 h-fit h-max-[400px] w-fit w-max-[400px] p-1 relative rounded-md overflow-y-auto">
      <div className="text-xs flex items-center justify-center">
        {metaData ? (
          <FormatTable object={metaData} />
        ) : (
          <div>
            "MetaData not available. (This was most likely a manual entry or
            older automation)."
          </div>
        )}
      </div>
    </div>
  );
};

export default MetaData;
