const getQueryString = (queryParams: Record<string, any>) => {
  const definedQueryParams = Object.entries(queryParams).filter(
    (qP) => qP[1] !== undefined
  );

  if (definedQueryParams.length === 0) {
    return "";
  }

  return `?${definedQueryParams
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&")}`;
};

export default getQueryString;
