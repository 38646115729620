import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CACHE_LIFE } from "../constants";

const API_URL = import.meta.env.VITE_API_URL;

export const organisationApi = createApi({
  reducerPath: "organisationApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/api` }),
  keepUnusedDataFor: CACHE_LIFE,
  endpoints: (builder) => ({
    getOrganisations: builder.query<any, any>({
      query: () => ({
        url: `/organisation`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    getAdminUser: builder.query<any, any>({
      query: ({ orgId }) => ({
        url: `/organisation/admin-users?orgId=${orgId}`,
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    createOrganisation: builder.mutation<
      any,
      {
        name: string;
        city: string;
        country: string;
      }
    >({
      query: ({ name, city, country }) => ({
        url: `/organisation/add`,
        method: "POST",
        body: { name, city, country },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    setOrganisationActiveInactive: builder.mutation<
      any,
      {
        orgId: string;
      }
    >({
      query: ({ orgId }) => ({
        url: `/organisation/toggle/active-in`,
        method: "PATCH",
        body: { orgId },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    setOrganisationAdminUserActiveInactive: builder.mutation<
      any,
      {
        userId: string;
        orgId: string;
      }
    >({
      query: ({ userId, orgId }) => ({
        url: `/organisation/toggle/admin-user/active-in`,
        method: "PATCH",
        body: { userId, orgId },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    createAdminUser: builder.mutation<any, any>({
      query: ({ email, name, orgId }) => ({
        url: `/organisation/admin-user`,
        method: "POST",
        body: { email, name, orgId },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    updateAdminUser: builder.mutation<any, any>({
      query: ({ email, name, userId, regenPassword }) => ({
        url: `/organisation/admin-user`,
        method: "PATCH",
        body: { email, name, userId, regenPassword },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    deleteAdminUser: builder.mutation<any, any>({
      query: ({ userId, orgId }) => ({
        url: `/organisation/admin-user`,
        method: "DELETE",
        body: { userId, orgId },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    updateOrganisation: builder.mutation<any, any>({
      query: ({ city, name, country, orgId }) => ({
        url: `/organisation/update`,
        method: "PATCH",
        body: { city, name, country, orgId },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    deleteOrganisation: builder.mutation<any, any>({
      query: ({ orgId }) => ({
        url: `/organisation/delete`,
        method: "DELETE",
        body: { orgId },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    forceUpdateSplitByCache: builder.mutation<any, any>({
      query: ({ month, year, orgId }) => ({
        url: `/cache/update/split-by/force`,
        method: "POST",
        body: { month, year, orgId },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
    forceUpdateMyShiftsCache: builder.mutation<any, any>({
      query: ({ month, year, orgId }) => ({
        url: `/cache/update/my-shifts/force`,
        method: "POST",
        body: { month, year, orgId },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
  }),
});

export const {
  useGetOrganisationsQuery,
  useGetAdminUserQuery,
  useCreateAdminUserMutation,
  useCreateOrganisationMutation,
  useUpdateAdminUserMutation,
  useUpdateOrganisationMutation,
  useSetOrganisationActiveInactiveMutation,
  useSetOrganisationAdminUserActiveInactiveMutation,
  useDeleteAdminUserMutation,
  useDeleteOrganisationMutation,
  useForceUpdateMyShiftsCacheMutation,
  useForceUpdateSplitByCacheMutation,
} = organisationApi;
