import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CACHE_LIFE } from "../constants";

const API_URL = import.meta.env.VITE_API_URL;

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_URL}/api` }),
  keepUnusedDataFor: CACHE_LIFE,
  endpoints: (builder) => ({
    updateFeatureData: builder.mutation({
      query: ({ rulesTutorial }) => ({
        url: `/user/update/featureData`,
        method: "POST",
        body: {
          rulesTutorial,
        },
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }),
    }),
  }),
});

export const { useUpdateFeatureDataMutation } = userApi;
