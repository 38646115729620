import { DateTime } from "luxon";
import { useEffect, useState } from "react";

import { GROUP_COLORS, SENIORITIES, TIME_ZONE, TimeBlock } from "@/constants";
import { useTableViewContext } from "@/hooks/context/useTableViewContext";
import useMonthCalendar from "@/hooks/useMonthCalendar";
import { getTimeBlock } from "@/utils/getTimeBlock";

import CarotTwo from "../Icons/CarotTwo";
import { Doctor } from "@/interface/doctor";
import DoctorMonthCalendar from "../Calender/DoctorMonthCalendar";

const PopoverComponent = ({
  doctor,
  pos,
  width = 280,
}: {
  doctor: Doctor;
  pos?: "top" | "bottom";
  width?: number;
}) => {
  // refactor this out
  const { month: initialMonth, year: initialYear } = useTableViewContext();
  // Get current month and year if the table view context is not set
  const currentDate = DateTime.now().setZone(TIME_ZONE);
  const defaultMonth = initialMonth === 1 ? currentDate.month : initialMonth;
  const defaultYear = initialYear === 1 ? currentDate.year : initialYear;

  const { month, year, calenderBack, calenderNext } = useMonthCalendar({
    initialMonth: defaultMonth,
    initialYear: defaultYear,
  });

  const [selectedDateData, setSelectedDateData] = useState<any | undefined>(
    undefined
  );

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const dateTime = selectedDateData?.schedules[activeIndex]?.shift
    ? DateTime.fromISO(
        selectedDateData?.schedules[activeIndex].shift.time.from
      ).setZone(TIME_ZONE)
    : undefined;

  const scheduleDuration = selectedDateData?.schedules[activeIndex]?.shift
    ? Math.round(
        DateTime.fromISO(selectedDateData?.schedules[activeIndex].shift.time.to)
          .setZone(TIME_ZONE)
          .diff(
            DateTime.fromISO(
              selectedDateData?.schedules[activeIndex].shift.time.from
            ).setZone(TIME_ZONE),
            "hours"
          ).hours
      )
    : undefined;

  const timeBlock: TimeBlock | undefined = selectedDateData?.schedules?.[
    activeIndex
  ]?.shift
    ? getTimeBlock(selectedDateData.schedules[activeIndex].shift.time.from)
    : undefined;

  const shiftSeniority = SENIORITIES.find(
    (s) => s.id === selectedDateData?.schedules?.[activeIndex]?.shift?.seniority
  );

  const [display, setDisplay] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setDisplay(true);
    }, 5);
  }, []);

  return (
    <div
      className={`bg-gray12 rounded-lg h-fit p-2 flex flex-col gap-2 ${
        pos && pos === "top"
          ? "-top-3"
          : pos && pos === "bottom"
          ? "-bottom-3"
          : ""
      }`}
      style={{ width }}
    >
      {display && (
        <DoctorMonthCalendar
          month={month}
          year={year}
          calenderBack={calenderBack}
          calenderNext={calenderNext}
          doctorId={doctor._id}
          setSelectedDateData={setSelectedDateData}
          selectedDateData={selectedDateData}
          initialDate={DateTime.fromObject(
            { month, year },
            { zone: TIME_ZONE }
          ).toJSDate()}
        />
      )}
      {selectedDateData && selectedDateData.schedules.length > 0 ? (
        <div className="bg-white rounded-lg ">
          <div
            className={`w-full flex py-2 ${
              selectedDateData.schedules.length === 1 ? "px-2" : ""
            } justify-between`}
          >
            {selectedDateData.schedules.length > 1 && (
              <div className="h-[36px] flex items-center justify-center px-0.5">
                <div
                  className="cursor-pointer h-[9px] w-[9px] rounded-full hover:bg-gray13 flex justify-center items-center group"
                  onClick={() => {
                    if (activeIndex === 0) {
                      setActiveIndex(selectedDateData.schedules.length - 1);
                    } else {
                      setActiveIndex(activeIndex - 1);
                    }
                  }}
                >
                  <CarotTwo
                    type="left"
                    pathFill={"#D9D9D9"}
                    classPathFill="group-hover:fill-black"
                    size={{ width: "3.5px", height: "6px" }}
                  />
                </div>
              </div>
            )}
            <div className="flex justify-between w-full gap-2">
              <div className="bg-green18 text-white text-sm font-bold p-1.5 rounded-md leading-none w-[75px] h-10 flex justify-center items-center relative">
                {
                  selectedDateData.schedules[activeIndex].shift.location
                    .shortLabel
                }
                {selectedDateData.schedules[activeIndex].shift.seniority !==
                  doctor.seniority.id && (
                  <div
                    className={`absolute w-1 h-full right-1 ${shiftSeniority?.bgColor}`}
                  ></div>
                )}
              </div>
              <div className="flex-grow">
                <div
                  className={`flex ${
                    GROUP_COLORS[timeBlock!].bgColor3
                  } text-white py-1 pl-2 pr-[2px] rounded-md gap-[2px] justify-between h-10`}
                >
                  <div className="flex justify-center flex-grow">
                    <div className="text-[22px] font-medium leading-none flex flex-col justify-center">
                      {dateTime!.toFormat("hh:mm")}
                    </div>
                    <div className="text-[9px] leading-none flex flex-col-reverse relative bottom-1.5">
                      {dateTime!.toFormat("a")}
                    </div>
                  </div>
                  <div className="bg-gray text-green4 flex rounded-lg py-[2px] px-1 text-xl font-medium leading-none justify-center items-center">
                    {scheduleDuration}{" "}
                    <div className="text-[9px] font-normal flex flex-col justify-center leading-none">
                      HRS
                    </div>
                  </div>
                </div>
                <div className="text-center text-green18 font-semibold text-xs">
                  {dateTime!.toFormat("EEEE, d MMMM")}
                </div>
              </div>
            </div>
            {selectedDateData.schedules.length > 1 && (
              <div className="h-[36px] flex items-center justify-center px-0.5">
                <div
                  className="cursor-pointer h-[9px] w-[9px] rounded-full hover:bg-gray13 flex justify-center items-center group"
                  onClick={() => {
                    if (activeIndex === selectedDateData.schedules.length - 1) {
                      setActiveIndex(0);
                    } else {
                      setActiveIndex(activeIndex + 1);
                    }
                  }}
                >
                  <CarotTwo
                    type="right"
                    pathFill={"#D9D9D9"}
                    classPathFill="group-hover:fill-black"
                    size={{ width: "3.5px", height: "6px" }}
                  />
                </div>
              </div>
            )}
          </div>
          {selectedDateData.schedules.length > 1 && (
            <div className="w-full justify-between flex px-2 py-2 gap-1.5">
              {Array(selectedDateData.schedules.length)
                .fill(0)
                .map((_, index) => {
                  return (
                    <div
                      className={`w-full h-1 rounded-sm ${
                        index === activeIndex
                          ? GROUP_COLORS[timeBlock!].bgColor3
                          : "bg-gray13"
                      }`}
                    ></div>
                  );
                })}
            </div>
          )}
        </div>
      ) : (
        <div className="text-green18 font-bold bg-white rounded-lg w-full flex p-2 justify-center">
          No Shifts Assigned.
        </div>
      )}
    </div>
  );
};

export default PopoverComponent;
