import { HiEye, HiEyeOff } from "react-icons/hi";

interface EyeProps extends React.HTMLAttributes<HTMLButtonElement> {
  state: "open" | "closed";
}

const EyeToggle = (props: EyeProps) => {
  const { state, ...nativeProps } = props;

  return (
    <button {...nativeProps} type="button">
      {state === "closed" ? (
        <HiEyeOff className="text-black" size={20} />
      ) : null}
      {state === "open" ? <HiEye className="text-black" size={20} /> : null}
    </button>
  );
};

export default EyeToggle;
