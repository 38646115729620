import { useMemo } from "react";
import { DateTime } from "luxon";

import { Shift } from "@/interface/shift";
import { Key, TIME_ZONE, TIMING_LIST, TimingList } from "@/constants";
import { Doctor } from "@/interface/doctor";
import { getJSDate } from "@/utils/date";

type GroupByTime = {
  time: { from: Date; to: Date };
  shifts: Shift[];
};

type GroupedShifts = {
  key: Key;
  byTime?: GroupByTime[];
  doctors?: Doctor[];
  label: string;
}[];

const useGroupShifts = ({
  shifts,
  dayOff,
}: {
  shifts: Shift[];
  dayOff: { doctors: Doctor[] };
}): GroupedShifts => {
  return useMemo(() => {
    const groupedShifts: GroupedShifts = [];

    const addToGroupedShifts = ({
      shift,
      index,
      timeElement,
    }: {
      shift: Shift;
      index: number;
      timeElement: TimingList;
    }) => {
      if (index !== -1) {
        const byTimeIndex = groupedShifts[index].byTime!.findIndex((bT) => {
          return (
            bT.time.from.getTime() === getJSDate(shift.time.from).getTime() &&
            bT.time.to.getTime() === getJSDate(shift.time.to).getTime()
          );
        });

        if (byTimeIndex !== -1) {
          groupedShifts[index].byTime?.[byTimeIndex].shifts.push(shift);
        } else {
          groupedShifts[index].byTime?.push({
            time: {
              from: getJSDate(shift.time.from),
              to: getJSDate(shift.time.to),
            },
            shifts: [shift],
          });
        }
      } else {
        groupedShifts.push({
          byTime: [
            {
              time: {
                from: getJSDate(shift.time.from),
                to: getJSDate(shift.time.to),
              },
              shifts: [shift],
            },
          ],
          label: timeElement.label,
          key: timeElement.key,
        });
      }
    };

    if (shifts.length > 0) {
      TIMING_LIST.forEach((timeElement) => {
        shifts.forEach((shift) => {
          const index = groupedShifts.findIndex(
            (gS) => gS.key === timeElement.key
          );

          if (shift.type === timeElement.type) {
            if (timeElement.type !== "normal") {
              addToGroupedShifts({ shift, index, timeElement });
            } else {
              const fromTime = DateTime.fromISO(shift.time.from).setZone(
                TIME_ZONE
              );
              if (
                timeElement.time?.some(
                  (t) =>
                    fromTime >=
                      DateTime.fromObject(
                        {
                          year: fromTime.year,
                          month: fromTime.month,
                          day: fromTime.day,
                          hour: t.from.hour,
                          minute: t.from.min,
                        },
                        { zone: TIME_ZONE }
                      ) &&
                    fromTime <=
                      DateTime.fromObject(
                        {
                          year: fromTime.year,
                          month: fromTime.month,
                          day: fromTime.day,
                          hour: t.to.hour,
                          minute: t.to.min,
                        },
                        { zone: TIME_ZONE }
                      )
                )
              ) {
                addToGroupedShifts({ shift, index, timeElement: timeElement });
              }
            }
          }
        });
      });
    }

    if (dayOff?.doctors.length > 0) {
      groupedShifts.push({
        doctors: dayOff.doctors,
        label: "Day Off",
        key: "dayOff",
      });
    }

    return groupedShifts;
  }, [shifts, dayOff?.doctors]);
};

export default useGroupShifts;
