import useMonthCalendar from "@/hooks/useMonthCalendar";
import MonthCalendar from "../Calender/MonthCalendar";
import { useRef, useState } from "react";
import { DateTime } from "luxon";
import { SENIORITIES, TIME_ZONE } from "@/constants";
import { currentMonthDates, weekFromCurrentDates } from "@/utils/date";
import { useFormik } from "formik";
import { z } from "zod";
import { useGetLocationsQuery } from "@/api/locationApi";
import ButtonTwo from "../Button/ButtonTwo";
import { useAutoAssignmentDateRangeMutation } from "@/api/rosterApi";
import handleResponse from "@/utils/handleResponse";
import { formatDateAPI } from "@/utils/formatDate";
import { useTableViewContext } from "@/hooks/context/useTableViewContext";
import useKey from "@/hooks/useKey";
import useRightClick from "@/hooks/useRightClick";

interface AutomateForm {
  seniorities: number[];
  locations: string[];
  dates: Date[];
}

const AutomateScheduleModal = ({
  initialYear,
  initialMonth,
  closeModal,
  refetchGetShiftsByMonth,
}: {
  initialMonth: number;
  initialYear: number;
  closeModal: () => void;
  refetchGetShiftsByMonth: () => void;
}) => {
  const { month, year, calenderBack, calenderNext } = useMonthCalendar({
    initialMonth: initialMonth,
    initialYear: initialYear,
  });

  const [assignDateRange, { isLoading }] = useAutoAssignmentDateRangeMutation();

  const {
    isKeyPressed: { Shift: isShiftPressed },
  } = useKey("document", ["Shift"]);

  const { setProgressLoader } = useTableViewContext();

  const [activeQuickAddKey, setActiveQuickAddKey] = useState<
    "today" | "this-week" | "this-month" | undefined
  >();

  const { data: locationsData } = useGetLocationsQuery({
    all: true,
  });

  const formik = useFormik<AutomateForm>({
    initialValues: {
      dates: [],
      seniorities: [],
      locations: [],
    },
    validate: (values) => {
      const schema = z.object({
        dates: z
          .array(z.date({ invalid_type_error: "Must of be of type date !" }))
          .nonempty("Must add a date !"),
        seniorities: z
          .array(
            z.coerce.number({ invalid_type_error: "Must of type number !" })
          )
          .nonempty("Must add a seniority !"),
        locations: z
          .array(z.string({ required_error: "Must be of type string !" }))
          .nonempty("Must add a location !"),
      });
      const result = schema.safeParse(values);
      if (!result.success) {
        const errors = Object.entries(
          result.error.flatten().fieldErrors
        ).reduce((acc, [key, value]) => {
          acc[key] = value[0];
          return acc;
        }, {} as Record<string, string>);
        return errors;
      }
      return {};
    },
    onSubmit: async (values) => {
      // Add the api call here
      const response = await assignDateRange({
        ...values,
        dates: values.dates.map((date) => formatDateAPI(date)),
      });

      handleResponse(
        response,
        "Date job queued, or shifts generated.",
        (data) => {
          if (data.status === "job-queue-sent") {
            setProgressLoader(0);
          } else {
            refetchGetShiftsByMonth();
          }
        }
      );

      closeModal();
    },
  });

  const calendarRef = useRef(null);

  useRightClick(calendarRef, () => {
    formik.setFieldValue("dates", []);
  });

  const quickAddButton: {
    label: string;
    key: "today" | "this-week" | "this-month";
    dates: Date[];
  }[] = [
    {
      label: "Today",
      key: "today",
      dates: [
        DateTime.fromObject(
          {
            year: DateTime.now().setZone(TIME_ZONE).year,
            month: DateTime.now().setZone(TIME_ZONE).month,
            day: DateTime.now().setZone(TIME_ZONE).day,
          },
          { zone: TIME_ZONE }
        ).toJSDate(),
      ],
    },
    {
      label: "This Week",
      key: "this-week",
      dates: weekFromCurrentDates(),
    },
    {
      label: "This Month",
      key: "this-month",
      dates: currentMonthDates(),
    },
  ];

  return (
    <div
      className={`flex flex-col gap-2 ${isLoading ? "animate-pulseFast" : ""}`}
    >
      <div className="font-bold text-2xl text-black mb-2">
        Automate Schedules
      </div>
      <div className="font-medium text-sm">Select date or range</div>
      <div className="flex gap-1">
        {quickAddButton.map((qAB) => (
          <button
            className={`${
              activeQuickAddKey === qAB.key ? "bg-secondary text-white" : ""
            } text-xs border p-2 rounded-full border-[#E4E4E7]`}
            onClick={() => {
              setActiveQuickAddKey(qAB.key);
              formik.setFieldValue("dates", qAB.dates);
            }}
          >
            {qAB.label}
          </button>
        ))}
      </div>
      <div className="h-[350px] w-[300px] mb-2" ref={calendarRef}>
        <MonthCalendar
          month={month}
          year={year}
          calenderBack={calenderBack}
          calenderNext={calenderNext}
          setSelectedDates={(dates: Date[]) => {
            formik.setFieldValue("dates", dates);
          }}
          selectedDates={formik.values.dates}
          isMultiSelect={isShiftPressed}
        />
      </div>
      {formik.errors.dates && formik.touched.dates && (
        <div className="text-red1 text-xs">
          {formik.errors.dates as string | string[]}
        </div>
      )}
      <div>
        <div className="flex justify-between items-center">
          <div className="text-zinc-950 text-sm font-medium">
            Select Seniority
          </div>
          <div
            className="text-secondary text-xs cursor-pointer font-medium"
            onClick={() => {
              formik.setFieldValue("seniorities", ["1", "2", "3"]);
            }}
          >
            Select All
          </div>
        </div>
        <div>
          {SENIORITIES.map((seniority) => (
            <div className="text-zinc-950 text-sm flex items-center">
              <input
                type="checkbox"
                className="accent-secondary rounded-lg"
                name={`seniorities`}
                value={seniority.id}
                checked={(
                  formik.values.seniorities as unknown as string[]
                ).includes(seniority.id.toString())}
                onChange={formik.handleChange}
              />
              <span className="ml-1">{seniority.label}</span>
            </div>
          ))}
        </div>
        {formik.errors.seniorities && formik.touched.seniorities && (
          <div className="text-red1 text-xs">{formik.errors.seniorities}</div>
        )}
      </div>
      <div className="mb-3">
        <div className="flex justify-between items-center">
          <div className="text-zinc-950 text-sm font-medium">
            Select work zones
          </div>
          <div
            className="text-secondary text-xs cursor-pointer font-medium"
            onClick={() => {
              formik.setFieldValue(
                "locations",
                locationsData.locations.map(
                  (loc: { shortLabel: string; _id: string }) => loc._id
                )
              );
            }}
          >
            Select All
          </div>
        </div>
        <div>
          {locationsData.locations.map(
            (loc: { shortLabel: string; _id: string }) => (
              <div className="text-zinc-950 text-sm flex items-center">
                <input
                  type="checkbox"
                  className="accent-secondary rounded-lg"
                  name={`locations`}
                  value={loc._id}
                  checked={formik.values.locations.includes(loc._id)}
                  onChange={formik.handleChange}
                />
                <span className="ml-1">{loc.shortLabel}</span>
              </div>
            )
          )}
        </div>
        {formik.errors.locations && formik.touched.locations && (
          <div className="text-red1 text-xs">{formik.errors.locations}</div>
        )}
      </div>

      <div className="flex justify-between">
        <ButtonTwo
          clickHandler={() => {
            closeModal();
          }}
          variant="outlined"
        >
          Cancel
        </ButtonTwo>
        <ButtonTwo
          clickHandler={() => {
            formik.handleSubmit();
          }}
          variant="fill"
          disabled={Boolean(
            formik.errors.locations ||
              formik.errors.seniorities ||
              formik.errors.dates
          )}
        >
          Create
        </ButtonTwo>
      </div>
    </div>
  );
};

export default AutomateScheduleModal;
