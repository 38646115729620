import { useEffect } from "react";
import { db } from "../lib/firebase";
import { ref, onValue, child, DatabaseReference } from "firebase/database";
import { useProfile } from "../store/profile.state";

const useReloadFirebase = ({
  childKey,
  callback,
}: {
  childKey: string;
  callback: (data: any, ref: DatabaseReference) => void;
}) => {
  const { userId } = useProfile();

  useEffect(() => {
    const adminReloadRef = child(ref(db, `admin_reload`), childKey);

    const unSubscribe = onValue(child(adminReloadRef, userId), (snapshot) => {
      const data = snapshot.val();
      if (data) {
        callback(data, adminReloadRef);
      }
    });

    return () => {
      unSubscribe();
    };
  }, []);
};

export default useReloadFirebase;
