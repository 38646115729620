const TOTAL_WIDTH = 205;
const TOTAL_WIDTH_CLASS = `w-[${TOTAL_WIDTH}px]`;

const ProgressLoader = ({
  progress,
  doubleClickHandler,
}: {
  progress: number;
  doubleClickHandler: () => void;
}) => {
  return (
    <div
      className={`${TOTAL_WIDTH_CLASS}`}
      onDoubleClick={() => {
        doubleClickHandler();
      }}
    >
      <div className="flex justify-between w-full">
        <div className="text-sm text-zinc-950">Creating your schedule</div>
        <div className="text-sm text-zinc-400">
          {Math.round(progress * 100)} %
        </div>
      </div>
      <div className={`h-1 bg-[#B4C396] w-full`}>
        <div
          style={{ width: Math.round(TOTAL_WIDTH * progress) }}
          className="transition-[width] duration-300 h-full bg-[#4B5E2D]"
        ></div>
      </div>
    </div>
  );
};

export default ProgressLoader;
