import { convertTimeToString } from "@/utils/date";
import ButtonTwo from "@/components/Button/ButtonTwo";
import { useDeleteShiftSettingsMutation } from "@/api/shiftSettingsApi";
import handleResponse from "@/utils/handleResponse";

const DeleteTemplateModal = ({
  template,
  seniorityLabel,
  locationLabel,
  closeModal,
  refetchTemplates,
}: {
  template: any | undefined;
  seniorityLabel: string;
  locationLabel: string;
  closeModal: () => void;
  refetchTemplates: () => void;
}) => {
  const [deleteSetting, { isLoading }] = useDeleteShiftSettingsMutation();
  if (!template) {
    return <></>;
  }
  return (
    <div
      className={`bg-white w-[370px] ${isLoading ? "animate-pulseFast" : ""}`}
    >
      <div className="text-2xl font-semibold">
        Delete{" "}
        {`${convertTimeToString(template.time.from)} - ${convertTimeToString(
          template.time.to
        )} (${seniorityLabel}, ${locationLabel})`}
      </div>
      <div className="text-sm text-[#525252] mt-2">
        Finalised employee schedules with this shift will not be affected.
      </div>
      <div className="flex flex-row-reverse mt-2 gap-2">
        <ButtonTwo
          clickHandler={async () => {
            const resp = await deleteSetting({
              shiftSettingsId: template.settingsId,
            });
            handleResponse(resp, "Shift template deleted.", () => {
              refetchTemplates();
              closeModal();
            });
          }}
          variant="fill"
          fillClass="bg-[#DC2626]"
        >
          Delete
        </ButtonTwo>
        <ButtonTwo
          clickHandler={() => {
            closeModal();
          }}
          variant="outlined"
        >
          Cancel
        </ButtonTwo>
      </div>
    </div>
  );
};

export default DeleteTemplateModal;
