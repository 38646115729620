import { FC } from "react";

interface ClockFourProps {
  colourCode?: string;
  backgroundColour?: string;
}

const ClockFour: FC<ClockFourProps> = ({
  colourCode = "#D39E6A", // Default clock color (brown-orange)
  backgroundColour = "#FCEED4", // Default background color
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 24 24"
      version="1.1"
    >
      {/* Outer background circle */}
      <circle cx="12" cy="12" r="11" fill={backgroundColour} />
      
      {/* Clock outer ring */}
      <circle
        cx="12"
        cy="12"
        r="9"
        fill="none"
        stroke={colourCode}
        strokeWidth="2"
      />
      
      {/* Clock hands */}
      <line
        x1="12"
        y1="12"
        x2="12"
        y2="7"
        stroke={colourCode}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="12"
        y1="12"
        x2="16"
        y2="12"
        stroke={colourCode}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ClockFour;
