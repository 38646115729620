import React from "react";
import Typography from "../Typography/index";
import Button from "../Button/index";

interface ConfirmationModalProps {
  isVisible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  email: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isVisible,
  onConfirm,
  onCancel,
  email,
}) => {
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
        <Typography tag="h3" className="text-lg font-bold mb-4 text-gray-800">
          User will be invited to your calendar
        </Typography>
        <Typography className="mb-6 text-sm text-gray-600" tag={"object"}>
          By clicking ‘Yes’, <strong>{email}</strong> will be invited to your
          calendar.
        </Typography>
        <div className="flex justify-end gap-4">
          <Button
            className="flex justify-center !bg-transparent !border-2 border-secondary text-center !text-secondary !rounded-xl h-10"
            onClick={onCancel}
          >
            Not now
          </Button>
          <Button
            className="flex justify-center text-center !rounded-xl"
            onClick={onConfirm}
          >
            Yes
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
