import { useDeleteShiftSettingsMutation } from "@/api/shiftSettingsApi";
import ButtonTwo from "@/components/Button/ButtonTwo";
import handleResponse from "@/utils/handleResponse";
import PatternElement from "../PatternElement";

const DeleteRuleModal = ({
  rule,
  seniorityLabel,
  locationLabel,
  closeModal,
  refetchRule,
}: {
  rule: any | undefined;
  seniorityLabel: string;
  locationLabel: string;
  closeModal: () => void;
  refetchRule: () => void;
}) => {
  const [deleteSetting, { isLoading }] = useDeleteShiftSettingsMutation();
  if (!rule) {
    return <></>;
  }
  return (
    <div className={`bg-white ${isLoading ? "animate-pulseFast" : ""}`}>
      <div className="text-2xl font-semibold">
        Delete this rule for {seniorityLabel}, {locationLabel} ?
      </div>
      <div className="text-sm text-[#525252] mt-2">
        Schedules created in the future for {seniorityLabel}, {locationLabel}{" "}
        will not include this rule.
      </div>
      <div className="flex justify-between flex-wrap mt-2 gap-x-2 gap-y-4">
        {Array(7)
          .fill(0)
          .map((_, index) => (
            <div className="flex flex-col gap-2 min-w-[50px]]">
              <div className="flex gap-2 text-sm font-medium text-[#525252] items-center h-[26px]">
                Day {index + 1}{" "}
              </div>
              <div className="flex gap-1">
                {rule.pattern[index]?.timeBlocks?.map((tB: any) => (
                  <PatternElement timeBlock={tB} />
                ))}
                {rule.pattern[index]?.templates?.map((t: any) => (
                  <PatternElement template={t} />
                ))}
              </div>
            </div>
          ))}
      </div>
      <div className="flex flex-row-reverse mt-2 gap-2">
        <ButtonTwo
          clickHandler={async () => {
            const resp = await deleteSetting({
              shiftSettingsId: rule.settingsId,
            });
            handleResponse(resp, "Shift rule deleted.", () => {
              refetchRule();
              closeModal();
            });
          }}
          variant="fill"
          fillClass="bg-[#DC2626]"
        >
          Delete
        </ButtonTwo>
        <ButtonTwo
          clickHandler={() => {
            closeModal();
          }}
          variant="outlined"
        >
          Cancel
        </ButtonTwo>
      </div>
    </div>
  );
};

export default DeleteRuleModal;
