import { useState } from "react";
import { Popover } from "react-tiny-popover";
import SmallDoctorCard from "../Cards/SmallDoctorCard";
import Calendar from "../Icons/Calendar";
import { Doctor } from "@/interface/doctor";
import PopoverComponent from "../Cards/PopoverComponent";


const DoctorCard = ({
  doctor,
  name,
  nickName,
  seniority,
  pos = "top",
}: {
  doctor: Doctor;
  name: string;
  nickName?: string;
  seniority: number;
  pos: "top" | "bottom";
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  return (
    <SmallDoctorCard
      name={name}
      nickName={nickName}
      seniority={seniority}
      textSize="large"
      rightElement={
        <Popover
          isOpen={isPopoverOpen}
          positions="right"
          align={pos === "top" ? "start" : "end"}
          padding={10}
          reposition={false}
          onClickOutside={() => setIsPopoverOpen(false)}
          content={() => {
            return <PopoverComponent doctor={doctor} pos={pos} />;
          }}
        >
          <div
            className="flex flex-row-reverse flex-grow cursor-pointer"
            onClick={() => {
              setIsPopoverOpen(!isPopoverOpen);
            }}
          >
            <Calendar isActive={isPopoverOpen} />
          </div>
        </Popover>
      }
    />
  );
};
export default DoctorCard;
