import { useFormik } from "formik";
import { z } from "zod";
import ButtonTwo from "../Button/ButtonTwo";
import {
  useCreateOrUpdateShiftSettingsHoursMutation,
  useGetShiftSettingsHoursQuery,
} from "@/api/shiftSettingsApi";
import { useRunOnChange } from "@/hooks/runOnChange";
import handleResponse from "@/utils/handleResponse";
import deepEqual from "deep-equal";
import { useSeniority } from "@/store/seniority.state";

export interface ShiftHours {
  hoursPerTimePeriod: number;
  minRestPeriod: number;
  avgHoursPerWeek?: number;
  maxDaysAssignedPerWeek: number;
}

const FORM_LIST: {
  key: keyof ShiftHours;
  label: string;
  placeHolder: string;
}[] = [
  {
    key: "hoursPerTimePeriod",
    label: "Hours clocked every month",
    placeHolder: "Number of work hours",
  },
  {
    key: "minRestPeriod",
    label: "Min rest hours between shifts",
    placeHolder: "Number of rest hours",
  },
  {
    key: "avgHoursPerWeek",
    label: "Average hours per week (Optional)",
    placeHolder: "Average hours",
  },
  {
    key: "maxDaysAssignedPerWeek",
    label: "Max days worked per week",
    placeHolder: "Number of days",
  },
];

const cleanInputsFromFrom = (values: ShiftHours): ShiftHours => {
  return Object.fromEntries(
    Object.entries(values).map(([key, value]) => {
      return [key, value === "" ? undefined : value];
    })
  ) as ShiftHours;
};

const WorkingHoursItem = () => {
  const { activeId: activeSeniorityId } = useSeniority();

  const {
    data: shiftHours,
    isLoading: isShiftHoursLoading,
    isFetching,
    refetch,
  } = useGetShiftSettingsHoursQuery(
    { seniority: activeSeniorityId! },
    { skip: !activeSeniorityId }
  );

  const [createOrUpdateShiftSettingsHours, { isLoading }] =
    useCreateOrUpdateShiftSettingsHoursMutation();

  const formik = useFormik<ShiftHours>({
    initialValues: {
      hoursPerTimePeriod: 0,
      minRestPeriod: 0,
      maxDaysAssignedPerWeek: 0,
    },
    validate: (values) => {
      const schema = z.object({
        hoursPerTimePeriod: z.number({
          required_error: "This field is required.",
        }),
        minRestPeriod: z.number({ required_error: "This field is required." }),
        avgHoursPerWeek: z.number().optional(),
        maxDaysAssignedPerWeek: z.number({
          required_error: "This field is required.",
        }),
      });
      const result = schema.safeParse(cleanInputsFromFrom(values));
      if (!result.success) {
        const errors = Object.entries(
          result.error.flatten().fieldErrors
        ).reduce((acc, [key, value]) => {
          acc[key] = value[0];
          return acc;
        }, {} as Record<string, string>);
        return errors;
      }
      return {};
    },
    onSubmit: async (values) => {
      if (activeSeniorityId) {
        const resp = await createOrUpdateShiftSettingsHours({
          ...cleanInputsFromFrom(values),
          seniority: activeSeniorityId,
        });
        handleResponse(resp, "Shift Settings updated.", () => {
          refetch();
        });
      }
    },
  });

  useRunOnChange(shiftHours, () => {
    if (shiftHours) {
      formik.setValues({
        hoursPerTimePeriod: shiftHours.hoursPerTimePeriod,
        minRestPeriod: shiftHours.minRestPeriod,
        maxDaysAssignedPerWeek: shiftHours.maxDaysAssignedPerWeek,
        avgHoursPerWeek: shiftHours.avgHoursPerWeek,
      });
    }
  });

  const canSave = shiftHours
    ? !deepEqual(
        {
          hoursPerTimePeriod: shiftHours.hoursPerTimePeriod,
          minRestPeriod: shiftHours.minRestPeriod,
          maxDaysAssignedPerWeek: shiftHours.maxDaysAssignedPerWeek,
          avgHoursPerWeek: shiftHours.avgHoursPerWeek,
        },
        formik.values,
        { strict: true }
      )
    : false;

  return (
    <div
      className={`border border-[#E5E5E5] rounded-lg p-6 ${
        isShiftHoursLoading || isFetching || isLoading
          ? "animate-pulseFast"
          : ""
      }`}
    >
      <div className="w-full h-full grid grid-cols-2 gap-11">
        {FORM_LIST.map((fl) => (
          <>
            <div className="text-sm font-medium text-[#171717] col-span-1 row-span-1 flex items-center">
              {fl.label}
            </div>
            <div className="col-span-1 row-span-1 h-[40px] border border-[#D4D4D4] rounded-md px-3 py-2 relative">
              <input
                placeholder={fl.placeHolder}
                name={fl.key}
                type="number"
                className="h-full w-full outline-none"
                value={formik.values[fl.key] ?? ""}
                onChange={formik.handleChange}
              />
              {formik.touched[fl.key] && formik.errors[fl.key] && (
                <div className="text-red1 text-xs absolute -bottom-4">
                  {formik.errors[fl.key] as string | string[]}
                </div>
              )}
            </div>
          </>
        ))}
      </div>
      <div className="flex mt-4">
        <div className="flex-grow"></div>
        <div className="flex gap-2">
          {canSave && (
            <ButtonTwo
              clickHandler={() => {
                if (shiftHours) {
                  formik.setValues({
                    hoursPerTimePeriod: shiftHours.hoursPerTimePeriod,
                    minRestPeriod: shiftHours.minRestPeriod,
                    maxDaysAssignedPerWeek: shiftHours.maxDaysAssignedPerWeek,
                    avgHoursPerWeek: shiftHours.avgHoursPerWeek,
                  });
                }
              }}
              variant={"outlined"}
            >
              Cancel
            </ButtonTwo>
          )}
          <ButtonTwo
            clickHandler={() => {
              formik.submitForm();
            }}
            variant={"fill"}
            fillClass="bg-[#4B5E2D]"
            disabled={Boolean(!canSave)}
          >
            Save
          </ButtonTwo>
        </div>
      </div>
    </div>
  );
};

export default WorkingHoursItem;
