import { useEffect, useState } from "react";
import { IoChevronBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import { INavTab } from "../../components/NavTabs/index";
import ScheduleLabelItem from "../../components/PreferencesTabItems/ScheduleLabelItem";
import CustomPointsItem from "../../components/PreferencesTabItems/CustomPointsItem";

import { useSeniority } from "../../store/seniority.state";
import SimpleLayout from "../../layouts/Simple";
import { SENIORITIES } from "@/constants";

const preferencesTabs = [
  {
    id: 0,
    label: "Labels",
    children: <ScheduleLabelItem />,
  },
  {
    id: 1,
    icon: null,
    label: "Guaconomy",
    children: <CustomPointsItem />,
  },
];

const Preferences = () => {
  const { setDisabled } = useSeniority();
  const navigate = useNavigate();

  useEffect(() => {
    for (const seniority of SENIORITIES) {
      setDisabled(seniority.id, false);
    }
  }, []);

  const [activePreferenceTab, setActivePreferenceTab] = useState(
    preferencesTabs[0].id
  );

  const handlePreferenceActiveTab = (tabId: INavTab["id"]) => {
    setActivePreferenceTab(tabId);
  };

  return (
    <SimpleLayout>
      <div className="flex w-full">
        <div className="p-4 w-[15%] relative z-[5] bg-white">
          <div className="flex">
            <div
              className="flex items-center bg-gray2 border-[1px] border-black3 cursor-pointer rounded-lg h-[30px] w-[30px]"
              onClick={() => {
                navigate(`/dashboard`);
              }}
            >
              <IoChevronBackOutline size={15} className="text-black mx-auto" />
            </div>
            <div className="my-auto ml-3 font-medium text-base text-black2">
              Back To Home
            </div>
          </div>
          <div className="my-2 text-base font-bold">Preferences</div>
          {preferencesTabs.map((tab) => (
            <div
              key={tab.id}
              className={`${
                tab.id === activePreferenceTab
                  ? "bg-secondary"
                  : "hover:bg-primary border-b-2 border-b-primary"
              } p-2 rounded-md my-2 cursor-pointer `}
              onClick={() => {
                handlePreferenceActiveTab(tab.id);
              }}
            >
              <div
                className={`font-medium ${
                  tab.id === activePreferenceTab ? "text-white" : "text-black"
                } font-base`}
              >
                {tab.label}
              </div>
            </div>
          ))}
        </div>
        <div className="w-[85%] flex-grow overflow-auto relative z-[5] bg-white">
          {preferencesTabs[activePreferenceTab].children}
        </div>
      </div>
    </SimpleLayout>
  );
};

export default Preferences;
