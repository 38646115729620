import { FC, useEffect } from "react";
import { SiWorldhealthorganization } from "react-icons/si";
import SuperAdminAccordian from "../SuperAdminAccordian";
import OrganizationUserCard from "../OrganizationUserCard";
import { IoClose } from "react-icons/io5";
import { BsPlus } from "react-icons/bs";
import { useGetAdminUserQuery } from "../../api/organisationApi";
import CreateAdminUser from "../../components/Modals/CreateAdminUser";
import Password from "../../components/Modals/Password";
import { useModal } from "../../hooks/useModal";
import { Dna } from "react-loader-spinner";

interface organizationProps {
  onClose: () => void;
  organizationName: string;
  city: string;
  country: string;
  isActive: boolean;
  orgId: string;
  onCreateUserSumbit?: () => Promise<void>;
  onToggleActiveSubmit?: () => Promise<void>;
}
const OrganizationModal: FC<organizationProps> = (props) => {
  const {
    onClose,
    organizationName,
    city,
    country,
    isActive,
    orgId,
    onCreateUserSumbit,
    onToggleActiveSubmit,
  } = props;
  const { openModal } = useModal();
  const {
    isLoading: isGetAdminUsersLoading,
    data: getAdminUsersData,
    refetch,
  } = useGetAdminUserQuery({ orgId });

  return (
    <>
      <div className="outer-shadow bg-white z-50 w-screen max-w-5xl p-6 mx-auto rounded-xl">
        <div className="flex w-full gap-6">
          <div className=" w-[40%]">
            <SuperAdminAccordian
              className="w-full space-x-2  border-green3 overflow-hidden"
              headerClass="space-x-4  w-full border-b  overflow-hidden"
              title={
                <div className=" grid grid-cols-1  w-full py-3.5 relative ">
                  <div className="text-base-text-black font-medium  flex flex-col items-center justify-bet gap-2">
                    <div className="font-bold border-2 rounded-full p-1.5 border-green3">
                      <SiWorldhealthorganization className="h-16 w-16 text-green3" />
                    </div>
                    <div className="text-secondary font-semibold">
                      {organizationName}
                    </div>
                  </div>
                </div>
              }
              organizationName={organizationName}
              orgId={orgId}
              city={city}
              country={country}
              totalUsers={getAdminUsersData?.length ?? 0}
              isActive={isActive}
              toggleActiveSubmit={onToggleActiveSubmit}
            />
          </div>
          <div className=" w-[60%]">
            <div className="outer-shadow rounded-md w-full p-2.5 mb-4">
              <div className="flex justify-between items-center font-medium text-secondary">
                <div className="flex justify-between text-sm items-center gap-2 pl-3">
                  {organizationName} Admin / Users
                </div>
                <div
                  className=" text-xs flex items-center justify-between gap-2  text-secondary font-medium  rounded top-4 -right-1"
                  onClick={() => {
                    openModal(
                      <CreateAdminUser
                        orgId={orgId}
                        onSubmit={async (password: string) => {
                          openModal(<Password password={password} />);
                          refetch();
                          onCreateUserSumbit
                            ? await onCreateUserSumbit()
                            : undefined;
                        }}
                      />
                    );
                  }}
                >
                  <BsPlus className="h-6 w-6 border-2 border-secondary rounded-full text-secondary cursor-pointer" />
                  Add User
                </div>
                <span
                  className="rounded outer-shadow cursor-pointer"
                  onClick={onClose}
                >
                  <IoClose className="h-8 w-8 text-green3" />
                </span>
              </div>
            </div>
            <div className="">
              <div className=" space-y-4 max-h-80 p-3 overflow-auto">
                {isGetAdminUsersLoading ? (
                  <div className="flex items-center justify-center h-full">
                    <Dna height={100} width={100} />
                  </div>
                ) : (
                  (getAdminUsersData ?? []).map((user: any) => (
                    <OrganizationUserCard
                      key={user._id}
                      name={user.name}
                      email={user.email}
                      orgId={orgId}
                      isActive={
                        isActive &&
                        user.organisations.find(
                          (org: { organisationId: string }) =>
                            org.organisationId === orgId
                        )?.isActive
                      }
                      userId={user._id}
                      reloadUsers={() => refetch()}
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganizationModal;
