import {
  FOCUS_COLORS,
  GROUP_COLORS,
  Key,
  shiftsMap,
  ShiftState,
} from "@/constants";
import { useState } from "react";
import { CarotRight } from "../Icons/CarotRight";
import { CarotLeft } from "../Icons/CarotLeft";

const ShiftCardEdit = ({
  groupKey,
  shiftState,
  setShiftState,
}: {
  groupKey: Key;
  shiftState: ShiftState;
  setShiftState: (shiftState: ShiftState) => void;
}) => {
  const [tabType, setTabType] = useState<"time" | "type">("time");
  return (
    <div className="max-w-md mx-auto">
      {tabType === "time" && (
        <div className="flex h-[45px]">
          <div className="mr-0.5">
            <div className="flex items-center mb-1">
              <div className="text-[10px] w-6 mr-1">Start</div>
              <div
                className={`flex ${GROUP_COLORS[groupKey].bgColor9} p-0.5 rounded-md items-center`}
              >
                <input
                  className={`outline-none w-3 h-3 text-[9px] rounded-sm text-center ${GROUP_COLORS[groupKey].bgColor9} ${FOCUS_COLORS[groupKey]}`}
                  value={shiftState.time.from.hour.toString().padStart(2, "0")}
                  onChange={(event) => {
                    const value = event.target.value.replace(/^0+/, "");
                    if (
                      value.length <= 2 &&
                      !Number.isNaN(Number(value)) &&
                      Number(value) >= 0 &&
                      Number(value) <= 12
                    ) {
                      setShiftState({
                        ...shiftState,
                        time: {
                          ...shiftState.time,
                          from: {
                            ...shiftState.time.from,
                            hour: Number(value),
                          },
                        },
                      });
                    }
                  }}
                />
                <div className="text-[9px] mx-0.5">:</div>
                <input
                  className={`outline-none w-3 h-3 text-[9px] rounded-sm text-center ${GROUP_COLORS[groupKey].bgColor9} ${FOCUS_COLORS[groupKey]}`}
                  value={shiftState.time.from.minute
                    .toString()
                    .padStart(2, "0")}
                  onChange={(event) => {
                    const value = event.target.value.replace(/^0+/, "");
                    if (
                      value.length <= 2 &&
                      !Number.isNaN(Number(value)) &&
                      Number(value) >= 0 &&
                      Number(value) <= 60
                    ) {
                      setShiftState({
                        ...shiftState,
                        time: {
                          ...shiftState.time,
                          from: {
                            ...shiftState.time.from,
                            minute: Number(value),
                          },
                        },
                      });
                    }
                  }}
                />
                <div
                  className="text-[9px] mx-0.5 cursor-pointer uppercase text-nowrap"
                  onClick={() => {
                    setShiftState({
                      ...shiftState,
                      time: {
                        ...shiftState.time,
                        from: {
                          ...shiftState.time.from,
                          amOrPm:
                            shiftState.time.from.amOrPm === "am" ? "pm" : "am",
                        },
                      },
                    });
                  }}
                >
                  {shiftState.time.from.amOrPm}
                </div>
              </div>
            </div>
            <div className="flex items-center mb-1">
              <div className="text-[10px] w-6 mr-1 flex flex-row-reverse">
                End
              </div>
              <div
                className={`flex ${GROUP_COLORS[groupKey].bgColor9} p-0.5 rounded-md items-center`}
              >
                <input
                  className={`outline-none w-3 h-3 text-[9px] rounded-sm text-center ${GROUP_COLORS[groupKey].bgColor9} ${FOCUS_COLORS[groupKey]}`}
                  value={shiftState.time.to.hour.toString().padStart(2, "0")}
                  onChange={(event) => {
                    const value = event.target.value.replace(/^0+/, "");
                    if (
                      value.length <= 2 &&
                      !Number.isNaN(Number(value)) &&
                      Number(value) >= 0 &&
                      Number(value) <= 12
                    ) {
                      setShiftState({
                        ...shiftState,
                        time: {
                          ...shiftState.time,
                          to: {
                            ...shiftState.time.to,
                            hour: Number(value),
                          },
                        },
                      });
                    }
                  }}
                />
                <div className="text-[9px] mx-0.5">:</div>
                <input
                  className={`outline-none w-3 h-3 text-[9px] rounded-sm text-center ${GROUP_COLORS[groupKey].bgColor9} ${FOCUS_COLORS[groupKey]}`}
                  value={shiftState.time.to.minute.toString().padStart(2, "0")}
                  onChange={(event) => {
                    const value = event.target.value.replace(/^0+/, "");
                    if (
                      value.length <= 2 &&
                      !Number.isNaN(Number(value)) &&
                      Number(value) >= 0 &&
                      Number(value) <= 60
                    ) {
                      setShiftState({
                        ...shiftState,
                        time: {
                          ...shiftState.time,
                          to: {
                            ...shiftState.time.to,
                            minute: Number(value),
                          },
                        },
                      });
                    }
                  }}
                />
                <div
                  className="text-[9px] mx-0.5 cursor-pointer uppercase"
                  onClick={() => {
                    setShiftState({
                      ...shiftState,
                      time: {
                        ...shiftState.time,
                        to: {
                          ...shiftState.time.to,
                          amOrPm:
                            shiftState.time.to.amOrPm === "am" ? "pm" : "am",
                        },
                      },
                    });
                  }}
                >
                  {shiftState.time.to.amOrPm}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div
              className={`text-[8px] ${GROUP_COLORS[groupKey].bgColor10} text-white py-0.5 text-center text-nowrap rounded-md mb-1 w-[35px]`}
            >
              {shiftsMap[shiftState.type].label}
            </div>
            <div
              className="flex flex-grow justify-center items-center cursor-pointer"
              onClick={() => {
                setTabType("type");
              }}
            >
              <CarotRight
                pathFill="black"
                size={{ width: "12px", height: "16px" }}
              />
            </div>
          </div>
        </div>
      )}
      {tabType === "type" && (
        <div className="h-[45px]">
          <div className="flex gap-1 justify-between mb-1.5">
            <div
              className={`text-[8px] ${
                shiftState.type === "normal"
                  ? `${GROUP_COLORS[groupKey].bgColor10} text-white`
                  : `${GROUP_COLORS[groupKey].textColor5} ${GROUP_COLORS[groupKey].bgColor9}`
              } py-0.5 text-center rounded-md w-[35px] cursor-pointer`}
              onClick={() => {
                if (shiftState.type !== "normal") {
                  setShiftState({ ...shiftState, type: "normal" });
                }
              }}
            >
              Shift
            </div>
            <div
              className={`text-[8px] ${
                shiftState.type === "onCall"
                  ? `${GROUP_COLORS[groupKey].bgColor10} text-white`
                  : `${GROUP_COLORS[groupKey].textColor5} ${GROUP_COLORS[groupKey].bgColor9}`
              } py-0.5 text-center rounded-md w-[35px] whitespace-nowrap cursor-pointer`}
              onClick={() => {
                if (shiftState.type !== "onCall") {
                  setShiftState({ ...shiftState, type: "onCall" });
                }
              }}
            >
              On Call
            </div>
            <div
              className={`text-[8px] ${
                shiftState.type === "event"
                  ? `${GROUP_COLORS[groupKey].bgColor10} text-white`
                  : `${GROUP_COLORS[groupKey].textColor5} ${GROUP_COLORS[groupKey].bgColor9}`
              }  py-0.5 text-center rounded-md w-[35px] cursor-pointer`}
              onClick={() => {
                if (shiftState.type !== "event") {
                  setShiftState({ ...shiftState, type: "event" });
                }
              }}
            >
              Event
            </div>
          </div>
          <div className="flex gap-1 justify-between mb-1.5">
            <div
              className="w-[35px] flex justify-center items-center cursor-pointer"
              onClick={() => {
                setTabType("time");
              }}
            >
              <CarotLeft
                pathFill="black"
                size={{ width: "12px", height: "16px" }}
              />
            </div>
            <div
              className={`text-[8px] ${
                shiftState.type === "standBy"
                  ? `${GROUP_COLORS[groupKey].bgColor10} text-white`
                  : `${GROUP_COLORS[groupKey].textColor5} ${GROUP_COLORS[groupKey].bgColor9}`
              }  py-0.5 text-center rounded-md w-[35px] cursor-pointer`}
              onClick={() => {
                if (shiftState.type !== "standBy") {
                  setShiftState({ ...shiftState, type: "standBy" });
                }
              }}
            >
              StandBy
            </div>
            <div className="w-[35px]"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShiftCardEdit;
