import Arrow from "@/components/Icons/Arrow";
import { SettingsContextProvider } from "@/components/SchedulingSettings/context/SettingsContext";
import useSettingsContext from "@/components/SchedulingSettings/hooks/useSettingsContext";
import Rules from "@/components/SchedulingSettings/Rules";
import SeniorityButtons from "@/components/SchedulingSettings/SenioritysButton";
import Templates from "@/components/SchedulingSettings/Templates";
import WorkingHoursItem from "@/components/SchedulingSettings/WorkingHoursItem";
import { useRunOnce } from "@/hooks/runOnce";
import SimpleLayout from "@/layouts/Simple";
import { useProfile } from "@/store/profile.state";
import { useLocation as useLocationStore } from "@/store/location.store";
import { useLocation, useNavigate } from "react-router-dom";

const SETTINGS_LIST = [
  {
    key: "working-hours",
    label: "Working hours",
    description: "Define working hours, rest hours and other variables",
    component: WorkingHoursItem,
  },
  {
    key: "shift-templates",
    label: "Shift templates",
    description: "Manage shift timings here",
    component: Templates,
  },
  {
    key: "shift-rules-patterns",
    label: "Shift rules & patterns",
    description: "Set rules for scheduling and your preferred shift patterns",
    component: Rules,
  },
];

const SchedulingSettingsInner = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathArray = location.pathname.split("/");
  const selectedSettingKey =
    pathArray.length <= 3 ? undefined : pathArray[pathArray.length - 1];

  const selectedSetting = SETTINGS_LIST.find(
    (s) => s.key === selectedSettingKey
  );

  const { enableRuleTutorial, setEnableRuleTutorial, setTutorialRuleState } =
    useSettingsContext();
  const { featureData } = useProfile();

  const { activeLocation, setActiveLocation } = useLocationStore();

  // place in run once hook
  useRunOnce(() => {
    if (featureData.rulesTutorial !== "completed" && !enableRuleTutorial) {
      setEnableRuleTutorial(true);
      setTutorialRuleState(featureData.rulesTutorial);
    }
    if (activeLocation) {
      setActiveLocation(undefined);
    }
  });

  return (
    <SimpleLayout>
      {enableRuleTutorial &&
        selectedSetting?.key === "shift-rules-patterns" && (
          <div className="absolute w-full h-full z-50 bg-gray3 opacity-50"></div>
        )}
      <div className="p-4 h-full w-full bg-white flex flex-col">
        <div
          className="flex items-center gap-1 cursor-pointer"
          onClick={() => {
            navigate(
              selectedSettingKey ? "/dashboard/scheduling" : "/dashboard"
            );
          }}
        >
          <Arrow type="left" />
          {selectedSettingKey
            ? "Back to scheduling settings"
            : "Back to dashboard"}
        </div>
        <div className="flex-grow flex justify-center h-0">
          <div
            className={`${
              selectedSetting?.key === "shift-rules-patterns"
                ? "w-[95%] h-full flex flex-col"
                : "w-[70%] max-w-[1000px]"
            }`}
          >
            <div className="mb-6 mt-24">
              <div className="text-3xl font-semibold">
                {selectedSetting
                  ? selectedSetting.label
                  : "Scheduling settings"}
              </div>
              {selectedSetting && (
                <div className={`text-sm text-[#737373] mt-2`}>
                  {selectedSetting.description}
                </div>
              )}
            </div>
            {selectedSettingKey && <SeniorityButtons />}
            {selectedSetting ? (
              <div className="mt-4 flex-grow flex-col h-0">
                <selectedSetting.component />
              </div>
            ) : (
              <div className="flex flex-col gap-4">
                {SETTINGS_LIST.map((setting) => (
                  <div className="border border-[#E5E5E5] rounded-lg p-6">
                    <div className="text-xl font-semibold leading-7">
                      {setting.label}
                    </div>
                    <div className="text-sm font-normal text-[#737373]">
                      {setting.description}
                    </div>
                    <div className="float-right h-[40px] flex items-center">
                      {" "}
                      <div
                        className="text-sm font-medium flex gap-1 cursor-pointer items-center"
                        onClick={() => {
                          navigate(`/dashboard/scheduling/${setting.key}`);
                        }}
                      >
                        Edit <Arrow type="right" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </SimpleLayout>
  );
};

const SchedulingSettings = () => {
  return (
    <SettingsContextProvider>
      <SchedulingSettingsInner />
    </SettingsContextProvider>
  );
};

export default SchedulingSettings;
