import { FC } from "react";

interface SendIconProps {
  color?: string; // Customize stroke color
  bgColor?: string; // Customize background color
  showBackground?: boolean; // Toggle background visibility
  size?: number; // Customize size (default: 20)
}

export const Send: FC<SendIconProps> = ({
  color = "#5081B8",
  bgColor = "#D8E5F0",
  showBackground = true,
  size = 20,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Background Circle (Optional) */}
      {showBackground && <rect width="20" height="20" rx="10" fill={bgColor} />}

      {/* Send Icon Path */}
      <path
        d="M7.49489 12.5051L15 5M7.49489 12.5051L9.88289 17.2811C9.91282 17.3464 9.96088 17.4018 10.0214 17.4406C10.0818 17.4794 10.1522 17.5 10.224 17.5C10.2959 17.5 10.3662 17.4794 10.4267 17.4406C10.4872 17.4018 10.5352 17.3464 10.5652 17.2811L15 5M7.49489 12.5051L2.71892 10.1171C2.6536 10.0872 2.59825 10.0391 2.55944 9.97865C2.52063 9.91817 2.5 9.84783 2.5 9.77597C2.5 9.70412 2.52063 9.63377 2.55944 9.5733C2.59825 9.51283 2.6536 9.46477 2.71892 9.43483L15 5"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
