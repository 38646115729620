import Grid from "../Icons/Grid";
import { formatTime } from "@/utils/formatDate";
import { Shift } from "@/interface/shift";
import { getTimeString } from "@/utils/date";
import { GROUP_COLORS, Key, ShiftState } from "@/constants";

const ShiftCardTopBar = ({
  displayType,
  handleGridClick,
  handleOnBarClick,
  groupKey,
  shift,
  shiftState,
  isLoading,
  isActive,
  mode,
  isAssignRandomLoading,
}: {
  displayType: "time" | "location";
  handleGridClick?: () => void;
  handleOnBarClick: () => void;
  groupKey: Key;
  shift?: Shift;
  shiftState?: ShiftState;
  isActive: boolean;
  isLoading: boolean;
  mode: "edit" | "delete" | "none";
  isAssignRandomLoading: boolean;
}) => {
  return (
    <div className="relative mb-1">
      {isActive && mode === "none" && handleGridClick && shift ? (
        <>
          <button
            disabled={isLoading}
            className={`absolute cursor-pointer top-0 bottom-0 my-auto h-fit right-0 p-0.5`}
            onClick={handleGridClick}
          >
            <Grid
              className={isAssignRandomLoading ? "animate-spin" : ""}
              fill={"black"}
            />
          </button>
        </>
      ) : null}
      <button
        onClick={handleOnBarClick}
        disabled={isAssignRandomLoading}
        className={`header w-full ${
          shift ? "px-4" : "px-2"
        } py-1 flex justify-center items-center text-[10px] rounded-md transition-all ${
          isActive
            ? `${GROUP_COLORS[groupKey].bgColor10} ${
                displayType === "time"
                  ? "font-bold text-black"
                  : `font-semibold ${GROUP_COLORS[groupKey].textColor5}`
              }`
            : `${GROUP_COLORS[groupKey].bgColor9} ${
                displayType === "time"
                  ? "font-semibold text-black"
                  : `font-medium ${GROUP_COLORS[groupKey].textColor5}`
              }`
        }`}
      >
        {displayType === "time"
          ? shift
            ? `${formatTime(shift.time.from ?? "")} - ${formatTime(
                shift.time.to ?? ""
              )}`
            : shiftState
            ? `${getTimeString(shiftState.time.from)} - ${getTimeString(
                shiftState.time.to
              )}`
            : ""
          : shift?.location.shortLabel ?? ""}
      </button>
    </div>
  );
};

export default ShiftCardTopBar;
