import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";

import { GROUP_COLORS, Key, ShiftState, TimeObject } from "@/constants";
import { useTableViewContext } from "@/hooks/context/useTableViewContext";
import { getTimeObject } from "@/utils/formatDate";

import { Shift, ShiftType } from "@/interface/shift";
import ShiftCardTopBar from "./ShiftCardTopBar";
import ShiftCardEdit from "./ShiftCardEdit";
import ShiftCardBottomBar from "./ShiftCardBottomBar";
import useShiftOperations from "./hooks/shiftOperations";

type ShiftCardProps = {
  shift?: Shift;
  default_?: {
    time: {
      from: TimeObject;
      to: TimeObject;
    };
    type: ShiftType;
  };
  className?: string;
  isFetching: boolean;
  date: Date;
  groupKey: Key;
  closeAddShiftCard?: () => void;
};

const ShiftCard = ({
  shift,
  className,
  isFetching,
  date,
  groupKey,
  closeAddShiftCard,
  default_,
}: ShiftCardProps) => {
  const { editShiftId, setEditShiftId } = useTableViewContext();

  const [shiftState, setShiftState] = useState<ShiftState>({
    time: {
      from: shift ? getTimeObject(shift.time.from) : default_!.time.from,
      to: shift ? getTimeObject(shift.time.to) : default_!.time.to,
    },
    type: shift?.type ?? default_!.type,
  });

  const [mode, setMode] = useState<"edit" | "delete" | "none">("none");

  const {
    isUpdateLoading,
    isDeleteShiftLoading,
    isAssignRandomLoading,
    isLoadingCreateShift,
    handleAssignRandom,
  } = useShiftOperations({
    slots: shift?.slots,
    date,
    shiftId: shift?._id,
  });

  const isLoading =
    isUpdateLoading ||
    isDeleteShiftLoading ||
    isAssignRandomLoading ||
    isLoadingCreateShift ||
    isFetching;

  return (
    <div
      className={`col-span-1 relative transition-all ${className ?? ""} h-fit`}
    >
      <div
        className={`rounded-xl p-1.5 bg-white ${
          !shift || (shift && editShiftId === shift._id)
            ? `${GROUP_COLORS[groupKey].borderColor2} border`
            : ``
        } ${isLoading ? "animate-pulseFast" : ""} h-full`}
      >
        <ShiftCardTopBar
          mode={mode}
          displayType={"time"}
          handleOnBarClick={() => {
            if (editShiftId && shift && editShiftId === shift._id) {
              setEditShiftId(undefined);
              setMode("none");
            } else if (shift) {
              setEditShiftId(shift._id);
            }
          }}
          handleGridClick={async () => {
            await handleAssignRandom();
          }}
          isActive={!shift || (shift && editShiftId === shift._id)}
          shift={shift}
          shiftState={shiftState}
          groupKey={groupKey}
          isLoading={isLoading}
          isAssignRandomLoading={isAssignRandomLoading}
        />
        {shift && mode !== "edit" ? (
          <div className="max-w-md mx-auto">
            <AnimatePresence>
              {shift.slots.length === 0 ? (
                <motion.div
                  key="noDataTransition"
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.2 }}
                  className={`pb-0.5 pt-1.5 border-x-0 border-t-0 border-b-[#BDBDBD] transition-opacity`}
                >
                  <div className="font-medium text-sm p-1 text-black2 text-center">
                    No Data
                  </div>
                </motion.div>
              ) : null}
              {shift.slots.map((slot, index) => (
                <div
                  key={slot._id}
                  className={`h-7 ${
                    index != shift.slots.length - 1
                      ? `border-b-[1px] ${GROUP_COLORS[groupKey].borderColor3}`
                      : ""
                  }`}
                >
                  <div className="h-full w-full flex justify-center items-center text-xs font-semibold">
                    {slot.schedule
                      ? slot.schedule.doctor.user.nickName ??
                        slot.schedule.doctor.user.name
                      : "+"}
                  </div>
                </div>
              ))}
            </AnimatePresence>
          </div>
        ) : (
          <ShiftCardEdit
            groupKey={groupKey}
            shiftState={shiftState}
            setShiftState={setShiftState}
          />
        )}
        {(!shift || (shift && editShiftId === shift._id)) && (
          <ShiftCardBottomBar
            type={!shift ? "add" : mode}
            setType={setMode}
            groupKey={groupKey}
            shift={shift as Shift}
            date={date}
            closeAddShiftCard={closeAddShiftCard}
            shiftState={shiftState}
          />
        )}
      </div>
    </div>
  );
};

export default ShiftCard;
