const Arrow = ({ type }: { type: "left" | "right" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={`${type === "right" ? "rotate-180" : ""}`}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.66699 7.99967C2.66699 7.63148 2.96547 7.33301 3.33366 7.33301H12.667C13.0352 7.33301 13.3337 7.63148 13.3337 7.99967C13.3337 8.36786 13.0352 8.66634 12.667 8.66634H3.33366C2.96547 8.66634 2.66699 8.36786 2.66699 7.99967Z"
        fill="#171717"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.47173 2.86225C8.73208 3.1226 8.73208 3.54471 8.47173 3.80506L4.27647 8.00033L8.47173 12.1956C8.73208 12.4559 8.73208 12.878 8.47173 13.1384C8.21138 13.3987 7.78927 13.3987 7.52892 13.1384L2.86225 8.47173C2.6019 8.21138 2.6019 7.78927 2.86225 7.52892L7.52892 2.86225C7.78927 2.6019 8.21138 2.6019 8.47173 2.86225Z"
        fill="#171717"
      />
    </svg>
  );
};

export default Arrow;
