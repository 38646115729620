import {
  useCreateShiftSettingRuleMutation,
  useGetShiftSettingsRulesQuery,
  useUpdateShiftSettingRuleMutation,
} from "@/api/shiftSettingsApi";
import { useUpdateFeatureDataMutation } from "@/api/userApi";
import { TimeBlock } from "@/constants";
import { RuleTutorialState } from "@/interface/shiftSettings";
import { useLocation } from "@/store/location.store";
import { useSeniority } from "@/store/seniority.state";
import handleResponse from "@/utils/handleResponse";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useState,
} from "react";

export const SettingsContext = createContext<{
  enableRuleTutorial: boolean;
  setEnableRuleTutorial: Dispatch<SetStateAction<boolean>>;
  tutorialRuleState: RuleTutorialState;
  setTutorialRuleState: Dispatch<SetStateAction<RuleTutorialState>>;
  activateTutorial: (tutorialStates: RuleTutorialState[]) => boolean;
  isLoading: boolean;
  activeTemplateMenu: string | undefined;
  setActiveTemplateMenu: Dispatch<SetStateAction<string | undefined>>;
  createOrUpdateRuleFactory: (
    settingsId: string,
    dayNumber: number
  ) => ({
    templateId,
    timeBlock,
  }: {
    templateId?: string;
    timeBlock?: TimeBlock | "dayOff";
  }) => Promise<void>;
}>({
  enableRuleTutorial: false,
  setEnableRuleTutorial: () => undefined,
  tutorialRuleState: "clickAddRule",
  setTutorialRuleState: () => undefined,
  activateTutorial: () => false,
  isLoading: false,
  activeTemplateMenu: undefined,
  setActiveTemplateMenu: () => undefined,
  createOrUpdateRuleFactory: () => async () => undefined,
});

export const SettingsContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [enableRuleTutorial, setEnableRuleTutorial] = useState<boolean>(false);
  const [tutorialRuleState, setTutorialRuleState] =
    useState<RuleTutorialState>("clickAddRule");

  const { activeId: activeSeniorityId } = useSeniority();
  const { activeLocation } = useLocation();

  const [activeTemplateMenu, setActiveTemplateMenu] = useState<
    string | undefined
  >();

  const [updateUser] = useUpdateFeatureDataMutation();

  const [createRule, { isLoading: isCreateRuleLoading }] =
    useCreateShiftSettingRuleMutation();

  const [updateRule, { isLoading: isUpdateRuleLoading }] =
    useUpdateShiftSettingRuleMutation();

  const { refetch: refetchShiftRules } = useGetShiftSettingsRulesQuery(
    { locationId: activeLocation?._id, seniority: activeSeniorityId! },
    { skip: !activeSeniorityId }
  );

  const activateTutorial = (tutorialState: RuleTutorialState[]) => {
    return enableRuleTutorial && tutorialState.includes(tutorialRuleState);
  };

  const createOrUpdateRuleFactory =
    (settingsId: string, dayNumber: number) =>
    async ({
      templateId,
      timeBlock,
    }: {
      templateId?: string;
      timeBlock?: TimeBlock | "dayOff";
    }) => {
      if (settingsId === "new-setting-rule") {
        if (activeSeniorityId) {
          const response = await createRule({
            templateId,
            timeBlock,
            locationId: activeLocation?._id,
            seniority: activeSeniorityId,
          });
          handleResponse(response, "Rule created !.", async () => {
            if (activateTutorial(["createRule"])) {
              setTutorialRuleState("addAnotherOption");
              const resp = await updateUser({
                rulesTutorial: "addAnotherOption",
              });
              handleResponse(resp);
            }
            refetchShiftRules();
            setActiveTemplateMenu(undefined);
          });
        }
      } else {
        const response = await updateRule({
          shiftSettingsId: settingsId,
          patternAdd: { templateId, timeBlock, dayNumber },
        });
        handleResponse(response, "Rule updated !.", async () => {
          if (activateTutorial(["createNewPattern"])) {
            setTutorialRuleState("ruleCreated");
            const resp = await updateUser({
              rulesTutorial: "ruleCreated",
            });
            handleResponse(resp);
          }
          refetchShiftRules();
          setActiveTemplateMenu(undefined);
        });
      }
    };

  return (
    <SettingsContext.Provider
      value={{
        enableRuleTutorial,
        setEnableRuleTutorial,
        tutorialRuleState,
        setTutorialRuleState,
        activateTutorial,
        createOrUpdateRuleFactory,
        activeTemplateMenu,
        setActiveTemplateMenu,
        isLoading: isCreateRuleLoading || isUpdateRuleLoading,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
