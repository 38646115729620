import "react-tooltip/dist/react-tooltip.css";
import { useEffect, useState } from "react";
import { useDebounce } from "rooks";
import { InfinitySpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import { AnimatePresence } from "framer-motion";

import Button from "../Button/index";
import ConfirmationModal from "../Modals/Confirmation";
import UploadModal from "../Modals/UploadModal";
import CreateDoctor from "../Modals/CreateDoctor";
import SearchBox from "../Input/SearchBox";
import { useExportDoctorsMutation } from "../../api/rosterApi";
import UserMany from "../Icons/UserMany";
import AddUser from "../Icons/AddUser";
import Clock from "../Icons/Clock";
import DeleteRound2 from "../Icons/DeleteRound2";
import DoctorCard from "../Cards/DoctorCard";
import {
  useDeleteDoctorMutation,
  useGetDoctorsQuery,
  useResendInviteMutation,
} from "../../api/doctorApi";
import { useSeniority } from "../../store/seniority.state";
import { useModal } from "../../hooks/useModal";
import UserProfile from "./UserManagement/UserProfile";
import Pagination from "./UserManagement/Pagination";
import { useRunOnChange } from "../../hooks/runOnChange";
import useDoctorCardAnimate, { getRowCol } from "./hooks/doctorCardAnimate";
import { Send } from "../Icons/Send";
import { Popover } from "react-tiny-popover";
import PopoverComponent from "../Cards/PopoverComponent";
import { SerializedError } from "@reduxjs/toolkit";
import { Doctor } from "@/interface/doctor";
import CalendarRound2 from "../Icons/CalendarRound2";

const ROWS = 7;
const COLUMNS = 5;

const TABS = ["All Users", "Active", "Temporary", "Invited", "Inactive"];

const UserManagementTab = () => {
  const { activeId: activeSeniorityId } = useSeniority();

  const [page, setPage] = useState<number>(1);
  const [isIdle, setIsIdle] = useState<boolean | undefined>();
  const [isTemporary, setIsTemporary] = useState<boolean | undefined>();
  const [isInvited, setIsInvited] = useState<boolean | undefined>();
  const [searchQ, setSearchQ] = useState("");
  const [selectedUsersId, setSelectedUsersId] = useState<string[]>([]);
  const [selectedTab, setSelectedTab] = useState("All Users");

  const [resendInvite, { isLoading: isResendLoading }] =
    useResendInviteMutation();

  const [isPopOverId, setIsPopOverId] = useState<string | null>(null);

  const handleFilteration = (tab: string) => {
    setSelectedTab(tab);
    setPage(1); // Reset to first page

    const filters: Record<
      string,
      { isIdle?: boolean; isInvited?: boolean; isTemporary?: boolean }
    > = {
      Inactive: { isIdle: true },
      Active: { isIdle: false },
      "All Users": {},
      Temporary: { isTemporary: true },
      Invited: { isInvited: true },
    };

    const { isIdle, isInvited, isTemporary } = filters[tab] || {};

    setIsIdle(isIdle);
    setIsInvited(isInvited);
    setIsTemporary(isTemporary);
    setSelectedUsersId([]);
  };

  const handleResendInvite = async () => {
    if (selectedUsersId.length === 0) return;
    const userIds = selectedUsersId
      .map((id) => finalDoctors.find((doc) => doc._id === id)?.user?._id)
      .filter(Boolean);

    if (userIds.length === 0) {
      toast.error("No valid users found to resend invite.");
      return;
    }

    try {
      await resendInvite({ userIds }).unwrap();
      toast.success("Invites resent successfully!");
      refetchDoctors();
    } catch (error: any) {
      toast.error(error?.data?.message || "Failed to resend invites");
    }
  };

  const handleSearch = (q: string) => {
    setPage(1);
    setSearchQ(q);
  };

  useRunOnChange(activeSeniorityId, () => {
    setSelectedUsersId([]);
    setPage(1);
  });

  const searchDebounced = useDebounce(handleSearch, 5);

  const {
    data: doctors,
    isLoading,
    refetch: refetchDoctors,
    isFetching,
  } = useGetDoctorsQuery({
    seniority: activeSeniorityId,
    page,
    max: ROWS * COLUMNS,
    q: searchQ,
    isIdle,
    isTemporary,
    isInvited,
  });

  const { openModal } = useModal();

  const [
    deleteDoctor,
    {
      isLoading: isDeleteDoctorLoading,
      isError: isDeleteDoctorError,
      error: deleteDoctorError,
    },
  ] = useDeleteDoctorMutation();

  const [
    exportDoctors,
    {
      isLoading: isExportDoctorsLoading,
      isError: isExportDoctorsError,
      error: exportDoctorsError,
    },
  ] = useExportDoctorsMutation();

  useEffect(() => {
    if (isExportDoctorsError) {
      toast.error((exportDoctorsError as SerializedError).message);
    }
    if (isDeleteDoctorError) {
      toast.error((deleteDoctorError as SerializedError).message);
    }
  }, [
    isExportDoctorsError,
    exportDoctorsError,
    isDeleteDoctorError,
    deleteDoctorError,
  ]);

  const selectedDoctorIndex =
    selectedUsersId.length && doctors
      ? doctors?.doctors.findIndex(
          (doctor: { _id: string }) => doctor._id === selectedUsersId[0]
        )
      : -1;

  const selectedUser = doctors?.doctors.find(
    (doctor: { _id: string }) => doctor._id === selectedUsersId[0]
  );

  const { finalDoctors, profileColPos, profileRowPos, type } =
    useDoctorCardAnimate({
      selectedDoctorIndex,
      doctors: doctors?.doctors,
      enable: selectedUsersId.length === 1,
      rows: ROWS,
      columns: COLUMNS,
    });

  const shouldShowResendInvite = selectedUsersId.every((id) => {
    const doctor = doctors?.doctors?.find(
      (doc: { _id: string }) => doc._id === id
    );
    const org = doctor?.user?.organisations?.[0];
    return org?.isInvited && !org?.isAccepted;
  });

  return (
    <div className="flex flex-col pt-4 w-full h-full mx-auto relative z-[5] bg-white">
      <div className="flex items-center justify-between bg-white py-4 px-6">
        {/* User Tabs */}
        <div className="flex gap-3 items-center">
          <div className="flex gap-2 h-fit">
            {TABS.map((tab) => (
              <button
                key={tab}
                onClick={() => {
                  handleFilteration(tab);
                }}
                className={`px-3 py-2 rounded-lg text-sm font-semibold leading-none ${
                  selectedTab === tab ? "bg-green20" : "bg-gray-100"
                }`}
              >
                {tab}
              </button>
            ))}
          </div>
          <SearchBox
            value={searchQ}
            placeholder={"Search User..."}
            onChange={(e) => {
              searchDebounced(e.target.value);
            }}
          />
        </div>
        {/* Action Buttons */}
        <div className="flex gap-2">
          {selectedUsersId.length > 0 ? (
            <>
              {shouldShowResendInvite && (
                <Button
                  variant="bordered"
                  className="w-min px-3 !py-1 !rounded-full h-min whitespace-nowrap text-sm"
                  onClick={() => {
                    toast.info("Resend invite triggered for selected users.");
                    handleResendInvite();
                  }}
                  disabled={isResendLoading} // Disable when loading
                  icon={{
                    position: "left",
                    content: <Send color="#65823F" showBackground={false} />,
                  }}
                >
                  Re-send invite
                </Button>
              )}
              <Button
                variant="danger-bordered"
                className="w-min px-3 !py-1 !rounded-full h-min whitespace-nowrap text-sm"
                onClick={() => {
                  openModal(
                    <ConfirmationModal
                      message={`Are you sure you want to delete selected ${selectedUsersId.length} user(s)?`}
                      confirmTxt="Delete"
                      onConfirm={async (close) => {
                        const promises = selectedUsersId.map((userId) =>
                          deleteDoctor({ doctorId: userId })
                        );
                        await Promise.all(promises);
                        await refetchDoctors();
                        setSelectedUsersId([]);
                        toast.success("User(s) deleted successfully");
                        close();
                      }}
                    />
                  );
                }}
              >
                <DeleteRound2 color="#7A2C26" />
                Delete User
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="bordered"
                className="w-min px-3 !py-1 !rounded-full h-min whitespace-nowrap text-sm"
                onClick={() => exportDoctors()}
              >
                Export All
              </Button>
              <Button
                onClick={() =>
                  openModal(
                    <UploadModal
                      type="user"
                      onSubmit={() => refetchDoctors()}
                    />
                  )
                }
                variant="bordered"
                className="w-min px-3 !py-1 !rounded-full h-min whitespace-nowrap text-sm"
                icon={{ position: "left", content: <UserMany /> }}
              >
                Add Bulk User
              </Button>
              <Button
                onClick={() =>
                  openModal(
                    <UploadModal
                      type="shift"
                      onSubmit={() => refetchDoctors()}
                    />
                  )
                }
                variant="bordered"
                className="w-min px-3 !py-1 !rounded-full h-min whitespace-nowrap text-sm"
                icon={{ position: "left", content: <Clock /> }}
              >
                Add Bulk Shifts
              </Button>
              <Button
                variant="bordered"
                className="w-min px-3 !py-1 !rounded-full h-min whitespace-nowrap text-sm"
                onClick={() =>
                  openModal(
                    <CreateDoctor
                      initialSeniority={activeSeniorityId ?? 1}
                      onSubmit={() => refetchDoctors()}
                    />
                  )
                }
                icon={{ position: "left", content: <AddUser /> }}
              >
                Add User
              </Button>
            </>
          )}
        </div>
      </div>

      <div className="rounded-lg flex-grow p-5 bg-gray6 relative z-[1]">
        {isLoading ? (
          <div className="h-full flex justify-center items-center">
            <InfinitySpin width="200" color="#67823A" />
          </div>
        ) : finalDoctors.length > 0 ? (
          <div className="grid grid-cols-5 grid-rows-7 gap-5 h-full relative overflow-hidden">
            {finalDoctors.map((doctor: Doctor, index: number) => {
              const { row, col } = getRowCol(COLUMNS)(index);
              const isActive = doctor && Boolean(
                doctor && selectedUsersId.find((userId) => userId === doctor._id)
              );
              return (
                <>
                  {[1, 2, 3, 4].includes(row) &&
                  [0, 1, 2, 3].includes(col) &&
                  selectedUsersId.length === 1 &&
                  profileRowPos === row &&
                  profileColPos === col ? (
                    <AnimatePresence>
                      <UserProfile
                        key={selectedUser._id}
                        doctorId={selectedUser._id}
                        name={selectedUser.user.name}
                        nickName={selectedUser.user.nickName}
                        type={{ loc: type.y, page: "user-management" }}
                        onUpdate={() => {
                          if (isIdle !== undefined) {
                            setSelectedUsersId([]);
                          }
                          refetchDoctors();
                        }}
                        resendHandler={() => {
                          handleResendInvite();
                        }}
                        email={selectedUser.user.email}
                        phoneNumber={selectedUser.user.phoneNumber}
                        experience={selectedUser.experience}
                        efficiency={selectedUser.efficiencyScore}
                        seniority={selectedUser.seniority}
                        isIdle={selectedUser.isIdle}
                        isInvited={
                          selectedUser.user?.organisations[0]?.isInvited
                        }
                        isAccepted={
                          selectedUser.user?.organisations[0]?.isAccepted
                        }
                        groups={selectedUser.group ? [selectedUser.group] : []}
                        subGroups={
                          selectedUser.subGroup ? [selectedUser.subGroup] : []
                        }
                        tags={selectedUser.tags}
                      />
                    </AnimatePresence>
                  ) : doctor ? (
                    <div className="h-full min-h-14 col-span-1 row-span-1 relative">
                      {Boolean(
                        selectedUsersId.find((userId) => userId === doctor._id)
                      ) && (
                        <>
                          <div
                            className={`absolute h-[320%] w-[205%] bg-gray6 ${
                              type.y === "top" ? "bottom-0" : "top-0"
                            } ${
                              type.x === "right" ? "left-0" : "right-0"
                            } z-[3]`}
                          ></div>
                        </>
                      )}
                      <DoctorCard
                        key={doctor._id.toString()}
                        className="z-10 relative h-full"
                        type="user-management"
                        name={doctor.user.name}
                        doctor={doctor}
                        doctorId={doctor._id.toString()}
                        isActive={isActive}
                        resendHandler={() => {
                          handleResendInvite();
                        }}
                        isFetching={
                          isFetching ||
                          isDeleteDoctorLoading ||
                          isExportDoctorsLoading
                        }
                        onClickHandler={() => {
                          if (
                            !isFetching &&
                            !isDeleteDoctorLoading &&
                            !isExportDoctorsLoading
                          ) {
                            setIsPopOverId(null);
                            if (
                              selectedUsersId.find(
                                (userId) => userId === doctor._id
                              )
                            ) {
                              setSelectedUsersId([
                                ...selectedUsersId.filter(
                                  (userId) => userId !== doctor._id
                                ),
                              ]);
                            } else {
                              setSelectedUsersId([
                                ...selectedUsersId,
                                doctor._id.toString(),
                              ]);
                            }
                          }
                        }}
                        calendarButton={
                          <Popover
                            isOpen={isPopOverId === doctor._id}
                            positions={row > 2 ? ["top"] : ["bottom"]} // Ensure the popover opens below the button
                            align="end" // Center the popover horizontally below the button
                            padding={5}
                            containerClassName="z-[500]"
                            onClickOutside={() => setIsPopOverId(null)}
                            content={() => (
                              <PopoverComponent doctor={doctor} width={300} />
                            )}
                          >
                            <div>
                              <Button
                                variant="ghost"
                                className="p-1 h-min w-min !rounded-full"
                                title="Shift Details"
                                onClick={() => {
                                  if (
                                    !isFetching &&
                                    !isDeleteDoctorLoading &&
                                    !isExportDoctorsLoading
                                  ) {
                                    setIsPopOverId(
                                      isPopOverId === doctor._id
                                        ? null
                                        : doctor._id
                                    );
                                  }
                                }}
                              >
                                <CalendarRound2
                                  pathFill={isActive ? "white" : "grey"}
                                  size={{ height: "20px", width: "20px" }}
                                />
                              </Button>
                            </div>
                          </Popover>
                        }
                        seniority={
                          activeSeniorityId?.toString() as "1" | "2" | "3"
                        }
                      />
                    </div>
                  ) : (
                    <div
                      key={index}
                      className="h-full min-h-14 col-span-1 row-span-1 z-10 relative"
                    ></div>
                  )}
                </>
              );
            })}
          </div>
        ) : (
          <div className="h-full w-full flex justify-center items-center font-bold">
            No Doctors Found
          </div>
        )}
      </div>
      {doctors && (
        <Pagination
          isLoading={isLoading}
          page={page}
          totalPages={doctors.totalPages}
          setPage={setPage}
          onPageChange={() => setSelectedUsersId([])}
        />
      )}
    </div>
  );
};

export default UserManagementTab;
