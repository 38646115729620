const RoundedStar = ({ fill }: { fill: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10.0104 0.0117188C10.7882 0.011766 11.5393 0.29509 12.1234 0.808719L12.2774 0.953719L12.9754 1.65172C13.167 1.84203 13.4171 1.96217 13.6854 1.99272L13.8204 2.00072H14.8204C15.6376 2.00067 16.4239 2.31329 17.018 2.87446C17.6121 3.43563 17.9689 4.20282 18.0154 5.01872L18.0204 5.20072V6.20072C18.0204 6.47072 18.1124 6.73372 18.2784 6.94372L18.3684 7.04372L19.0654 7.74172C19.6431 8.31617 19.98 9.08934 20.0073 9.90359C20.0346 10.7178 19.7503 11.5118 19.2124 12.1237L19.0674 12.2777L18.3694 12.9757C18.1791 13.1673 18.059 13.4174 18.0284 13.6857L18.0204 13.8207V14.8207C18.0205 15.6379 17.7078 16.4242 17.1467 17.0183C16.5855 17.6124 15.8183 17.9692 15.0024 18.0157L14.8204 18.0207H13.8204C13.5508 18.0208 13.2891 18.1117 13.0774 18.2787L12.9774 18.3687L12.2794 19.0657C11.705 19.6434 10.9318 19.9803 10.1175 20.0076C9.30329 20.0349 8.50928 19.7506 7.89741 19.2127L7.74341 19.0677L7.04541 18.3697C6.85385 18.1794 6.6037 18.0593 6.33541 18.0287L6.20041 18.0207H5.20041C4.38319 18.0208 3.59693 17.7082 3.00284 17.147C2.40876 16.5858 2.05189 15.8186 2.00541 15.0027L2.00041 14.8207V13.8207C2.00032 13.5511 1.90944 13.2894 1.74241 13.0777L1.65241 12.9777L0.955412 12.2797C0.377693 11.7053 0.0408346 10.9321 0.0135195 10.1179C-0.0137956 9.3036 0.27049 8.50959 0.808412 7.89772L0.953412 7.74372L1.65141 7.04572C1.84172 6.85416 1.96187 6.60401 1.99241 6.33572L2.00041 6.20072V5.20072L2.00541 5.01872C2.05006 4.23416 2.38184 3.49347 2.9375 2.93781C3.49316 2.38215 4.23386 2.05037 5.01841 2.00572L5.20041 2.00072H6.20041C6.47003 2.00063 6.73176 1.90975 6.94341 1.74272L7.04341 1.65272L7.74141 0.955719C8.03875 0.656608 8.39228 0.419224 8.78168 0.257218C9.17108 0.0952122 9.58866 0.0117798 10.0104 0.0117188Z"
        fill={fill}
      />
    </svg>
  );
};

export default RoundedStar;
