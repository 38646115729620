import { Dispatch, SetStateAction } from "react";
import { Popover } from "react-tiny-popover";
import PatternElement from "./PatternElement";
import ShiftTemplateDropDown from "./ShiftTemplateDropdown";
import { TimeBlock, WEEK_DAYS_LABELS } from "@/constants";
import { Time } from "@/utils/date";
import Plus from "../Icons/Plus";
import {
  PatternElement as PatternElementType,
  ShiftTemplate,
} from "@/interface/shiftSettings";
import useSettingsContext from "./hooks/useSettingsContext";
import RulesTutorialDropdown from "./RulesTutorialDropdown";
import { useUpdateFeatureDataMutation } from "@/api/userApi";
import handleResponse from "@/utils/handleResponse";

const Pattern = ({
  settingsId,
  menuPos,
  startsOn,
  pattern,
  groupedShiftTemplates,
  locationLabel,
  seniorityLabel,
  isLoading,
  removePatternFactory,
  enableTutorial,
}: {
  settingsId: string;
  menuPos: "top" | "bottom";
  startsOn?: number;
  pattern: PatternElementType[];
  groupedShiftTemplates: Record<TimeBlock, ShiftTemplate[]>;
  locationLabel: string;
  seniorityLabel: string;
  isLoading: boolean;
  removePatternFactory: (
    settingsId: string,
    dayNumber: number
  ) => ({
    timeBlock,
    time,
  }: {
    timeBlock?: TimeBlock | "dayOff";
    time?: {
      from: Time;
      to: Time;
    };
  }) => Promise<void>;
  enableTutorial: boolean;
}) => {
  const {
    activateTutorial,
    setTutorialRuleState,
    activeTemplateMenu,
    setActiveTemplateMenu,
  } = useSettingsContext();

  const [updateUser] = useUpdateFeatureDataMutation();

  return (
    <div className="w-[70%] flex justify-between flex-wrap gap-x-2 gap-y-4">
      {Array(7)
        .fill(0)
        .map((_: unknown, index: number) => (
          <div className="flex flex-col gap-2 min-w-[100px]">
            <div className="flex gap-2 text-sm font-medium text-[#525252] items-center h-[26px] group">
              {startsOn
                ? WEEK_DAYS_LABELS[
                    startsOn - 1 + index > 6
                      ? startsOn - 1 + index - 7
                      : startsOn - 1 + index
                  ]
                : `Day ${index + 1}`}
              {pattern.length >= index && (
                <Popover
                  isOpen={
                    activeTemplateMenu === `${settingsId}-${index}` ||
                    (((activateTutorial(["clickAddPattern"]) && index === 0) ||
                      (activateTutorial(["addAnotherOption"]) &&
                        index === 1)) &&
                      enableTutorial)
                  }
                  onClickOutside={() => {
                    if (
                      !activateTutorial([
                        "clickAddPattern",
                        "addAnotherOption",
                        "createRule",
                      ])
                    ) {
                      setActiveTemplateMenu(undefined);
                    }
                  }}
                  content={
                    activateTutorial(["clickAddPattern"]) ? (
                      <RulesTutorialDropdown
                        tutorialState={"clickAddPattern"}
                      />
                    ) : activateTutorial(["addAnotherOption"]) ? (
                      <RulesTutorialDropdown
                        tutorialState={"addAnotherOption"}
                      />
                    ) : (
                      <ShiftTemplateDropDown
                        isLoading={isLoading}
                        groupedShiftTemplates={groupedShiftTemplates}
                        locationLabel={locationLabel}
                        seniorityLabel={seniorityLabel}
                        settingsId={settingsId}
                        dayNumber={index + 1}
                      />
                    )
                  }
                  positions={menuPos}
                  align="start"
                  reposition={false}
                  containerStyle={{ zIndex: "100" }}
                >
                  <div
                    className={`p-0.5 ${
                      activateTutorial(["clickAddPattern", "addAnotherOption"]) && enableTutorial
                        ? "z-[100]"
                        : ""
                    }`}
                  >
                    <div
                      className={`h-[24px] w-[24px] border-[#E5E5E5] border flex justify-center items-center leading-none rounded-md text-[16px] ${
                        activeTemplateMenu === `${settingsId}-${index}` ||
                        activateTutorial([
                          "clickAddPattern",
                          "addAnotherOption",
                        ])
                          ? "bg-[#F5F8ED]"
                          : "bg-white opacity-0 group-hover:opacity-100 duration-300"
                      }  cursor-pointer`}
                      onClick={() => {
                        setActiveTemplateMenu(`${settingsId}-${index}`);
                        if (activateTutorial(["clickAddPattern"])) {
                          setTutorialRuleState("chooseDropdownOption");
                        } else if (activateTutorial(["addAnotherOption"])) {
                          setTutorialRuleState("createNewPattern");
                        }
                      }}
                    >
                      <Plus size={{ height: "18px", width: "18px" }} />
                    </div>
                  </div>
                </Popover>
              )}
            </div>
            <div className="flex gap-1">
              {[
                ...(pattern[index]?.timeBlocks.map((tB) => ({
                  timeBlock: tB,
                })) ?? []),
                ...(pattern[index]?.templates.map((t) => ({ template: t })) ??
                  []),
              ].map(
                (payload: {
                  timeBlock?: TimeBlock | "dayOff";
                  template?: ShiftTemplate;
                }) => (
                  <Popover
                    isOpen={
                      activateTutorial(["ruleCreated"]) &&
                      index === 1 &&
                      enableTutorial
                    }
                    content={
                      activateTutorial(["ruleCreated"]) ? (
                        <RulesTutorialDropdown tutorialState={"ruleCreated"} />
                      ) : (
                        <></>
                      )
                    }
                    onClickOutside={async () => {
                      if (activateTutorial(["ruleCreated"])) {
                        setTutorialRuleState("ruleType");
                        const resp = await updateUser({
                          rulesTutorial: "ruleType",
                        });
                        handleResponse(resp);
                      }
                    }}
                    positions={"right"}
                    containerStyle={{ zIndex: "100" }}
                  >
                    <div className="pr-1">
                      <PatternElement
                        timeBlock={payload.timeBlock}
                        template={payload.template}
                        removePattern={removePatternFactory(
                          settingsId,
                          index + 1
                        )}
                      />
                    </div>
                  </Popover>
                )
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default Pattern;
