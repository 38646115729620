import {
  API_DATE_FORMAT,
  API_TIME_FORMAT,
  DISPLAY_DATE_FORMAT,
  DISPLAY_DATE_FORMAT_LONG,
  TIME_ZONE,
  TimeObject,
} from "../constants";
import { DateTime } from "luxon";

export const formatTime = (
  date: string,
  charCase: "lower" | "upper" | undefined = "upper"
) => {
  const dateTime = DateTime.fromISO(date).setZone(TIME_ZONE);

  let result = "";
  if (dateTime.minute === 0) {
    result = dateTime.toFormat("ha");
  } else {
    result = dateTime.toFormat("h:mma");
  }

  if (charCase === "lower") {
    return `${result.slice(0, result.length - 2)}${result
      .slice(result.length - 2)
      .toLowerCase()}`;
  }

  return result;
};

export const formatTimeWSpace = (date: string | DateTime) => {
  const dateTime =
    typeof date === "string" ? DateTime.fromISO(date).setZone(TIME_ZONE) : date;

  if (dateTime.minute === 0) {
    return dateTime.toFormat("h a");
  }

  return dateTime.toFormat("h:mm a");
};

export const formatDateLong = (date: string) => {
  return DateTime.fromISO(date)
    .setZone(TIME_ZONE)
    .toFormat(DISPLAY_DATE_FORMAT_LONG);
};

export const formatTimeAPI = (date: string) => {
  return DateTime.fromISO(date).setZone(TIME_ZONE).toFormat(API_TIME_FORMAT);
};

export const formatDateAPI = (date: Date) => {
  return DateTime.fromJSDate(date).setZone(TIME_ZONE).toFormat(API_DATE_FORMAT);
};

export const formatMonthYearKey = (date: Date) => {
  const dateTime = DateTime.fromJSDate(date).setZone(TIME_ZONE);
  return `${dateTime.month}-${dateTime.year}`;
};

export const formatDisplayDate = (date: Date | string) => {
  if (typeof date === "string") {
    return DateTime.fromISO(date)
      .setZone(TIME_ZONE)
      .toFormat(DISPLAY_DATE_FORMAT);
  }
  return DateTime.fromJSDate(date)
    .setZone(TIME_ZONE)
    .toFormat(DISPLAY_DATE_FORMAT);
};

export const addDay = (date: Date, days: number) => {
  return DateTime.fromJSDate(date).setZone(TIME_ZONE).plus({ days }).toJSDate();
};

export const getTimeObject = (date: string): TimeObject => {
  const dateTime = DateTime.fromISO(date).setZone(TIME_ZONE);
  return {
    hour: dateTime.hour > 12 ? dateTime.hour - 12 : dateTime.hour,
    minute: dateTime.minute,
    amOrPm: dateTime.toFormat("a").toLowerCase() as "am" | "pm",
  };
};
