import { SENIORITIES } from "@/constants";
import RoundedStar from "../Icons/RoundedStar";
import { useSeniority } from "@/store/seniority.state";

const SeniorityButtons = () => {
  const { activeId: activeSeniorityId, setActiveId: setActiveSeniorityId } =
    useSeniority();
  return (
    <div className={"flex gap-1 bg-[#F5F5F5] rounded-md p-1 w-fit"}>
      {SENIORITIES.map((seniority) => (
        <div
          className={`flex gap-1 items-center px-3 py-1.5 cursor-pointer rounded-sm ${
            activeSeniorityId === seniority.id ? "bg-white" : ""
          }`}
          onClick={() => {
            setActiveSeniorityId(seniority.id);
          }}
        >
          <div>
            <RoundedStar fill={seniority.colorCode} />
          </div>
          <div className={`font-medium ${seniority.textColor}`}>
            {seniority.label}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SeniorityButtons;
