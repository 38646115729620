const UserStatus = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="9" cy="9" r="9" fill="#FAE5C8" />
            <path d="M14.4476 3.25985C12.8907 1.17171 10.535 0 7.99889 0C7.6738 0 7.34677 0.0200347 7.02492 0.0588116C6.94671 0.0685059 6.89178 0.139597 6.90277 0.217151L7.02815 1.11419C7.03849 1.18916 7.10699 1.2428 7.18197 1.23375C7.45147 1.20144 7.72615 1.18528 7.99889 1.18528C9.81951 1.18528 11.5303 1.89425 12.8177 3.181C14.0909 4.45417 14.7986 6.14356 14.8134 7.94216C14.8283 9.74076 14.0915 11.5665 12.7763 12.8597C11.4941 14.1213 9.79947 14.8147 7.99889 14.8147C7.72615 14.8147 7.45212 14.7986 7.18197 14.7662C7.10699 14.7572 7.03849 14.8108 7.02815 14.8858L6.90277 15.7828C6.89178 15.8604 6.94736 15.9315 7.02492 15.9412C7.34677 15.98 7.6738 16 7.99889 16C10.1355 16 12.1449 15.1676 13.6559 13.6566C15.4488 11.8638 16.2864 9.36915 15.9109 6.79178C15.7247 5.51214 15.2206 4.29584 14.4476 3.2592V3.25985Z"
                fill="#DE973A" />
            <path d="M2.46419 3.79611L1.74357 3.24742C1.68088 3.19959 1.59169 3.21317 1.5458 3.2765C1.19098 3.75992 0.892393 4.28147 0.655202 4.83016C0.624179 4.90255 0.657141 4.98592 0.730172 5.01565L1.56777 5.36011C1.63822 5.3892 1.71836 5.35688 1.74874 5.28708C1.9478 4.82887 2.19791 4.39328 2.49327 3.9887C2.53787 3.92731 2.52494 3.84135 2.46484 3.79547L2.46419 3.79611Z"
                fill="#DE973A" />
            <path d="M1.18369 8.00091C1.18369 7.75145 1.19726 7.50133 1.2244 7.25445C1.23281 7.17884 1.17852 7.11098 1.10355 7.10129L0.205193 6.9856C0.126991 6.97526 0.0565426 7.03149 0.0481407 7.10969C0.0151795 7.40439 -0.000976562 7.70362 -0.000976562 8.00156C-0.000976562 8.29949 0.0158265 8.60195 0.0487876 8.8986C0.0571895 8.9768 0.128282 9.03302 0.205838 9.02268L1.10419 8.90635C1.17916 8.89666 1.23345 8.8288 1.22505 8.75318C1.19791 8.50501 1.18433 8.25231 1.18433 8.00156L1.18369 8.00091Z" fill="#DE973A" />
            <path d="M3.20732 14.4054C3.68688 14.7647 4.20585 15.0691 4.75198 15.3121C4.82371 15.3438 4.90773 15.3121 4.93876 15.2391L5.29163 14.4047C5.32136 14.3349 5.2897 14.2542 5.22054 14.2231C4.7649 14.0189 4.33124 13.7649 3.92989 13.465C3.86913 13.4198 3.78318 13.4314 3.73664 13.4915L3.18083 14.2063C3.13235 14.2684 3.14528 14.3576 3.20797 14.4047L3.20732 14.4054Z" fill="#DE973A" />
            <path d="M5.28382 1.59857L4.9303 0.764863C4.89992 0.692479 4.81526 0.660165 4.74352 0.691833C4.1974 0.934835 3.67971 1.23988 3.20016 1.59921C3.13747 1.64639 3.12454 1.73558 3.17301 1.79762L3.72948 2.51241C3.77601 2.57251 3.86197 2.58415 3.92272 2.53891C4.32407 2.23903 4.75709 1.9844 5.21273 1.78017C5.28188 1.74915 5.31355 1.66837 5.28382 1.59857Z" fill="#DE973A" />
            <path d="M0.655109 11.1747C0.892946 11.7234 1.19218 12.2443 1.547 12.7277C1.59353 12.7911 1.68272 12.8047 1.74477 12.7568L2.46539 12.2075C2.52549 12.1616 2.53842 12.0756 2.49383 12.0143C2.19847 11.6097 1.94835 11.1747 1.74865 10.7165C1.71827 10.6467 1.63748 10.6144 1.56768 10.6435L0.730079 10.9886C0.657694 11.0183 0.624086 11.1023 0.655109 11.1741V11.1747Z" fill="#DE973A" />
            <path d="M8.11732 7.48652C9.01324 7.48652 9.73953 6.76025 9.73953 5.86435C9.73953 4.96846 9.01324 4.24219 8.11732 4.24219C7.2214 4.24219 6.49512 4.96846 6.49512 5.86435C6.49512 6.76025 7.2214 7.48652 8.11732 7.48652Z" fill="#DE973A" />
            <path d="M4.79199 11.1552C4.79199 9.3178 6.28171 7.82812 8.11913 7.82812C9.95656 7.82812 11.4463 9.3178 11.4463 11.1552" fill="#DE973A" />
        </svg>


    );
};

export default UserStatus;
