const SortTwo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.52851 10.8623C1.78886 10.6019 2.21097 10.6019 2.47132 10.8623L4.66658 13.0575L6.86185 10.8623C7.1222 10.6019 7.54431 10.6019 7.80466 10.8623C8.06501 11.1226 8.06501 11.5447 7.80466 11.8051L5.13799 14.4717C4.87764 14.7321 4.45553 14.7321 4.19518 14.4717L1.52851 11.8051C1.26816 11.5447 1.26816 11.1226 1.52851 10.8623Z"
        fill="#737373"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.66667 5.33301C5.03486 5.33301 5.33333 5.63148 5.33333 5.99967V13.9997C5.33333 14.3679 5.03486 14.6663 4.66667 14.6663C4.29848 14.6663 4 14.3679 4 13.9997V5.99967C4 5.63148 4.29848 5.33301 4.66667 5.33301Z"
        fill="#737373"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8619 1.52827C11.1223 1.26792 11.5444 1.26792 11.8047 1.52827L14.4714 4.19494C14.7318 4.45529 14.7318 4.8774 14.4714 5.13775C14.2111 5.39809 13.7889 5.39809 13.5286 5.13775L11.3333 2.94248L9.13807 5.13775C8.87772 5.39809 8.45561 5.39809 8.19526 5.13775C7.93491 4.8774 7.93491 4.45529 8.19526 4.19494L10.8619 1.52827Z"
        fill="#737373"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3334 1.33301C11.7016 1.33301 12.0001 1.63148 12.0001 1.99967V9.99967C12.0001 10.3679 11.7016 10.6663 11.3334 10.6663C10.9652 10.6663 10.6667 10.3679 10.6667 9.99967V1.99967C10.6667 1.63148 10.9652 1.33301 11.3334 1.33301Z"
        fill="#737373"
      />
    </svg>
  );
};

export default SortTwo;
