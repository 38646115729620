import { useGetDayOffsByMonthQuery } from "@/api/dayOffApi";
import { useGetDoctorsQuery } from "@/api/doctorApi";
import { useGetCalendarQuery } from "@/api/locationApi";
import { useGetShiftsByMonthQuery } from "@/api/shiftsApi";
import { useTableViewContext } from "@/hooks/context/useTableViewContext";
import { Doctor } from "@/interface/doctor";
import { useSeniority } from "@/store/seniority.state";
import { decrementMonth, incrementMonth } from "@/utils/date";
import { useMemo } from "react";

export const useDoctors = () => {
  const { activeId: activeSeniorityId } = useSeniority();
  const { doctorSort, searchQuery, doctorFilter } = useTableViewContext();
  const { data, isLoading, isFetching, refetch } = useGetDoctorsQuery({});

  return {
    doctors: data?.doctors
      .filter((doctor: Doctor) => {
        return (
          (!activeSeniorityId || doctor.seniority.id === activeSeniorityId) &&
          (searchQuery === "" ||
            doctor.user.name
              .toLowerCase()
              .startsWith(searchQuery.toLowerCase())) &&
          (doctorFilter.tags.length === 0 ||
            doctorFilter.tags.find((tagId) =>
              doctor.tags.find((_tag) => tagId === _tag._id)
            )) &&
          (!doctorFilter.group || doctorFilter.group === doctor.group?._id) &&
          (doctorFilter.subGroups.length === 0 ||
            doctorFilter.subGroups.includes(doctor.subGroup?._id ?? ""))
        );
      })
      .sort((a: Doctor, b: Doctor) => {
        if (doctorSort.name) {
          return (
            (doctorSort.name === "asc" ? 1 : -1) *
            a.user.name.localeCompare(b.user.name)
          );
        }
        if (doctorSort.efficiency) {
          return doctorSort.efficiency === "asc"
            ? a.efficiencyScore - b.efficiencyScore
            : b.efficiencyScore - a.efficiencyScore;
        }
        if (doctorSort.experience) {
          return doctorSort.experience === "asc"
            ? a.experience - b.experience
            : b.experience - a.experience;
        }
        return 1;
      }),
    isLoading,
    isFetching,
    refetch,
  };
};

export const useTableData = ({
  month,
  year,
  activeLocationId,
  activeSeniorityId,
  type,
}: {
  month: number;
  year: number;
  activeLocationId?: string;
  activeSeniorityId?: number;
  type: "decrement" | "increment";
}) => {
  const {
    isLoading: isCalendarLoading,
    isFetching: isCalendarFetching,
    data: calendarData,
  } = useGetCalendarQuery(
    {
      locationId: activeLocationId?.toString(),
      seniority: Number(activeSeniorityId),
      month: month,
      year,
    },
    { skip: !activeLocationId || !activeSeniorityId }
  );

  const {
    data: shiftsByMonth,
    isLoading: isShiftsByMonthLoading,
    isFetching: isShiftsByMonthFetching,
    refetch: refetchGetShiftsByMonth,
  } = useGetShiftsByMonthQuery({ month, year });

  const {
    data: dayOffsByMonth,
    isLoading: isDayOffsLoading,
    isFetching: isDayOffsFetching,
  } = useGetDayOffsByMonthQuery({ month, year });

  const otherMonth =
    type === "increment"
      ? incrementMonth(month, year)
      : decrementMonth(month, year);

  const {
    isLoading: isCalendarLoadingOther,
    isFetching: isCalendarFetchingOther,
    data: calendarDataOther,
  } = useGetCalendarQuery(
    {
      locationId: activeLocationId?.toString(),
      seniority: Number(activeSeniorityId),
      month: otherMonth.month,
      year: otherMonth.year,
    },
    { skip: !activeLocationId || !activeSeniorityId }
  );

  const {
    data: shiftsByMonthOther,
    isLoading: isShiftsByMonthLoadingOther,
    isFetching: isShiftsByMonthFetchingOther,
  } = useGetShiftsByMonthQuery(otherMonth);

  const { schedulesByDoctor, schedulesByDoctorOther } = useMemo(() => {
    const schedulesByDoctor = shiftsByMonth
      ? Object.fromEntries(
          Object.entries(shiftsByMonth).map(([date, shifts]) => {
            const doctorObject: Record<
              string,
              { _id: string; shift: string; doctor: string }[]
            > = {};
            (shifts as any[]).forEach((shift) => {
              shift.slots
                .filter((slot: { schedule?: any }) => Boolean(slot.schedule))
                .forEach(
                  (slot: {
                    schedule: { doctor: { _id: any }; _id: any; shift: any };
                  }) => {
                    if (doctorObject[slot.schedule.doctor._id]) {
                      doctorObject[slot.schedule.doctor._id].push({
                        _id: slot.schedule._id,
                        shift: slot.schedule.shift,
                        doctor: slot.schedule.doctor._id,
                      });
                    } else {
                      doctorObject[slot.schedule.doctor._id] = [
                        {
                          _id: slot.schedule._id,
                          shift: slot.schedule.shift,
                          doctor: slot.schedule.doctor._id,
                        },
                      ];
                    }
                  }
                );
            });

            return [date, doctorObject];
          })
        )
      : undefined;
    const schedulesByDoctorOther = shiftsByMonthOther
      ? Object.fromEntries(
          Object.entries(shiftsByMonthOther).map(([date, shifts]) => {
            const doctorObject: Record<
              string,
              { _id: string; shift: string; doctor: string }[]
            > = {};
            (shifts as any[]).forEach((shift) => {
              shift.slots
                .filter((slot: { schedule?: any }) => Boolean(slot.schedule))
                .forEach(
                  (slot: {
                    schedule: { doctor: { _id: any }; _id: any; shift: any };
                  }) => {
                    if (doctorObject[slot.schedule.doctor._id]) {
                      doctorObject[slot.schedule.doctor._id].push({
                        _id: slot.schedule._id,
                        shift: slot.schedule.shift,
                        doctor: slot.schedule.doctor._id,
                      });
                    } else {
                      doctorObject[slot.schedule.doctor._id] = [
                        {
                          _id: slot.schedule._id,
                          shift: slot.schedule.shift,
                          doctor: slot.schedule.doctor._id,
                        },
                      ];
                    }
                  }
                );
            });

            return [date, doctorObject];
          })
        )
      : undefined;

    return { schedulesByDoctor, schedulesByDoctorOther };
  }, [shiftsByMonth, shiftsByMonthOther]);

  const {
    data: dayOffsByMonthOther,
    isLoading: isDayOffsLoadingOther,
    isFetching: isDayOffsFetchingOther,
  } = useGetDayOffsByMonthQuery(otherMonth);

  return {
    isTableDataLoading:
      isShiftsByMonthLoading ||
      isDayOffsLoading ||
      isShiftsByMonthLoadingOther ||
      isCalendarLoading ||
      isCalendarLoadingOther ||
      isDayOffsLoadingOther,
    isTableDataFetching:
      isShiftsByMonthFetching ||
      isDayOffsFetching ||
      isShiftsByMonthFetchingOther ||
      isDayOffsFetchingOther ||
      isCalendarFetching ||
      isCalendarFetchingOther,
    shiftsByMonth:
      shiftsByMonth && shiftsByMonthOther
        ? { ...shiftsByMonth, ...shiftsByMonthOther }
        : undefined,
    schedulesByDoctor:
      schedulesByDoctor && schedulesByDoctorOther
        ? { ...schedulesByDoctor, ...schedulesByDoctorOther }
        : undefined,
    dayOffsByMonth:
      dayOffsByMonth && dayOffsByMonthOther
        ? { ...dayOffsByMonth, ...dayOffsByMonthOther }
        : undefined,
    calendar:
      calendarData && calendarDataOther
        ? Object.fromEntries(
            [...calendarData.calendar, ...calendarDataOther.calendar].map(
              (item: any) => [item.date, item]
            )
          )
        : undefined,
    refetchGetShiftsByMonth,
  };
};
