import {
  addToTime,
  convertTimeToString,
  getDurationFromTime,
  Time,
} from "@/utils/date";
import { useState } from "react";
import ButtonTwo from "../../Button/ButtonTwo";
import TimeInput from "../TimeInput";
import { SHIFT_DOCTORS_REQUIRED_LIMIT } from "@/constants";
import {
  useCreateShiftSettingTemplateMutation,
  useUpdateShiftSettingTemplateMutation,
} from "@/api/shiftSettingsApi";
import handleResponse from "@/utils/handleResponse";
import deepEqual from "deep-equal";
import { useSeniority } from "@/store/seniority.state";
import { useLocation } from "@/store/location.store";
import { DAY_OF_WEEK, ShiftTemplateParent } from "@/interface/shiftSettings";

const ROWS = [
  { label: "Default", key: "parent" },
  { label: "Monday", key: "mon" },
  { label: "Tuesday", key: "tue" },
  { label: "Wednesday", key: "wed" },
  { label: "Thursday", key: "thu" },
  { label: "Friday", key: "fri" },
  { label: "Saturday", key: "sat" },
  { label: "Sunday", key: "sun" },
] as const;

type ROW_KEY = "mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun";

const ShiftVariationModal = ({
  locationLabel,
  seniorityLabel,
  initData,
  closeModal,
  closeEditRow,
  postSaveHandler,
}: {
  locationLabel: string;
  seniorityLabel: string;
  initData?: ShiftTemplateParent;
  closeModal: () => void;
  closeEditRow: () => void;
  postSaveHandler: () => void;
}) => {
  const { activeId: activeSeniorityId } = useSeniority();
  const { activeLocation } = useLocation();

  const [isDisabled, setIsDisabled] = useState<Record<ROW_KEY, boolean>>(
    (initData
      ? Object.fromEntries<boolean>(
          ROWS.slice(1).map((row) => {
            const foundVariation = initData.variations.find((variation) =>
              variation.daysOfWeek.includes(row.key as unknown as DAY_OF_WEEK)
            );
            return [row.key, !foundVariation];
          })
        )
      : {}) as unknown as Record<ROW_KEY, boolean>
  );

  const [createShiftTemplate, { isLoading: isCreateShiftTemplateLoading }] =
    useCreateShiftSettingTemplateMutation();

  const [updateShiftTemplate, { isLoading: isUpdateShiftTemplateLoading }] =
    useUpdateShiftSettingTemplateMutation();

  const [templates, setTemplates] = useState<
    Record<
      ROW_KEY,
      {
        time: { from: Time; to: Time };
        duration: number;
        slotsCount: number;
      }
    >
  >(
    (initData
      ? Object.fromEntries<{
          time: { from: Time; to: Time };
          duration: number;
          slotsCount: number;
        }>(
          ROWS.slice(1).map((row) => {
            const foundVariation = initData.variations.find((variation) =>
              variation.daysOfWeek.includes(row.key as unknown as DAY_OF_WEEK)
            );
            return [
              row.key,
              {
                time: foundVariation ? foundVariation.time : initData.time,
                slotsCount: foundVariation
                  ? foundVariation.slotsCount
                  : initData.slotsCount,
                duration: getDurationFromTime(
                  foundVariation ? foundVariation.time : initData.time
                ),
              },
            ];
          })
        )
      : {}) as unknown as Record<
      ROW_KEY,
      {
        time: { from: Time; to: Time };
        duration: number;
        slotsCount: number;
      }
    >
  );

  if (!initData) {
    return <></>;
  }

  return (
    <div
      className={`bg-white flex flex-col gap-3 ${
        isCreateShiftTemplateLoading || isUpdateShiftTemplateLoading
          ? "animate-pulseFast"
          : ""
      }`}
    >
      <div className="text-lg font-semibold">
        Add variations for {convertTimeToString(initData.time.from)} -{" "}
        {convertTimeToString(initData.time.to)} ({seniorityLabel} at{" "}
        {locationLabel})
      </div>
      <div className="text-sm text-[#737373]">
        Select and edit the days for which this shift has different timings,
        duration or slots.
      </div>
      <div className="my-2">
        <table className="border-separate border-spacing-y-4 border-spacing-x-4 w-full">
          <thead>
            <tr>
              <th></th>
              <th className="text-xs font-medium">Start Time</th>
              <th className="text-xs font-medium">End Time</th>
              <th className="text-xs font-medium">Duration</th>
              <th className="text-xs font-medium">Slots</th>
            </tr>
          </thead>
          <tbody>
            {ROWS.map((row) => (
              <tr key={row.key} className="">
                <td
                  className={`${
                    row.key === "parent"
                      ? "font-semibold"
                      : "text-sm font-medium"
                  } items-center flex gap-1 h-[40px] w-[120px]`}
                >
                  {row.key === "parent" ? (
                    "Default"
                  ) : (
                    <>
                      <input
                        type="checkbox"
                        className="accent-[#4B5E2D]"
                        checked={!isDisabled[row.key]}
                        onChange={() => {
                          setIsDisabled({
                            ...isDisabled,
                            [row.key]: !isDisabled[row.key],
                          });
                        }}
                      />
                      {row.label}
                    </>
                  )}
                </td>
                <td className="w-[80px] h-[40px]">
                  <div className="h-full w-full py-1 px-2 border border-[#D4D4D4] rounded-md">
                    <TimeInput
                      time={
                        row.key === "parent"
                          ? initData.time.from
                          : templates[row.key].time.from
                      }
                      setTime={(time_) => {
                        if (row.key !== "parent") {
                          setTemplates({
                            ...templates,
                            [row.key]: {
                              ...templates[row.key],
                              time: { ...templates[row.key].time, from: time_ },
                              duration: getDurationFromTime({
                                from: time_,
                                to: templates[row.key].time.to,
                              }),
                            },
                          });
                        }
                      }}
                      disabled={
                        row.key === "parent" ? true : isDisabled[row.key]
                      }
                      className={
                        "h-full w-full outline-none disabled:bg-white disabled:text-[#737373] text-sm"
                      }
                      errorClass={"bg-red-100"}
                    />
                  </div>
                </td>
                <td className="w-[80px] h-[40px]">
                  <div className="h-full w-full py-1 px-2 border border-[#D4D4D4] rounded-md">
                    <TimeInput
                      time={
                        row.key === "parent"
                          ? initData.time.to
                          : templates[row.key].time.to
                      }
                      setTime={(time_) => {
                        if (row.key !== "parent") {
                          setTemplates({
                            ...templates,
                            [row.key]: {
                              ...templates[row.key],
                              time: { ...templates[row.key].time, to: time_ },
                              duration: getDurationFromTime({
                                from: templates[row.key].time.from,
                                to: time_,
                              }),
                            },
                          });
                        }
                      }}
                      disabled={
                        row.key === "parent" ? true : isDisabled[row.key]
                      }
                      className={
                        "h-full w-full outline-none disabled:bg-white disabled:text-[#737373] text-sm"
                      }
                      errorClass={"bg-red-100"}
                    />
                  </div>
                </td>
                <td className="w-[80px] h-[40px]">
                  <div className="h-full w-full py-1 px-2 border border-[#D4D4D4] rounded-md">
                    <input
                      type="number"
                      disabled={
                        row.key === "parent" ? true : isDisabled[row.key]
                      }
                      value={
                        row.key === "parent"
                          ? getDurationFromTime(initData.time)
                          : templates[row.key].duration
                      }
                      onChange={(e) => {
                        if (row.key !== "parent") {
                          const duration_ =
                            Number(e.target.value) >= 24
                              ? 24
                              : Number(e.target.value) <= 1
                              ? 1
                              : Number(e.target.value);

                          setTemplates({
                            ...templates,
                            [row.key]: {
                              ...templates[row.key],
                              time: {
                                ...templates[row.key].time,
                                to: addToTime({
                                  time: templates[row.key].time.from,
                                  hours: duration_,
                                }),
                              },
                              duration: duration_,
                            },
                          });
                        }
                      }}
                      className="h-full w-full outline-none disabled:bg-white disabled:text-[#737373] text-sm"
                    />
                  </div>
                </td>
                <td className="w-[140px] h-[40px]">
                  <div className="flex gap-2">
                    <div
                      className="h-[40px] w-[40px] border border-[#D4D4D4] bg-white flex justify-center items-center rounded-md text-2xl cursor-pointer"
                      onClick={() => {
                        if (row.key !== "parent") {
                          setTemplates({
                            ...templates,
                            [row.key]: {
                              ...templates[row.key],
                              slotsCount:
                                templates[row.key].slotsCount <= 1
                                  ? 1
                                  : templates[row.key].slotsCount - 1,
                            },
                          });
                        }
                      }}
                    >
                      -
                    </div>
                    <div className="w-[50px] py-1 px-2 border border-[#D4D4D4] rounded-md">
                      <input
                        type="number"
                        className="flex-grow h-full w-full outline-none disabled:bg-white disabled:text-[#737373] text-center text-sm"
                        disabled={
                          row.key === "parent" ? true : isDisabled[row.key]
                        }
                        value={
                          row.key === "parent"
                            ? initData.slotsCount
                            : templates[row.key].slotsCount
                        }
                        onChange={(e) => {
                          if (row.key !== "parent") {
                            setTemplates({
                              ...templates,
                              [row.key]: {
                                ...templates[row.key],
                                slotsCount:
                                  Number(e.target.value) >
                                  SHIFT_DOCTORS_REQUIRED_LIMIT
                                    ? SHIFT_DOCTORS_REQUIRED_LIMIT
                                    : Number(e.target.value) < 1
                                    ? 1
                                    : Number(e.target.value),
                              },
                            });
                          }
                        }}
                      />
                    </div>
                    <div
                      className="h-[40px] w-[40px] border border-[#D4D4D4] bg-white flex justify-center items-center rounded-md text-2xl cursor-pointer"
                      onClick={() => {
                        if (row.key !== "parent") {
                          setTemplates({
                            ...templates,
                            [row.key]: {
                              ...templates[row.key],
                              slotsCount:
                                templates[row.key].slotsCount >=
                                SHIFT_DOCTORS_REQUIRED_LIMIT
                                  ? SHIFT_DOCTORS_REQUIRED_LIMIT
                                  : templates[row.key].slotsCount + 1,
                            },
                          });
                        }
                      }}
                    >
                      +
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="text-sm text-[#737373] my-1">
        *Variations of only up to 45 mins are allowed.
      </div>
      <div className="flex gap-2 mt-2">
        <div className="flex-grow"></div>
        <ButtonTwo
          clickHandler={() => {
            closeModal();
          }}
          variant="outlined"
        >
          Cancel
        </ButtonTwo>
        <ButtonTwo
          clickHandler={async () => {
            // group all variations and get the weekDays.

            const variationWeekdays: Array<
              "mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun"
            > = [];

            const variations = Object.entries(templates).reduce<
              {
                time: {
                  from: Time;
                  to: Time;
                };
                slotsCount: number;
                daysOfWeek: Array<
                  "mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun"
                >;
              }[]
            >((templates_, [dayOfWeek, template]) => {
              if (
                deepEqual(
                  { time: initData.time, slotsCount: initData.slotsCount },
                  { time: template.time, slotsCount: template.slotsCount }
                )
              ) {
                return templates_;
              }

              const index = templates_.findIndex((temp) =>
                deepEqual(
                  { time: temp.time, slotsCount: temp.slotsCount },
                  { time: template.time, slotsCount: template.slotsCount }
                )
              );

              variationWeekdays.push(
                dayOfWeek as
                  | "mon"
                  | "tue"
                  | "wed"
                  | "thu"
                  | "fri"
                  | "sat"
                  | "sun"
              );

              if (index === -1) {
                templates_.push({
                  time: template.time,
                  slotsCount: template.slotsCount,
                  daysOfWeek: [
                    dayOfWeek as
                      | "mon"
                      | "tue"
                      | "wed"
                      | "thu"
                      | "fri"
                      | "sat"
                      | "sun",
                  ],
                });
              } else {
                templates_[index].daysOfWeek.push(
                  dayOfWeek as
                    | "mon"
                    | "tue"
                    | "wed"
                    | "thu"
                    | "fri"
                    | "sat"
                    | "sun"
                );
              }
              return templates_;
            }, []);
            if (initData.settingsId === "new-template" && activeSeniorityId) {
              const resp = await createShiftTemplate({
                shiftTemplate: {
                  time: initData.time,
                  slotsCount: initData.slotsCount,
                  daysOfWeek: ROWS.slice(1)
                    .map((r) => r.key)
                    .filter(
                      (dayOfWeek) =>
                        !variationWeekdays.includes(
                          dayOfWeek as
                            | "mon"
                            | "tue"
                            | "wed"
                            | "thu"
                            | "fri"
                            | "sat"
                            | "sun"
                        )
                    ),
                },
                variations: variations,
                seniority: activeSeniorityId,
                locationId: activeLocation?._id,
              });
              handleResponse(resp, "Shift template created !", () => {
                postSaveHandler();
              });
            } else if (initData.settingsId !== "new-template") {
              const resp = await updateShiftTemplate({
                update: {
                  daysOfWeek: ROWS.slice(1)
                    .map((r) => r.key)
                    .filter(
                      (dayOfWeek) =>
                        !variationWeekdays.includes(
                          dayOfWeek as
                            | "mon"
                            | "tue"
                            | "wed"
                            | "thu"
                            | "fri"
                            | "sat"
                            | "sun"
                        )
                    ),
                },
                addVariations: variations,
                removeVariations: initData.variations.map((v) => v.settingsId),
                shiftSettingsId: initData.settingsId,
              });
              handleResponse(resp, "Shift template updated !", () => {
                postSaveHandler();
              });
            }
            closeModal();
            closeEditRow();
          }}
          variant={"fill"}
          fillClass="bg-[#4B5E2D]"
        >
          Save
        </ButtonTwo>
      </div>
    </div>
  );
};

export default ShiftVariationModal;
